import React from 'react'
import Link from 'gatsby-link'
import styles from './footer.module.css'
import LanguageLink from 'components/language-link'

export default (props) => {
  const langPrefix = props.location.pathname.match(/^\/en/) ? '/en' : '';

  return (
    <footer>
      <div className="container padding-bottom-md">
        <div className="row padding-bottom-lg">
          <div className="col-sm">
            <h1 className="brand"><Link to={langPrefix + "/"} className="internal-link">Planetway</Link></h1>
          </div>
          <div className="col-sm">
            {/* Put language link here */}
          </div>
        </div>
        <div className="row">
          <div className="col-sm padding-bottom-md">
            <h5>会社情報</h5>
            <ul className="footer-links">
              <li><Link to={langPrefix + "/our-vision"} className="internal-link">ビジョン</Link></li>
              <li><Link to={langPrefix + "/corporate-profile"} className="internal-link">会社概要</Link></li>
              <li><Link to={langPrefix + "/corporate-leadership"} className="internal-link">役員一覧</Link></li>
              <li><Link to={langPrefix + "/privacy-policy"} className="internal-link">Privacy Policy</Link></li>
            </ul>
          </div>
          <div className="col-sm padding-bottom-md">
            <h5>製品情報</h5>
            <ul className="footer-links">
              <li><Link to={langPrefix + "/planetcross"} className="internal-link">PlanetCross</Link></li>
              <li><Link to={langPrefix + "/planetid"} className="internal-link">PlanetID</Link></li>
              <li><Link to={langPrefix + "/security-service-for-gdpr"} className="internal-link">GDPR対策支援サービス</Link></li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <p className="address">〒107-0051 東京都港区元赤坂1-2-7 赤坂Kタワー4階</p>
          </div>
          <div className="col">
            <ul className="social-links float-right">
              <li className="social facebook"><a href="https://www.facebook.com/pwlvc">Facebook</a></li>
              <li className="social twitter"><a href="https://twitter.com/Planetway_Tw">Twitter</a></li>
              <li className="social linkedin"><a href="https://www.linkedin.com/groups/6697814/profile">LinkedIn</a></li>
              <li className="social youtube"><a href="https://www.youtube.com/channel/UC9cRrrtfD9OGtKx57Bt2JpA">YouTube</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container-fluid white-bg">
        <div className="row">
          <div className="col-sm text-center">
            <p className="copyright">&copy; Copyright 2023 Planetway Corp. <br className="mobile-only" />All Rights Reserved</p>
          </div>
        </div>
      </div>
      {/* <div className="video-wrapper"><iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/ErIsy88RjbA" frameBorder="0" allowFullScreen></iframe></div> */}
    </footer>
  )
}
