import React from 'react'
import { Helmet } from 'react-helmet'
import Link from 'gatsby-link'
import OgImage from "img/ogimg-planetcross.jpg"
import PartnerLogoNortal from "img/partner-logo-nortal.png"
import get from 'lodash/get'
import { graphql } from 'gatsby'
import Layout from "../../components/layout"

class RootIndex extends React.Component {
  render() {
    const langPrefix = this.props.location.pathname.match(/^\/en/) ? '/en' : '';

    const siteTitle = get(this, 'props.data.site.siteMetadata.en.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.en.description')
    const siteKeywords = get(this, 'props.data.site.siteMetadata.en.keywords')

    return (
      <Layout location={this.props.location}>
        <Helmet
        	title={siteTitle}
        	meta={[
        		{ name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: 'https://planetway.com' + OgImage },
        		{ property: 'og:description', content: siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />
        <main className="no-header">

          <div className="clear" />

          <section className="padding-top-lg">
            <div className="container">
              <div className="row">
                <div className="col" />
                <div className="col-sm-10"><div className="partner-logo"><img src={PartnerLogoNortal} /></div>
                  <h4 className="underline">Nortal AS</h4>
                  <h5 className="margin-bottom-sm">Market leader who has built over 40% of Estonian e-government solutions</h5>
                  <p>Nortal is a multinational strategic change and technology company. Combining the unique experience of transforming Estonia into a digital leader and creating change in businesses with a strategic approach and data-driven technology, Nortal’s vision is to build a seamless society. Nortal is present in 10 countries and employs specialists who carry out high-impact projects across Europe, the Middle East, Africa, and North America.</p>
                  <p>Nortal helps organizations and countries build data-driven strategies and support them through the transformation, offering help with change management as well as delivering the technology. Originally founded in 2000, Nortal has planned and implemented over 40% of Estonia’s digital disruption, which means the company have unique experience in building a digital, seamless society. Just recently, Nortal built a new business register for Oman — you can now register a company in Oman in less than three minutes. As a result, Oman’s rating rose 127 places in the World Bank’s ease of doing business rankings.</p>
                  <h5 className="margin-top-md margin-bottom-sm">Key Competence Areas</h5>
                  <p>Ease of doing business, public finance management, digital healthcare, electronic identity, Big Data analytics, data protection, revenue Science, e-commerce, Industry 4.0, cloud, design services</p>
                  <h5 className="margin-top-md margin-bottom-sm">Case Study</h5>
                  <p>Nortal is a key player in Estonian eID infrastructure development, by being an important partner in developing the eID system based on chip cards (ID cards), SIM based Mobile-ID and the mobile application Smart-ID that is not connected to a physical chip.
                  In the Baltic states, the push to develop a new eID solution for online banking resulted in something far more powerful: an ultra-secure, mobile-based system for authentication and digital signing that requires no special SIM card. It can be adopted by any online service provider, is free for users and naturally, has caught on like wildfire.
                  Over the past decade, Nortal has helped to build and modify existing eID solutions and integrate them to provide services. Nortal has contributed significantly to making eID a natural part of the e-government infrastructure. Nortal supports all aspects necessary for the successful implementation of electronic identity, whether this involves eID infrastructure, trust services, digital signatures, authentication solutions, establishing policy, supporting the legal environment or nationwide eGovernance.</p>
                  <p>For more information: <a href="https://nortal.com/" target="_blank">https://nortal.com/</a></p>
                  <p className="text-center mt-5"><Link to={langPrefix + "/pdsa-partners"} className="btn internal-link">Back</Link></p>
                </div>
                <div className="col" />
              </div>
            </div>
          </section>

        </main>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query PdsaPartnersNortalQueryEn {
    site {
      siteMetadata {
        en {
          title
          description
          keywords
        }
      }
    }
  }
`
