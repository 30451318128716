import React from "react"
import { Helmet } from 'react-helmet'
import Link from 'gatsby-link'
import OgImage from "img/ogimg-contact.jpg"
import get from 'lodash/get'

import DocSection from "components/DocSection"
import { graphql } from 'gatsby'

class DocPage extends React.Component {
  render() {
    const langPrefix = this.props.location.pathname.match(/^\/en/) ? '/en' : '';

    const siteTitle       = get(this, 'props.data.site.siteMetadata.en.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.en.description')
    const siteKeywords    = get(this, 'props.data.site.siteMetadata.en.keywords')

    let sections = this.props.data.allMarkdownRemark.edges;

    return (
      <div>
        <Helmet
          title={siteTitle}
          meta={[
            { name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
            { property: 'og:title', content: siteTitle },
            { property: 'og:type', content: 'website' },
            { property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
            { property: 'og:image', content: 'https://planetway.com' + OgImage },
            { property: 'og:description', content: siteDescription },
            { name: 'twitter:card', content: 'summary' },
            { name: 'twitter:site', content: '@Planetway_Tw' },
          ]}
        />
        <main className="no-header">

          <div className="clear"></div>

          <section className="padding-top-lg">
            <div className="container">
              <div className="row">
                <div className="col"></div>
                <div className="col-lg-8">
                  <h3 className="pageTitle margin-bottom-md">Documentation</h3>
                  <h5 className="pageTitle">PlanetCross</h5>

                  {sections.map(function (section, index) {
                    if(section.node.frontmatter.heading) {
                      return <div>
                        <Link className="" to={"#" + section.node.frontmatter.linkTitle.replace(/[^\x00-\x7F]|\s/g, "")}>{section.node.frontmatter.titleEn}</Link>
                      </div>
                    }
                  })}
                  <div className="margin-top-lg"></div>
                  {sections.map(function (section, index) {
                    let language = section.node.frontmatter.language;
                    if (!language || language=="en") {
                      return <div id={section.node.frontmatter.linkTitle ? section.node.frontmatter.linkTitle.replace(/[^\x00-\x7F]|\s/g, "") : null}>
                               <DocSection text={section.node.html} title={section.node.frontmatter.titleEn} info={section.node.frontmatter}/>
                             </div>
                    }
                  })}
                  <div className="clear"></div>
                </div>
                <div className="col"></div>
              </div>
            </div>
          </section>

        </main>

      </div>

    )
  }
}

export const pageQuery = graphql`
  query DocQueryEn {
    site {
      siteMetadata {
        en {
          title
          description
          keywords
        }
      }
    }
    allMarkdownRemark(filter: { frontmatter:  { type: { eq:"docSection"} } }, sort: { order: ASC, fields: [frontmatter___id] }) {
      edges {
        node {
          html
          frontmatter {
            date
            title
            titleEn
            linkTitle
            id
            type
            underline
            icon1
            heading
            language
          }
        }
      }
    }
  }
`

export default DocPage
