import React from 'react'
import { Helmet } from 'react-helmet'
import OgImage from "img/ogimg-planetcross.jpg"
import get from 'lodash/get'
import { graphql } from 'gatsby'
import Layout from "../components/layout"

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.description')
    const siteKeywords = get(this, 'props.data.site.siteMetadata.keywords')

    return (
      <Layout location={this.props.location}>
        <Helmet
        	title={`PlanetCross - ${siteTitle}`}
        	meta={[
        		{ name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: 'PlanetCross - ' + siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: 'https://planetway.com' + OgImage },
        		{ property: 'og:description', content: siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />

        <div className="page-header planetcross-header dia-bottom">
          <div className="container text-on-dark text-left">
            <div className="row">
              <div className="col-md-6">
                <div className="hp-slider-planetcross-logo-mark" />
              </div>
              <div className="col-md-6">
                <h2><span className="text-light">Planet</span>Cross</h2>
                <strong className="subtitle-small">Cross-Industry Secure Data Access Platform</strong>
                <p>「PlanetCross」は、ICT先進国エストニアの電子政府を支える情報連携基盤「X-Road」を、Planetwayが日本で初めて民間企業向けにカスタマイズして提供するセキュアなデータ連携基盤です。</p>
              </div>
            </div>
          </div>
        </div>

        <main>

          <div className="clear" />

          <section>
            <div className="container">
              <div className="row">
                <div className="col-lg-1" />
                <div className="col-lg-10 text-center mobile-text-left">
                  <h4 className="underline">Planetway<strong>のトータルソリューション</strong></h4>
                  <p className="text-larger">Planetwayが目指すデータ利活用社会においては、個人の許諾ベースで企業や自治体が持っているデータを業界の垣根を越えてセキュアに流通できる必要があると考えております。</p>
                </div>
                <div className="col-lg-1" />
              </div>
            </div>
          </section>

          <section className="dark-blue-gradient text-on-dark">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <strong className="subtitle-small margin-bottom-sm">Planetwayは、</strong>
                  <h4 className="margin-bottom-sm"><strong>自分の意思で個人データ</strong></h4>
                  <strong className="subtitle-small">を公開できるプラットフォームを提供します。</strong>
                  <p className="text-larger">本プラットフォームを活用することで、企業や個人はそれぞれの意思で、安全且つ容易にデータを第三者に公開可能とすることができます。これにより、データ利活用による新たなサービスやベネフィットを誰もが生み出せる社会となります。</p>
                </div>
                <div className="col-lg-6 text-center">
                  <div className="planetcross-personal-data block-center" />
                  <h4><strong>自分の意思で個人データを<br />
                  <span className="text-green">自由にコントロール</span></strong></h4>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="container">
              <div className="row">
                <div className="col-lg-12 text-center mobile-text-left">
                  <h4 className="underline med-title"><strong>本プラットフォームは大きく２つのプロダクトから構成されます</strong></h4>
                </div>
              </div>
              <div className="row text-center">
                <div className="col-lg-2" />
                <div className="col-lg-4">
                  <div className="planetcross-logo-light block-center" />
                  <p className="text-larger">分野間のデータアクセスに特化した<br />データ連携基盤</p>
                </div>
                <div className="col-lg-4">
                  <div className="planetid-logo-light block-center" />
                  <p className="text-larger">確実な個人が証明可能でセキュア<br />なID基盤</p>
                </div>
                <div className="col-lg-2" />
              </div>
            </div>
          </section>

          <section className="padding-top-none">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 mb-4">
                  <h4 className="margin-bottom-sm underline">PlanetCross<strong>とは</strong></h4>
                  <p className="text-large"><strong>セキュアにクロスインダストリー・クロスボーダーのデータ連携を実現し、オープンなエコシステムを形成</strong></p>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <p className="text-large">PlanetCrossは既存システムやデータベースに大きな変更を加えることなく、インターネットを通じて企業・団体間のデータ連携をセキュアに実現できます。</p>
                  <p className="text-large">PlanetCrossはそれ単独でも効果を発揮します。PlanetCrossを活用することで例えば以下のようなニーズに答えることができます。</p>
                </div>
                <div className="col-lg-1" />
                <div className="col-lg-5">
                  <ul className="list-purple-arrow text-large margin-top-none">
                    <li><strong>組織内の各部門でバラバラに構築／管理されたデータベースを既存環境そのままに一元的にアクセスできるようにしたい</strong></li>
                    <li><strong>組織内で保有しているデータを他企業／団体に開放して新しいサービスを作りたい</strong></li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

          <section className="dark-blue-gradient text-on-dark padding-top-md padding-bottom-md">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 text-center">
                  <div className="planetcross-system-image block-center" />
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="container">
              <div className="row">
                <div className="col-lg-3" />
                <div className="col-lg-6">
                  <p className="text-large">各拠点にPlanetCrossモジュールを導入し、簡易な設定を行うだけでお客様データベースを、PlanetCrossを介して直接通信可能となります。</p>
                  <p className="text-large">拠点間のデータベースアクセスのためのインターフェースはWebAPIベースで行われ、そのサービス開発はPlanetCrossから提供されるフォームに
                  従うことでデータ提供者側で容易に作成可能です。データ利用者側は提供されたWebAPIをコールすることでデータアクセスが可能となります。</p>
                  <p className="text-large">データ連携時には通信暗号化並びに、第三者機関の認証局（CA）による法人証明、タイムスタンプ局（TSA）
                  によるタイムスタンプ付与が透過的に行われており、インターネット上でも強固なセキュリティ・高い証明性を担保しております。</p>
                </div>
                <div className="col-lg-3" />
              </div>
            </div>
          </section>

          <section className="padding-top-none padding-bottom-none">
            <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h4 className="underline"><strong>特徴</strong></h4>
              </div>
            </div>
              <div className="row">
                <div className="col-md text-center">
                  <div className="planetcross-artwork-estonia block-center" />
                  <p><strong>エストニアにおけるX-Roadの豊富な運用実績</strong></p>
                  <p>20年以上に及ぶ電子国家エストニアの政府インフラを民間応用</p>
                </div>
                <div className="col-md text-center">
                  <div className="planetcross-artwork-api block-center" />
                  <p><strong>無限のスケーラビリティ</strong></p>
                  <p>セキュリティを担保したData Exchange</p>
                </div>
                <div className="col-md text-center">
                  <div className="planetcross-artwork-control block-center" />
                  <p><strong>柔軟なアクセスコントロール</strong></p>
                  <p>分散型による大規模なデータベースネットワークの構築</p>
                </div>
                <div className="col-md text-center">
                  <div className="planetcross-artwork-security block-center" />
                  <p><strong>データの改ざん防止、完全性、トレーサビリティを同時に実現</strong></p>
                  <p>マルチプロトコル対応したプラットフォームによる無限のスケーラビリティ</p>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="container">
              <div className="row">
                <div className="col text-center">
                  <h4 className="underline"><strong>受賞歴</strong></h4>
                </div>
              </div>

              <div className="row">
                <div className="col-sm">
                  <div className="planetcross-award-ibmbluehub" />
                  <div className="text-center"><p>2016年12月17日</p></div>
                  <ul className="list-green-check">
                    <li><h5>Automotive</h5><p>最優秀賞/審査員特別賞(日産自動車様)</p></li>
                    <li><h5>Healthcare</h5><p>審査員特別賞(電通デジタル様)</p></li>
                  </ul>
                </div>
                <div className="col-sm">
                  <div className="planetcross-award-efma" />
                  <div className="text-center"><p>2017年5月2日</p></div>
                  <ul className="list-green-check">
                    <li><h5>Efma主催Innovation In Insurance Award</h5><p>東京海上日動火災社一位受賞に貢献</p></li>
                  </ul>
                </div>
                <div className="col-sm">
                  <div className="planetcross-award-apec" />
                  <div className="text-center"><p>2017年10月30日</p></div>
                  <ul className="list-green-check">
                    <li><h5>ガートナー主催 APEC 2017 Award
                    (Most Innovative Digital Business Modelの部)
                    </h5><p>東京海上日動火災社 準優勝に貢献</p></li>
                  </ul>
                </div>
                <div className="col-sm">
                  <a style={{color: "inherit"}} href="https://planetway.com/en/pressreleases/2022-planetway/">
                    <div className="planetcross-award-cert" />
                    <div className="text-center"><p>2022年4月6日</p></div>
                    <ul className="list-green-check">
                      <li><h5>Top 10 JAPANESE STARTUPS 2022</h5>
                      <p>米StartUp City誌により、日本を代表するスタートアップ10社に選出</p></li>
                    </ul>
                  </a>
                </div>
              </div>
            </div>
          </section>

          <section className="padding-top-lg padding-bottom-md">
            <div className="container">
              <div className="row">
                <div className="col" />
                <div className="col-lg-8 text-center">
                  <h4 className="underline text-bold">お問い合わせ</h4>
                </div>
                <div className="col" />
              </div>
              <div className="row">
                <div className="col" />
                <div className="col-lg-8">
                  <form action="https://formspree.io/pr@planetway.com" method="POST">
                    <input type="hidden" name="_next" value="https://planetway.com/thanks" />
                    <input type="hidden" name="_language" value="ja" />
                    <fieldset id="name"><label htmlFor="firstname">お名前</label>
                      <div style={{float: "left", width: "49%"}}>
                        <input type="text" name="familyname" placeholder="姓" />
                      </div>
                      <div style={{float: "right", width: "49%"}}>
                        <input type="text" name="firstname" placeholder="名" />
                      </div>
                    </fieldset>
                    <fieldset id="company">
                      <label htmlFor="company">会社名</label>
                      <input type="text" name="company" placeholder="" />
                    </fieldset>
                    <fieldset id="email">
                      <label htmlFor="email">メールアドレス</label>
                      <input type="email" name="email" placeholder="" />
                    </fieldset>
                    <fieldset id="message">
                      <label htmlFor="message">お問い合わせ内容</label>
                      <textarea name="message" />
                    </fieldset>
                    <button type="submit" className="btn">送信する</button>
                  </form>
                </div>
                <div className="col" />
              </div>
            </div>
          </section>
        </main>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query PlanetCrossQuery {
    site {
      siteMetadata {
        title
        description
        keywords
      }
    }
  }
`
