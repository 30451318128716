import React from 'react'
import styles from './article-preview.module.css'
import Link from 'gatsby-link'

export default ({ post }) => {

  // photo model is optional in contentful
  let photo;
  if (post.photo && post.photo.file) {
    photo = post.photo.file.url
  }

  const to = (post.node_locale === 'en' ? '/en' : '') + `/pressreleases/${post.slug}`;

  return (
    <div className="col-md-6 news-col">
      <div className="news-item-wrapper">
        <Link to={to} className="news-item internal-link" style={{backgroundImage: "url(" + photo + ")"}}>
          <span className={`news-category ${post.type}`}></span>
          <span className="news-date">{post.publishDate}</span>
        </Link>
      </div>
      <Link to={to} className="internal-link"><h5 className="news-title">{post.title}</h5>
      <span
        dangerouslySetInnerHTML={{
          __html: post.excerpt.childMarkdownRemark.html,
        }}
      /></Link>
    </div>
  );
};
