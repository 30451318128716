import React from 'react'
import { Helmet } from 'react-helmet'
import OgImage from "img/ogimg-planetid.jpg"
import get from 'lodash/get'
import { graphql } from 'gatsby'
import Layout from "../../components/layout"

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.en.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.en.description')
    const siteKeywords = get(this, 'props.data.site.siteMetadata.en.keywords')

    return (
      <Layout location={this.props.location}>
        <Helmet
        	title={`PlanetID - ${siteTitle}`}
        	meta={[
        		{ name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: 'PlanetID - ' + siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: 'https://planetway.com' + OgImage },
        		{ property: 'og:description', content: siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />

        <div className="page-header planetid-header dia-bottom">
          <div className="container text-on-dark text-left">
            <div className="row">
              <div className="col-md-6">
                <div className="hp-slider-planetid-logo" />
                <h2><span className="text-light">Planet</span>ID</h2>
                <strong className="subtitle-small">The key to your digital self</strong>
                <p>PlanetID is a universal ID used by end users and IoT devices, utilizing cutting-edge Estonian technology.</p>
              </div>
              <div className="col-md-6">
                <div className="hp-slider-planetid-device" />
              </div>
            </div>
          </div>
        </div>

        <main>

          <div className="clear" />

          <section className="padding-bottom-none">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 order-lg-0 order-12">
                  <h3 className="underline">Authenticate</h3>
                  <p>To be issued a PlanetID it is necessary to verify your identity through a process which meets the legal standards of financial and telecommunications institutions.
                    The encryption of the private key, which is based on the digital certificate authentication of the end user, guarantees high security during the service login process.
                    (Multifactor authentication, including biometric authentication, is also possible.)</p>
                </div>
                <div className="col-lg-4 text-center padding-bottom-lg"><div className="planetid-authenticate block-center" /></div>
              </div>
            </div>
          </section>

          <section className="padding-bottom-none">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 text-center padding-bottom-lg"><div className="planetid-digitally-sign block-center" /></div>
                <div className="col-lg-8">
                  <h3 className="underline">Digitally Sign</h3>
                  <p>Various transactions such as referring or acquiring data can be signed digitally by utilizing the electronic certificate as the signature of the end user.
                    This process makes signatures and stamps unnecessary and encourages the realization of Smart Contract.</p>
                </div>
              </div>
            </div>
          </section>

          <section className="padding-bottom-none">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 order-lg-0 order-12">
                  <h3 className="underline">Share Information</h3>
                  <p>The end user can grant permission for the company to share their personal data with others over the cross-industry data access platform, PlanetCross.
                    After the permission is granted with PlanetID, the company holding the data can collaborate with companies who want to utilize external data, and services
                    will be provided to the individuals in return.</p>
                </div>
                <div className="col-lg-4 text-center padding-bottom-lg"><div className="planetid-share-information block-center" /></div>
              </div>
            </div>
          </section>

          <section>
            <div className="container">
              <div className="row">
                <div className="col-lg-4 text-center padding-bottom-lg"><div className="planetid-protect-your-data block-center" /></div>
                <div className="col-lg-8">
                  <h3 className="underline">Protect Your Data</h3>
                  <p>The robust security infrastructure helps to prevent identity theft. Furthermore, it is possible to check who has had access to your data and control who is allowed to see it and who is not.</p>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h3 className="underline">How it works</h3>
                </div>
              </div>
              <div className="row margin-bottom-lg">
                <div className="col-md-4 text-center">
                  <div className="planetid-how-step-1 block-center" />
                  <h4>Step 1</h4>
                  <p>Download PlanetID application from <a href="https://apps.apple.com/jp/app/planetid/id1448126526" target="_blank">App Store </a>OR
                  <a href="https://play.google.com/store/apps/details?id=com.planetway.planetid.public" target="_blank"> Google Play</a> and install it on your smartphone.</p>
                </div>
                <div className="col-md-4 text-center">
                  <div className="planetid-how-step-2 block-center" />
                  <h4>Step 2</h4>
                  <p>Launch the app and begin registration, choose an identity verification company, verify your identity and receive your PlanetID.</p>
                </div>
                <div className="col-md-4 text-center">
                  <div className="planetid-how-step-3 block-center" />
                  <h4>Step 3</h4>
                  <p>You can use PlanetID for certification and signing to receive different services from companies and organizations.</p>
                </div>
              </div>
              <div className="row margin-bottom-sm">
                <div className="col-md-12 text-center">
                  {/* <a href="#/" className="app-store-button">Available on the App Store</a><a href="#/" className="google-play-button">Android App on Google Play</a> */}
                </div>
              </div>
            </div>
          </section>
        </main>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query PlanetIdQueryEn {
    site {
      siteMetadata {
        en {
          title
          description
          keywords
        }
      }
    }
  }
`
