import React from 'react'
import { Helmet } from 'react-helmet'
import OgImage from "img/ogimg-our-vision.jpg"
import ArtworkOurVisionBackground from "img/artwork-our-vision-background.svg"
import NoriSig from "img/nori-sig.svg"
import get from 'lodash/get'
import { graphql } from 'gatsby'
import Layout from "../components/layout"

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.description')
    const siteKeywords = get(this, 'props.data.site.siteMetadata.keywords')

    return (
      <Layout location={this.props.location}>
        <Helmet
        	title={siteTitle}
        	meta={[
        		{ name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: 'https://planetway.com' + OgImage },
        		{ property: 'og:description', content: siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />
        <main className="no-header">

          <div className="clear" />

          <section>
            <div className="container">
              <div className="row">
                <div className="col" />
                <div className="col-sm-10 text-center">
                  <h3 className="underline">Our Vision</h3>
                  <h5 className="margin-bottom-sm"><strong>データ個人主権時代の創出</strong></h5>
                  <p>
                    Planetwayは、データは人権であると考えます。日常生活全てのパーソナルデータから秘匿性の高いデータまで、 組織でなく個人に帰属すべきであると考えています。
                    その為に実現することはただ1つ、“Creating an era of Personal Data Sovereignty（「データ個人主権時代の創出」）“です。その世界では個人の許諾のもと、自身の意思でデータを安全かつ自由に第三者に公開・非公開を可能とすることで、 自身と社会にとって好ましい形でデータが利活用される世界を創出します。また、これまでパーソナルデータを財源に成長してきた巨大IT企業などから、そのデータが持つ価値を全人類に還元する仕組みを作ります。創業者は４大ビジョンを掲げており、 上記が1つ目のビジョンとなります。
                  </p>
                </div>
                <div className="col" />
              </div>
            </div>
          </section>

          <section className="space-bg text-on-dark dia-bottom dia-top">
            <div className="container">
              <div className="row">
                <div className="col">
                  <h3 className="underline">背景</h3>
                </div>
              </div>
              <div className="row">
                <div className="col-sm">2015年に創業した社名のプラネットウェイには、Planet(宇宙の中の惑星としての地球)のway(道をさし示す)という意味が込められております。人類は地球が誕生してから最も文明を成功させたと言えるかもしれませんが、一方で今、地球環境は極めて危険な状況にあります。<br/><br/> 2005年、ユーザーはインターネット上で能動的に情報を発信し、相互作用できるという「Web2.0」が提唱され、GAFAMなどの米国資本主義型の巨大IT企業はデータビジネスを展開することで、社会や経済に対して絶大な影響を与えるまでになりました。しかし、これら巨大IT企業にデータを独占されてきたことが世界的な課題となっています。EUではGAFAMへの対抗策として、2018年以降、世界で最も厳しいデータ保護法と言われるGDPRを施行し、個人データの処理や移転、保管に関して厳しい制限をかけています。 </div>
                <div className="col-sm">GDPRをモデルにしたデータ保護法は各国で整備され始め、巨大IT企業がデータを中央集権的に管理し、プラットフォームとして運営するモデルは成立しなくなりつつあり、同時に次世代の分散型インターネットとして「Web3.0」が提唱され、近年ブロックチェーンやNFT、DAOなど様々な技術に注目が集まっていますが、「Web3.0風アプリケーション」が少なくなく、運営企業がデータを保有するなど、構造上はWeb2.0と変わらないケースが散見されています。<br/><br/>このような時代だからこそ、今日の世界で求められているのは、正しい情報を得られる真のインターネットと、自分自身の情報を安心して利活用できる環境です。我々が作る未来の世界では、これからの100年、1000年、その先も続く未来において、いかなるテクノロジーの進歩も人類を支配・淘汰することはできない・させない世界を作って参ります。</div>
              </div>
              <div className="row">
                <div className="col-sm" />
                <div className="col-sm-10 padding-bottom-lg text-center">
                  <img src={ArtworkOurVisionBackground} className="margin-top-lg full-width-image" />
                </div>
                <div className="col-sm" />
              </div>
            </div>
          </section>

          <section>
            <div className="container">
              <div className="row">
                <div className="col-sm" />
                <div className="col-sm-8 text-center">
                  <h3 className="underline">Message from CEO</h3>
                  <p>
                    私は、米国留学時代に宇宙工学、マーケティング、起業を学び、その後7年間で大手通信会社、半導体製造スタートアップ、台湾系ハードウェア製造企業、通信インフラ上場会社で、新規事業立案などを経験した後に、Planetway社を創業しました。<br className="full-width-only" /><br className="full-width-only" />
                    2015年の創業期にはグローバル通信サービスの提供や、ホワイトハッカー育成事業など様々な事業を展開しながら事業譲渡も経験し、18年以降は、エストニアの電子政府インフラ『X-ROAD』を拡張開発した、企業間で安全な情報交換を可能とするPlanetCross（通称：PX）と個人認証基盤のPlanetID（通称：PID）を提供しています。また企業向けに、個人情報のプライバシーを担保する世界一厳しい個人情報保護法のGDPR法対策支援サービスも提供しております。我々は、これからの人類を取り巻く環境にとって最も重要なことは、「テクノロジーによる永続的な人類の発展と地球内外の環境の保全」と「プライバシーを保護した上での、明確なデータの利活用法」であると考えています。<br className="full-width-only" /><br className="full-width-only" />
                    Planetwayは、今まさに、新たなテクノロジーやサービスが人類の利便性を究極に追求した上で、安心して自分でデータ提供し生活できる環境の配備をしています。それは、例えるならば『国境や企業の壁を超えたパーソナルデータの高速伝送路』のようなものを作っている最中です。また、今後は企業や個人の皆様の生活の一部のお悩みを解決する切り口での、新サービスも展開予定ですので、ご期待ください。
                  </p>
                </div>
                <div className="col-sm" />
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="our-vision-sig block-center padding-top-md">
                    <span className="nori-pic" />
                    <span className="nori-sig">
                      <img src={NoriSig} style={{width: "200px", height: "auto"}} />
                      <h5 className="padding-top-sm">平尾 憲映</h5>
                      <p className="member-position">代表取締役CEO/ファウンダー</p>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query OurVisionQuery {
    site {
      siteMetadata {
        title
        description
        keywords
      }
    }
  }
`
