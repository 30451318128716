import React from 'react'
import { Helmet } from 'react-helmet'
import OgImage from "img/ogimg-contact.jpg"
import get from 'lodash/get'
import { graphql } from 'gatsby'

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.description')
    const siteKeywords = get(this, 'props.data.site.siteMetadata.keywords')

    return (
      <div>
        <Helmet
        	title={siteTitle}
        	meta={[
        		{ name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: 'https://planetway.com' + OgImage },
        		{ property: 'og:description', content: siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />
        <main className="no-header">

          <div className="clear"></div>

          <section className="padding-top-md padding-bottom-none">
            <div className="container">
              <div className="row">
                <div className="col"></div>
                <div className="col-lg-8 text-center">
                  <h4 className="underline">PlanetForum Signup</h4>
                  <h5><strong>第</strong>1<strong>回</strong> PlanetForum - Planetway&reg; Data Sovereignty Alliance <strong>の発表</strong></h5>
                  <p>2020年２月11日 (火) - 13:30〜16:30 (予定)<br />スクランブルホール（渋谷スクランブルスクエア内SHIBUYA QWS）<br /><a href="https://goo.gl/maps/LnqhMFPbAaqteftZ6" target="_blank">View Map</a></p>
                </div>
                <div className="col"></div>
              </div>
            </div>
          </section>

          <section className="padding-top-none padding-bottom-lg">
            <div className="container">
              <div className="row">
                <div className="col"></div>
                <div className="col-lg-8">
                  <form action="https://formspree.io/pr@planetway.com" method="POST">
                    <input type="hidden" name="_next" value="https://planetway.com/thanks" />
                    <input type="hidden" name="_language" value="ja" />
                    <fieldset id="company">
                      <label htmlFor="company">会社名</label>
                      <input type="text" name="company" placeholder="" required />
                    </fieldset>
                    <fieldset id="department">
                      <label htmlFor="department">所属部署</label>
                      <input type="text" name="department" placeholder="" />
                    </fieldset>
                    <fieldset id="name">
                      <label htmlFor="name">氏名</label>
                      <input type="text" name="name" placeholder="" required />
                    </fieldset>
                    <fieldset id="email">
                      <label htmlFor="email">メールアドレス</label>
                      <input type="email" name="email" placeholder="" required />
                    </fieldset>
                    <button type="submit" className="btn">Submit</button>
                  </form>
                </div>
                <div className="col"></div>
              </div>
            </div>
          </section>

        </main>

      </div>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query PlanetForumSignupQuery {
    site {
      siteMetadata {
        title
        description
        keywords
      }
    }
  }
`
