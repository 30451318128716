import React from 'react'
import { Helmet } from 'react-helmet'
import Link from 'gatsby-link'
import OgImage from "img/ogimg-contact.jpg"
import get from 'lodash/get'
import { graphql } from 'gatsby'
import Layout from "../components/layout"

class RootIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = { subject: "" };
    this.onPurposeChange = this.onPurposeChange.bind(this);
  }
  onPurposeChange(ev) {
    let purpose = ev.target.value;
    let subject = `New submission from planetway.com/contact/ purpose=${purpose}`;
    this.setState({
      subject: subject
    });
  }
  render() {
    const siteTitle       = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.description')
    const siteKeywords    = get(this, 'props.data.site.siteMetadata.keywords')

    return (
      <Layout location={this.props.location}>
        <Helmet
        	title={siteTitle}
        	meta={[
        		{ name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: 'https://planetway.com' + OgImage },
        		{ property: 'og:description', content: siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />
        <main className="no-header">

          <div className="clear" />

          <section className="padding-top-lg padding-bottom-md">
            <div className="container">
              <div className="row">
                <div className="col"/>
                <div className="col-lg-8 text-center">
                  <h4 className="underline text-bold">お問い合わせ</h4>
                </div>
                <div className="col"/>
              </div>
              <div className="row">
                <div className="col"/>
                <div className="col-lg-8">

                  <form action="https://formspree.io/pr@planetway.com" method="POST">
                    <input type="hidden" name="_next" value="https://planetway.com/thanks" />
                    <input type="hidden" name="_language" value="ja" />
                    <input type="hidden" name="_subject" value={this.state.subject} />
                    <fieldset id="purpose">
                      <label htmlFor="purpose">お問い合わせ内容 <span className="required">※選択必須</span></label>
                      <select name="purpose" required onChange={this.onPurposeChange}>
                        <option value="">お問い合わせ内容を選択して下さい</option>
                        <option value="service">サービスについて</option>
                        <option value="business">ビジネスについて</option>
                        <option value="interview">取材について</option>
                        <option value="recruit">求人について</option>
                        <option value="privacy">プライバシーについて</option>
                        <option value="security">セキュリティについて</option>
                        <option value="gdpr">GDPR対策支援サービスについて</option>
                        <option value="other">その他</option>
                      </select>
                    </fieldset>
                    <fieldset id="name"><label htmlFor="firstname">お名前 <span className="required">※入力必須</span></label>
                      <div style={{float: "left", width: "49%"}}>
                        <input type="text" name="familyname" placeholder="姓" required/>
                      </div>
                      <div style={{float: "right", width: "49%"}}>
                        <input type="text" name="firstname" placeholder="名" required/>
                      </div>
                    </fieldset>
                    <fieldset id="company">
                      <label htmlFor="company">会社名</label>
                      <input type="text" name="company" placeholder="" />
                    </fieldset>
                    <fieldset id="email">
                      <label htmlFor="email">メールアドレス <span className="required">※入力必須</span></label>
                      <input type="email" name="email" placeholder="" required />
                    </fieldset>
                    <fieldset id="telephone">
                      <label htmlFor="telephone">連絡先</label>
                      <input type="text" name="telephone" placeholder="" />
                    </fieldset>
                    <fieldset id="message">
                      <label htmlFor="message">お問い合わせ内容 <span className="required">※入力必須</span></label>
                      <textarea name="message" required/>
                    </fieldset>
                    <fieldset id="optin">
                      <label htmlFor="optin"><input type="checkbox" name="optin" />&nbsp;&nbsp;入力されたEメールに各サービスの御案内を送ることに同意します。</label>
                      {/* (<Link to="/legal/PX_Freemium_Privacy_Notice.pdf">プライバシーノーティスをお読み下さい。</Link>) */}
                    </fieldset>
                    <button type="submit" className="btn">送信する</button>
                  </form>

                </div>
                <div className="col"/>
              </div>
            </div>
          </section>

          <section className="padding-top-lg padding-bottom-lg">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <h4 className="padding-bottom-none margin-bottom-sm">US Headquarters</h4>
                  <p className="text-larger"><strong>Planetway Corporation</strong><br />2033 Gateway Pl, San Jose, CA 95110, United States</p>
                  <p className="text-larger">
                    Tel: +1-408-961-8675<br />
                    {/* Email: <a href="mailto:sanjose@planetway.com">sanjose@planetway.com</a> */}
                  </p>
                </div>
                <div className="col-md-6">
                  <h4 className="padding-bottom-none margin-bottom-sm">Asia Pacific, Tokyo</h4>
                  <p className="text-larger"><strong>Planetway Japan 株式会社</strong><br />〒107-0051 東京都港区元赤坂1-2-7 赤坂Kタワー ４階</p>
                  <p className="text-larger">
                    Tel: 03-6897-9419<br />
                    {/* Email: <a href="mailto:pr@planetway.com">pr@planetway.com</a> */}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <h4 className="padding-bottom-none margin-bottom-sm">R&D Center, Estonia</h4>
                  <p className="text-larger"><strong>Planetway Europe OÜ</strong><br />Valukoja 8, Öpiku 2, Tallinn, 11415 Estonia</p>
                  <p className="text-larger">
                    {/* Tel: 03-6897-9419<br /> */}
                    {/* Email: <a href="mailto:tallinn@planetway.com">tallinn@planetway.com</a> */}
                  </p>
                </div>
              </div>
            </div>
          </section>

        </main>

      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query ContactQuery {
    site {
      siteMetadata {
        title
        description
        keywords
      }
    }
  }
`
