import React from 'react'
import { Helmet } from 'react-helmet'
import Link from 'gatsby-link'
import OgImage from "img/ogimg-planetid.jpg"
import get from 'lodash/get'
import { graphql } from 'gatsby'

class RootIndex extends React.Component {
  render() {
    const langPrefix = this.props.location.pathname.match(/^\/en/) ? '/en' : '';

    const siteTitle = get(this, 'props.data.site.siteMetadata.en.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.en.description')
    const siteKeywords = get(this, 'props.data.site.siteMetadata.en.keywords')

    return (
      <div>
        <Helmet
        	title={siteTitle}
        	meta={[
        		{ name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: 'https://planetway.com' + OgImage },
        		{ property: 'og:description', content: siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />
        <main>

          <section className="pt-5">
            <div className="container">
              <div className="row">
                <div className="col"></div>
                <div className="col-sm-10">
                  <h4 className="underline">PlanetID News</h4>

                  <div className="pid-news-item mb-5">
                    <span class="news-date margin-bottom-sm">March 19, 2020</span>
                    <h5 className="mb-3">Planetway Releases Universal ID Platform “PlanetID” to Realize Personal Data Sovereignty</h5>
                    <p>Planetway Corporation (Headquarters: San Jose, California, USA; CEO & Founder: Noriaki Hirao; Planetway) will commence commercialization of PlanetID, a universal ID platform, in early April.</p>
                    <p>Planetway Corporation (Headquarters: San Jose, California, USA; CEO & Founder: Noriaki Hirao; Planetway) will commence commercialization of PlanetID, a universal ID platform, in early April. Planetway, using PlanetID with the data linkage platform PlanetCross which allows users to realize personal information value in the form of personal information control, will lead the world in personal data sovereignty.</p>
                    <p>PlanetID has the advanced security and other necessary functions to integrate the overwhelming number of user IDs providing unparalleled convenience to end users. Furthermore, since it is a universal ID with verified individuals and digital certificates, this solution can be utilized in the field of smart contracts. PlanetID also has a permission function which can be used to great effect in areas where highly confidential data (such as personal or private information) is used, for example, in areas such as personal information banks. PlanetID users can safely respond to data exchange requests for new services that require individual personal data.</p>
                    <p>PlanetID is a new universal ID platform for identifying individuals. It is simple, easy to use, and allows you to conveniently and securely access services on websites and smartphone apps. With PlanetID, it is possible to experience a world with the following new service examples:</p>
                    <ul>
                      <li>(User Authentication function) End users no longer needs to remember IDs and passwords for various websites and smartphone apps. By simply using the PIN code set with PlanetID, you can securely log in to any service app.</li>
                      <li>(Digital signature) By utilizing a digital signature, you can register or complete a contract online. In other words, all procedures that previously required a seal or official signature can now be performed digitally.</li>
                      <li>(Consent function) You can easily express or deny your consent for the use or provision of personal data in an electronic service (you control the use of your personal data). With this release we heavily improve the quality of the MVP (Minimum Viable Product) version released last year and start selling it as the PlanetID product version.</li>
                    </ul>
                    <h5>About Planetway</h5>
                    <p>Planetway Corporation a start-up headquartered in California, has developed its own core platform technology "PlanetCross," an advanced version of the powerful Estonian e-government administration platform “X-Road”, and “PlanetID,” a digital universal ID with functions including ID assignment(identity-verified), authentication, and electronic signature. Our company is certified as an X-ROAD TECHNOLOGY PARTNERS-SILVER. Based on the fundamental idea that the rights to personal information belong to individuals rather than companies or organizations, Planetway has created a mission to change society "For a Human-centric and Secure Planet" with the aim to promote the idea of information disclosure or utilization by an “individual’s consent or denial thereof”.</p>
                    <p><strong>Corporate Home Page (EN):</strong> <a href="https://planetway.com/en/">https://planetway.com/en/</a></p>
                    <p><strong>X-ROAD TECHNOLOGY PARTNERS-SILVER:</strong> <a href="https://x-road.global/xroad-technology-partners">https://x-road.global/xroad-technology-partners</a></p>
                    <p>※Company names and product names in this text are trademarks or registered trademarks of their respective companies</p>
                    <h5>About the founder</h5>
                    <p>Born in 1983, Noriaki Hirao is a three-time entrepreneur (with one liquidation), founding companies in entertainment, semiconductor and IoT fields. As a student, he studied space engineering, organic chemistry and marketing in the United States, then founding a content production startup for Hollywood films and video games. In 2008, after graduating from California State University he returned to Japan joining SoftBank Mobile and received several in-house awards, including first prize for sales competing against 800 national mobile salespersons. In 2011, he co-founded a technology licensing company (TLO), which signed a joint development research agreement with Tohoku University. He attempted to expand business in next-generation semiconductor, centering on 500 patent strategies, but unfortunately the company had to be liquidated. In 2014, he was appointed as the domestic Business Development Manager for Sircom, a Taiwanese IoT equipment ODM vendor, and General Manager of their New Business Innovation Office for WirelessGate, specifically responsible for new business development in the IoT field. From July 2015, Noriaki founded Planetway, a global startup (USA, Japan, Estonia) acuminating his various experiences. In 2017, the company introduced technology for securely communicating patient information between Tokio Marine &amp; Nichido Insurance and Iizuka Hospital and was awarded the “World's No. 1” rating award for the solution. In 2019, Planetway achieved its first commercialization of a product (PlanetCross), now servicing more than 1.3 million service account users. After 2020, the company aims to expand its infrastructure in Japan and globally. Noriaki has a unique vision for the realization of the age of data sovereignty and the evolution of capitalism.</p>
                    <p><Link to={langPrefix + "/planetid/planetid-news"} className="btn internal-link">Back</Link></p>
                  </div>

                </div>
                <div className="col"></div>
              </div>
            </div>
          </section>

        </main>

      </div>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query PlanetidNews1QueryEn {
    site {
      siteMetadata {
        en {
          title
          description
          keywords
        }
      }
    }
  }
`
