import React from 'react'
import { Helmet } from 'react-helmet'
import Link from 'gatsby-link'
import OgImage from "img/ogimg-planetcross.jpg"
import PartnerLogoNetgroup from "img/partner-logo-netgroup.png"
import get from 'lodash/get'
import { graphql } from 'gatsby'
import Layout from "../../components/layout"

class RootIndex extends React.Component {
  render() {
    const langPrefix = this.props.location.pathname.match(/^\/en/) ? '/en' : '';

    const siteTitle = get(this, 'props.data.site.siteMetadata.en.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.en.description')
    const siteKeywords = get(this, 'props.data.site.siteMetadata.en.keywords')

    return (
      <Layout location={this.props.location}>
        <Helmet
        	title={siteTitle}
        	meta={[
        		{ name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: 'https://planetway.com' + OgImage },
        		{ property: 'og:description', content: siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />
        <main className="no-header">

          <div className="clear" />

          <section className="padding-top-lg">
            <div className="container">
              <div className="row">
                <div className="col" />
                <div className="col-sm-10"><div className="partner-logo"><img src={PartnerLogoNetgroup} /></div>
                  <h4 className="underline">Net Group OÜ</h4>
                  <h5 className="margin-bottom-sm">Changing Africa by upgrading digital governance in Rwanda and Tanzania</h5>
                  <p>Net Group is a new generation software development and consultancy firm with 20 years of experience in the field and EMEA market. Through its software solutions, the company aims to boost efficiency and lower costs for its clients.</p>
                  <p>The company do not consider themselves as purely programmers, but rather as consultants with the aim to understand its clients’ business, by analyzing the needs and proving the most suitable and profitable solutions. Key aspects that we keep in mind when developing new solutions are simplicity, usability and direct effect on business process improvement.</p>
                  <p>Since 2002, Icefire has been designing and building technological solutions, mainly for clients in the financial sector.</p>
                  <h5 className="margin-top-md margin-bottom-sm">Key Competence Areas</h5>
                  <p>E-commerce (Digital Channels, Back-office Systems), Digital governance (Digital Justice, e-Education), Fintech (Depositary Banking, Asset Management), Utilities (CRM and Billing, Data integration), Digital business (Consulting, Software development)</p>
                  <h5 className="margin-top-md margin-bottom-sm">Case Study</h5>
                  <p>Net Group played a key role in encoding the design and developing an application in technical aspects for the Estonia’s biggest energy company Eesti Energia. As the internet users are increasingly using mobile devices, Eesti Energia needed to engage with customers in the same environment where the customers are. Besides the application’s technical aspects, Net Group also created application screens, worked with service layers and helped with front-end functionalities. Part of the success of the project came from coordination by Net Group that guaranteed understanding between the client and several technological teams. Net Group made sure that the application was visually appealing and easy to use and to remain relevant, the app has a built-in logic that allows easy upgrades to meet the changing customer habits and technological platforms.</p>
                  <p>For more information: <a href="https://www.netgroup.com/" target="_blank">https://www.netgroup.com/</a></p>
                  <p className="text-center mt-5"><Link to={langPrefix + "/pdsa-partners"} className="btn internal-link">Back</Link></p>
                </div>
                <div className="col" />
              </div>
            </div>
          </section>

        </main>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query PdsaPartnersNetgroupQueryEn {
    site {
      siteMetadata {
        en {
          title
          description
          keywords
        }
      }
    }
  }
`
