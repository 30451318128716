import React from 'react'
import { Helmet } from 'react-helmet'
import Link from 'gatsby-link'
import OgImage from "img/news-placeholder.jpg"
import PlanetIdHpArtwork from "img/planetid-hp-artwork.png"
import get from 'lodash/get'
import PressReleaseLatest from 'components/pressrelease-latest'
import PressReleaseSmall from 'components/pressrelease-small'
import { graphql } from 'gatsby'
import Layout from "../../components/layout"

class RootIndex extends React.Component {
  render() {
    const langPrefix = this.props.location.pathname.match(/^\/en/) ? '/en' : '';

    const siteTitle       = get(this, 'props.data.site.siteMetadata.en.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.en.description')
    const siteKeywords    = get(this, 'props.data.site.siteMetadata.en.keywords')
    const posts           = get(this, 'props.data.allContentfulPressRelease.edges')
    const members         = get(this, 'props.data.allContentfulTeamMember.edges')

    return (
      <Layout location={this.props.location}>
        <Helmet
          title={siteTitle}
          meta={[
          { name: 'description', content: siteDescription },
          { name: 'keywords', content: siteKeywords },
          { property: 'og:title', content: siteTitle },
          { property: 'og:type', content: 'website' },
          { property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
          { property: 'og:image', content: 'https://planetway.com' + OgImage },
          { property: 'og:description', content: siteDescription },
          { name: 'twitter:card', content: 'summary' },
          { name: 'twitter:site', content: '@Planetway_Tw' },
          ]}
          />
          <div className="slider">
            <div className="vimeo-wrapper">
              <iframe src="https://player.vimeo.com/video/385935996?background=1&autoplay=1&loop=1&byline=0&title=0&dnt=1"
              frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen />
            </div>
            <div className="video-caption">
              <strong className="subtitle-small">Planetway at</strong>
              <h2>DAVOS 2020</h2>
              <p>CEO and Founder of Planetway, Noriaki Hirao visited the 2020 World Economic Forum to present his vision for human-centric data sovereignty.</p>
              <Link to="" className="btn btn-outline margin-bottom-md" data-toggle="modal" data-target="#pwDavosVidPop">Watch Full Interview</Link>
              <p className="header-offsite-links"> <a href="https://ascii.jp/elem/000/004/000/4000038/" target="_blank">Davos 2020 on ASCII</a></p>
            </div>
          </div>

          <div id="pwDavosVidPop" className="modal fade" role="dialog">
            <div className="modal-dialog">

              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Human-Centric Data Sovereignty<span className="hide-on-mobile">: Revolutionize Your Personal Data with the Japan-Estonia Team</span></h4>
                  <button type="button" className="close" data-dismiss="modal">&times;</button>
                </div>
                <div className="modal-body">
                  <div style={{padding: '56.25% 0 0 0',position: 'relative'}}><iframe src="https://player.vimeo.com/video/385942278?color=FF40FF&byline=0&portrait=0&dnt=1" id="pwDavosVidFull" className="pw-davos-full" style={{position: 'absolute',top: '0',left: '0',width: '100%',height: '100%'}} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen /></div>
                </div>
              </div>

            </div>
          </div>

        <main>

          <div className="clear" />

          {posts.slice(0,1).map(({ node }) => {
            return (
              <PressReleaseLatest key={node.slug} post={node} />
            )
          })}

          <section className="padding-bottom-none">
            <div className="container">
              <div className="row">
                <div className="col" />
                <div className="col-lg-10 text-center mobile-text-left">
                  <h3 className="underline">Our Vision</h3>
                    <p>We believe that data should belong to individuals, not organizations. Individuals should have authority over the sharing of their data.
                    Essentially, our vision is of an <strong>independent, data-driven society</strong>. We aim create an era of <strong>human-centered data
                    sovereignty</strong> where data is used on an individual permission basis to best benefit the individual and society. In doing so, we strive
                    to realize a society that surpasses the constraints of traditional capitalism.</p>
                  <Link to={langPrefix + "/our-vision"} className="btn internal-link">Learn more about Planetway</Link>
                </div>
                <div className="col" />
              </div>
            </div>
          </section>

          <section className="padding-bottom-none">
            <div className="container">
              <div className="row">
                <div className="col">
                  <h3 className="underline">Solutions</h3>
                </div>
              </div>
            </div>
          </section>

          <section className="padding-top-none">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 text-center"><img src={PlanetIdHpArtwork} className="hp-planetid-artwork full-width-image" /></div>
                <div className="col-lg-6">
                  <h4>PlanetID</h4>
                  <strong className="subtitle-small">Personal Information Centralisation</strong>
                  <p><strong>An ID which ensures individuals have the right to complete control of their own data held by companies.</strong></p>
                  <ul>
                    <li>Extremely low risk of identity theft thanks to well-proven state-of-the-art technology from Estonia</li>
                    <li>Service driven approach focusing on ease of use and implementation<br />(initially targeting services with high usage frequency)</li>
                    <li>Application-based, easily scalable</li>
                    <li>Can be used both by residents and nonresidents due to universal ID</li>
                    <li>Individuals can control their own data (authorization/revocation and history browsing)</li>
                  </ul>
                  <Link to={langPrefix + "/planetid"} className="btn internal-link">More about PlanetID</Link>
                </div>
              </div>
            </div>
          </section>

          <section className="dark-blue-gradient text-on-dark">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 order-lg-0 order-12">
                  <h4>PlanetCross</h4>
                  <strong className="subtitle-small">Cross-Industry Secure Data Access Platform</strong>
                  <p>PlanetCross connects the databases of various enterprises in a decentralized manner. It combines solutions from the
                    world's most advanced e-government with technologies like blockchain to ensure the integrity and security of data and
                    make it accessible to both individuals and corporations. In addition, it realizes the concept of ‘Beyond API’.</p>
                  <Link to={langPrefix + "/planetcross"} className="btn internal-link">More about PlanetCross</Link>
                </div>
                <div className="col-lg-1" />
                <div className="col-lg-5 text-center"><div className="planetcross-logo-dark block-center" /></div>
              </div>
            </div>
          </section>

          <section className="padding-bottom-none">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 text-center"><div className="gdpr-artwork" /></div>
                <div className="col-lg-6 padding-top-lg">
                  <h4>Security Service for GDPR</h4>
                  <strong className="subtitle-small">GDPR Countermeasure Support Service</strong>
                  <p>We provide support services to help you comply with GDPR, the law that enhances and integrates data protection for all individuals in the EU.</p>
                  <Link to={langPrefix + "/security-service-for-gdpr"} className="btn internal-link">More about GDPR</Link>
                </div>
              </div>
            </div>
          </section>


          <section className="padding-bottom-none">
            <div className="container">
              <div className="row">
                <div className="col">
                  <h3 className="underline">Platforms</h3>
                </div>
              </div>
            </div>
          </section>

          {/*<section className="purple-gradient text-on-dark">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 text-center"><img src={PlanetGuardiansLogo} className="hp-planetguardians-logo padding-bottom-md" /></div>
                <div className="col-lg-6">
                  <h4>PlanetGuardians</h4>
                  <strong className="subtitle-small">Global Digital Security Initiative</strong>
                  <p>We offer a white hat hacker and security personnel development program, brought to you by security engineers, trainers and
                    advisors of world-class security institutions such as NATO, Cyber Terrorism Defense Centers, International Intelligence
                    Organizations, and International Police Organizations. This program involves online courses, Global Cyber Range (CyberPlanet),
                    and the provision of certifications. Details will be released soon.</p>
                  <Link to={langPrefix + "/planetguardians"} className="btn internal-link">Learn more about PlanetGuardians</Link>
                </div>
              </div>
            </div>
          </section>*/}

          {/*<section className="padding-top-none">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 order-lg-0 order-12">
                  <h4>PlanetEco</h4>
                  <strong className="subtitle-small">Open Innovation Platform</strong>
                  <p>PlanetEco is an open innovation platform for cooperating companies and organizations in a specific field. We utilize our
                    data exchange platform <Link to={langPrefix + "/planetcross"} className="internal-link">PlanetCross</Link> and our
                    personal authentication application <Link to={langPrefix + "/planetid"} className="internal-link">PlanetID</Link> to
                    develop new services. The four core fields we target are healthcare and medical services, real estate and smart city
                    services, finance and fintech services, automotive and vehicle sharing industry.</p>
                  <Link to={langPrefix + "/planeteco"} className="btn internal-link">Learn more about PlanetEco</Link>
                </div>
                <div className="col-lg-1"></div>
                <div className="col-lg-5 text-center"><div className="planeteco-logo block-center"></div></div>
              </div>
            </div>
          </section>*/}

          <section className="dark-blue-gradient text-on-dark margin-bottom-xl">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 order-lg-0 order-12">
                  <h4>PDSA</h4>
                  <strong className="subtitle-small">Planetway® Data Sovereignty Alliance</strong>
                  <p>A global business alliance for companies that promotes the realization of personal data sovereignty as set forth in our vision.</p>
                  <p>PDSA helps companies solve issues by providing solutions and use case content for enterprise issues such as DX, GDPR, and information security.
                  By providing content that supports business and technology, we help partner companies build, market, and sell our products and services.</p>
                  <Link to={langPrefix + "/planetway-data-sovereignty-alliance"} className="btn internal-link">More about PDSA</Link>
                </div>
                <div className="col-lg-1" />
                <div className="col-lg-5 text-center"><div className="pwdsa-pid-px-home-artwork" /></div>
              </div>
            </div>
          </section>

          <section className="video padding-top-none padding-bottom-none">
            <div className="container">
              <div className="row">
                <div className="col">
                  <h3 className="underline margin-bottom-lg">Planetway at MyData 2019</h3>
                  <div className="video-wrapper">
                    <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/q6COzzOsmUo" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="news">
            <div className="container">
              <div className="row">
                <div className="col">
                  <h3 className="underline margin-bottom-lg">News</h3>
                </div>
              </div>
            </div>

            <div className="container">
              {posts.map(({ node }) => {
                return (
                  <PressReleaseSmall key={node.slug} post={node} />
                )
              })}
            </div>

            <div className="container margin-top-sm margin-bottom-sm">
              <div className="row">
                <div className="col">
                  <Link to="/en/media" className="btn internal-link test-en">See more news</Link>
                </div>
              </div>
            </div>
          </section>

          <section className="padding-top-none">
            <div className="container">
              <div className="row">
                <div className="col">
                <h3 className="underline">Awards</h3>
                </div>
              </div>

              <div className="row">
                <div className="col-sm">
                  <div className="planetcross-award-ibmbluehub" />
                  <div className="text-center"><p>December 17, 2016</p></div>
                  <ul className="list-green-check">
                    <li><h5>Automotive</h5><p>Grand Prize/ Special Jury Prize (Nissan Motor Corporation)</p></li>
                    <li><h5>Healthcare</h5><p>Special Jury Prize (Dentsu Digital Corporation)</p></li>
                  </ul>
                </div>
                <div className="col-sm">
                  <div className="planetcross-award-efma" />
                  <div className="text-center"><p>May 02, 2017</p></div>
                  <ul className="list-green-check">
                    <li><h5>Efma–Accenture Innovation in Insurance Awards</h5><p>Grand Prize winner: Tokio Marine & Nichido Fire Insurance in collaboration with Planetway</p></li>
                  </ul>
                </div>
                <div className="col-sm">
                  <div className="planetcross-award-apec" />
                  <div className="text-center"><p>October 30, 2017</p></div>
                  <ul className="list-green-check">
                    <li><h5>Gartner Financial Services Eye on Innovation Awards for the Asia Pacific region 2017</h5><p>Finalist: Tokio Marine & Nichido Fire Insurance in collaboration with Planetway</p></li>
                  </ul>
                </div>
                <div className="col-sm">
                  <div className="planetcross-award-cert" />
                  <div className="text-center"><p>April 06, 2022</p></div>
                  <ul className="list-green-check">
                    <li><h5>Top 10 JAPANESE STARTUPS 2022</h5>
                    <p>Planetway is selected as one of the 10 leading startups in Japan by StartUp City magazine</p></li>
                  </ul>
                </div>
              </div>
            </div>
          </section>

        </main>

      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQueryEn {
    site {
      siteMetadata {
        en {
          title
          description
          keywords
        }
      }
    }
    allContentfulPressRelease(
      filter: {node_locale: {eq: "en"}, publishEnglish: {eq: true}},
      sort: { fields: [publishDate], order: DESC },
      limit: 4
    ) {
      edges {
        node {
          title
          slug
          type
          excerpt {
            childMarkdownRemark {
              html
            }
          }
          content {
            childMarkdownRemark {
              html
            }
          }
          photo {
            file {
              url
            }
          }
          publishDate(formatString: "LL", locale: "en")
          node_locale
          publishEnglish
        }
      }
    }
    allContentfulTeamMember(
      filter: {node_locale: {eq: "en"}, publishEnglish: {eq: true}},
      sort: { fields: [publishDate], order: DESC },
      limit: 400
    ) {
      edges {
        node {
          name
          slug
          position
          excerpt {
            childMarkdownRemark {
              html
            }
          }
          profile {
            childMarkdownRemark {
              html
            }
          }
          profilePicture {
            file {
              url
            }
          }
          america
          japan
          estonia
          type
          publishDate(formatString: "LL", locale: "en")
          node_locale
          publishEnglish
        }
      }
    }
  }
`
