import React from 'react'
import { Helmet } from 'react-helmet'
import OgImage from "img/ogimg-our-vision.jpg"
import ArtworkOurVisionBackground from "img/artwork-our-vision-background.svg"
import NoriSig from "img/nori-sig.svg"
import get from 'lodash/get'
import { graphql } from 'gatsby'
import Layout from "../../components/layout"

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.en.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.en.description')
    const siteKeywords = get(this, 'props.data.site.siteMetadata.en.keywords')

    return (
      <Layout location={this.props.location}>
        <Helmet
        	title={siteTitle}
        	meta={[
        		{ name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: 'https://planetway.com' + OgImage },
        		{ property: 'og:description', content: siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />
        <main className="no-header">

          <div className="clear" />

          <section>
            <div className="container">
              <div className="row">
                <div className="col" />
                <div className="col-sm-10 text-center">
                  <h3 className="underline">Our Vision</h3>
                  <p>We believe that data should belong to individuals, not organizations. Individuals should have authority over the sharing of their data.
                  Essentially, our vision is of an <strong>independent, data-driven society</strong>. We aim create an era of <strong>human-centered data
                  sovereignty</strong> where data is used on an individual permission basis to best benefit the individual and society. In doing so, we strive
                  to realize a society that surpasses the constraints of traditional capitalism.</p>
                </div>
                <div className="col" />
              </div>
            </div>
          </section>

          <section className="space-bg text-on-dark dia-bottom dia-top">
            <div className="container">
              <div className="row">
                <div className="col">
                  <h3 className="underline">Background</h3>
                </div>
              </div>
              <div className="row">
                <div className="col-sm">By 2020 there will be 50-100 billion devices connected to the internet and this amount will exceed 1 trillion by 2035.
                The IoT market is predicted to grow to incorporate hundreds of trillions of devices and be valued at thousands of trillions of Yen. Machine
                learning and neural networks development began in 2007, and now, the world is currently at a turning point with the third AI boom upon us.
                We are surrounded by advanced technologies and a diverse business environment, entering a turbulent era which will have a revolutionary
                impact in human history.</div>
                <div className="col-sm">However, many businesses still don’t know how to harness and exploit major technology trends such as IoT and AI.
                We aim to coordinate new and existing technological trends and transform them into businesses. As a technology development company, we will
                provide original technology and our own platform to do business globally.</div>
              </div>
              <div className="row">
                <div className="col-sm" />
                <div className="col-sm-10 padding-bottom-lg text-center">
                  <img src={ArtworkOurVisionBackground} className="margin-top-lg full-width-image" />
                </div>
                <div className="col-sm" />
              </div>
            </div>
          </section>

          <section>
            <div className="container">
              <div className="row">
                <div className="col-sm" />
                <div className="col-sm-8 text-center">
                  <h3 className="underline">Message from CEO</h3>
                  <p>We believe that a clear data utilization method alongside robust cybersecurity will be of utmost importance in the future. Our PlanetEco
                  platform is being developed to promote data utilization to create new business collaborations in various areas (including IoT / AI).
                  PlanetCross, our data access platform specializes in cross-industry data exchange. PlanetID is another product which provides a highly secure
                  'universal personal authentication infrastructure'. Our global standard white hat hacker development program, PlanetGuardians, promotes
                  cybersecurity collaboration. We are also working towards developing a proprietary data analysis engine that combines multiple open databases
                  to bring new value to the data they hold.</p>
                </div>
                <div className="col-sm" />
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="our-vision-sig block-center padding-top-md">
                    <span className="nori-pic" />
                    <span className="nori-sig">
                      <img src={NoriSig} style={{width: "200px", height: "auto"}} />
                      <h5 className="padding-top-sm">Noriaki Hirao</h5>
                      <p className="member-position">CEO & Founder</p>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query OurVisionQueryEn {
    site {
      siteMetadata {
        en {
          title
          description
          keywords
        }
      }
    }
  }
`
