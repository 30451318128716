import React from 'react'
import { Helmet } from 'react-helmet'
import BodyClassName from 'react-body-classname'
import Link from 'gatsby-link'
import OgImage from "img/ogimg-planeteco.jpg"
import get from 'lodash/get'
import { graphql } from 'gatsby'

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.en.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.en.description')
    const siteKeywords = get(this, 'props.data.site.siteMetadata.en.keywords')

    return (
      <div>
        <Helmet
        	title={`PlanetEco - ${siteTitle}`}
        	meta={[
        		{ name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: 'PlanetEco - ' + siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: 'https://planetway.com' + OgImage },
        		{ property: 'og:description', content: siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />
        <BodyClassName className='header-no-divider' />

        <div className="page-header planeteco-header dia-bottom">
          <div className="container text-left">
            <div className="row">
              <div className="col-md-6">
                <div className="planeteco-logo-artwork"></div>
              </div>
              <div className="col-md-6">
                <h2><span className="text-light">Planet</span>Eco</h2>
                <strong className="subtitle-small">Open Innovation Platform</strong>
                <p>PlanetEco is an open innovation platform to allow cooperation between companies and organizations in a specific industry. We utilize our data exchange platform, PlanetCross, and our personal authentication application, PlanetID, to develop new services.</p>
              </div>
            </div>
          </div>
        </div>

        <main>

          <div className="clear"></div>

          <section className="padding-bottom-none">
            <div className="container">
              <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-lg-8 text-center mobile-text-left">
                  <h3 className="underline">What is PlanetEco?</h3>
                  <p>PlanetEco is an open innovation platform to allow cooperation between companies and organizations in a specific industry. We utilize our data exchange platform, PlanetCross, and our personal authentication application, PlanetID, to develop new services.</p>
                </div>
                <div className="col-lg-2"></div>
              </div>
            </div>
          </section>

          <section className="padding-bottom-none">
            <div className="container">
              <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-lg-8 text-center mobile-text-left">
                  <h3 className="underline">Core Areas</h3>
                </div>
                <div className="col-lg-2"></div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-md text-center">
                  <div className="planeteco-theme-healthcare block-center"></div>
                  <p className="subtitle-small">Healthcare</p>
                </div>
                <div className="col-md text-center">
                  <div className="planeteco-theme-realestate block-center"></div>
                  <p className="subtitle-small">Real Estate</p>
                </div>
                <div className="col-md text-center">
                  <div className="planeteco-theme-finance block-center"></div>
                  <p className="subtitle-small">Finance</p>
                </div>
                <div className="col-md text-center">
                  <div className="planeteco-theme-automotive block-center"></div>
                  <p className="subtitle-small">Automotive</p>
                </div>
              </div>
            </div>
          </section>

          <section className="padding-bottom-none">
            <div className="container">
              <div className="row">
                <div className="col">
                  <h3 className="underline">Example Use Cases</h3>
                </div>
              </div>
            </div>
          </section>

          <section className="light-grey-bg">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <h4 className="numbered-title"><span className="number one"></span><span className="">Healthcare<strong className="subtitle-small">Collaboration between Medical Institution and Insurance Company</strong></span></h4>
                  <p><strong>Utilization of personal data on end user’s application</strong><br />
                  <strong>Purpose:</strong> Improve usability and reduce operational costs</p>
                  <p>When submitting an insurance claim, a patient can grant the hospital permission to exchange their electronic medical data with the insurance company. This exchange occurs rapidly and securely and greatly reduces the period from insurance claim to payment.</p>
                  <div className="planetid-x-planetcross"></div>
                </div>
                <div className="col-lg-6">
                  <div className="planeteco-healthcare-diagram-en block-center"></div>
                </div>
              </div>
            </div>
          </section>

          <section className="medium-grey-bg">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <h4 className="numbered-title"><span className="number two"></span><span className="">Finance<strong className="subtitle-small">Collaboration between financial institutions and other service providers</strong></span></h4>
                  <p><strong>Utilization of personal data based on service provider’s request</strong><br />
                  <strong>Purpose:</strong> Opportunity to create new services</p>
                  <p>End users can choose to register and disclose information such as their hobbies and interests on their accounts at different financial institutions. Other companies can then access this public data and provide new services. We expect this kind of system to create new business beneficial to both companies and individuals.</p>
                  <div className="planetid-x-planetcross"></div>
                </div>
                <div className="col-lg-6">
                  <div className="planeteco-finance-diagram-en block-center"></div>
                </div>
              </div>
            </div>
          </section>

          <section className="light-grey-bg">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h4 className="numbered-title"><span className="number three"></span><span className="">Smart City<strong className="subtitle-small">Business-government cooperation in specific areas</strong></span></h4>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-8">
                  <p>We will support the advancement of Smart Cities by partnering with real estate developers. Individuals living in the area will be provided IDs which they can use to receive all kinds of services in
                    various fields such as finance, education, and transportation</p>
                </div>
                <div className="col-lg-4">
                  <div className="planetid-x-planetcross block-center"></div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="planeteco-smartcity-diagram-en block-center"></div>
                </div>
              </div>
            </div>
          </section>

          <section className="medium-grey-bg">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h4 className="numbered-title"><span className="number four"></span><span className="">Automotive<strong className="subtitle-small"></strong></span></h4>
                  <h5 className="padding-top-lg padding-bottom-lg text-center">Coming soon</h5>
                </div>
              </div>
            </div>
          </section>

          <section className="light-grey-bg">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <h4 className="numbered-title"><span className="number five"></span><span className="">Secure P2P Data System<strong className="subtitle-small">Inter-company information collaboration</strong></span></h4>
                  <p>Distributed information exchange can be carried out over the internet without laying expensive, dedicated lines between headquarters and subsidiaries within the group, and agencies and suppliers outside
                  of the group. PlanetCross modules make it unnecessary to collect data centrally because it is possible to rapidly exchange data, not only including RDBs (Relational Databases), but also documents, movies, and sound. This drastically reduces the cost of information exchange.</p>
                  <div className="planetcross-small-grey-logo"></div>
                </div>
                <div className="col-lg-6">
                  <div className="planeteco-group-diagram-en block-center"></div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="container">
              <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-lg-8 text-center mobile-text-left">
                  <h3>Become a Partner</h3>
                  <p>We are looking for partners open to consider various use cases using our core technologies, PlanetID and PlanetCross!</p>
                  <Link to="/contact" className="btn internal-link">Contact Us</Link>
                </div>
                <div className="col-lg-2"></div>
              </div>
            </div>
          </section>

        </main>

      </div>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query PlanetEcoQueryEn {
    site {
      siteMetadata {
        en {
          title
          description
          keywords
        }
      }
    }
  }
`
