// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-202002110000-documents-js": () => import("./../src/pages/202002110000/documents.js" /* webpackChunkName: "component---src-pages-202002110000-documents-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-corporate-leadership-js": () => import("./../src/pages/corporate-leadership.js" /* webpackChunkName: "component---src-pages-corporate-leadership-js" */),
  "component---src-pages-corporate-profile-js": () => import("./../src/pages/corporate-profile.js" /* webpackChunkName: "component---src-pages-corporate-profile-js" */),
  "component---src-pages-en-202002110000-documents-js": () => import("./../src/pages/en/202002110000/documents.js" /* webpackChunkName: "component---src-pages-en-202002110000-documents-js" */),
  "component---src-pages-en-contact-js": () => import("./../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-corporate-leadership-js": () => import("./../src/pages/en/corporate-leadership.js" /* webpackChunkName: "component---src-pages-en-corporate-leadership-js" */),
  "component---src-pages-en-corporate-profile-js": () => import("./../src/pages/en/corporate-profile.js" /* webpackChunkName: "component---src-pages-en-corporate-profile-js" */),
  "component---src-pages-en-index-js": () => import("./../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-media-js": () => import("./../src/pages/en/media.js" /* webpackChunkName: "component---src-pages-en-media-js" */),
  "component---src-pages-en-our-vision-js": () => import("./../src/pages/en/our-vision.js" /* webpackChunkName: "component---src-pages-en-our-vision-js" */),
  "component---src-pages-en-pdsa-partners-aktors-js": () => import("./../src/pages/en/pdsa-partners-aktors.js" /* webpackChunkName: "component---src-pages-en-pdsa-partners-aktors-js" */),
  "component---src-pages-en-pdsa-partners-housall-js": () => import("./../src/pages/en/pdsa-partners-housall.js" /* webpackChunkName: "component---src-pages-en-pdsa-partners-housall-js" */),
  "component---src-pages-en-pdsa-partners-icefire-js": () => import("./../src/pages/en/pdsa-partners-icefire.js" /* webpackChunkName: "component---src-pages-en-pdsa-partners-icefire-js" */),
  "component---src-pages-en-pdsa-partners-js": () => import("./../src/pages/en/pdsa-partners.js" /* webpackChunkName: "component---src-pages-en-pdsa-partners-js" */),
  "component---src-pages-en-pdsa-partners-netgroup-js": () => import("./../src/pages/en/pdsa-partners-netgroup.js" /* webpackChunkName: "component---src-pages-en-pdsa-partners-netgroup-js" */),
  "component---src-pages-en-pdsa-partners-nortal-js": () => import("./../src/pages/en/pdsa-partners-nortal.js" /* webpackChunkName: "component---src-pages-en-pdsa-partners-nortal-js" */),
  "component---src-pages-en-planetcross-js": () => import("./../src/pages/en/planetcross.js" /* webpackChunkName: "component---src-pages-en-planetcross-js" */),
  "component---src-pages-en-planeteco-js": () => import("./../src/pages/en/planeteco.js" /* webpackChunkName: "component---src-pages-en-planeteco-js" */),
  "component---src-pages-en-planetforum-download-js": () => import("./../src/pages/en/planetforum-download.js" /* webpackChunkName: "component---src-pages-en-planetforum-download-js" */),
  "component---src-pages-en-planetforum-questionnaire-js": () => import("./../src/pages/en/planetforum-questionnaire.js" /* webpackChunkName: "component---src-pages-en-planetforum-questionnaire-js" */),
  "component---src-pages-en-planetforum-signup-js": () => import("./../src/pages/en/planetforum-signup.js" /* webpackChunkName: "component---src-pages-en-planetforum-signup-js" */),
  "component---src-pages-en-planetid-js": () => import("./../src/pages/en/planetid.js" /* webpackChunkName: "component---src-pages-en-planetid-js" */),
  "component---src-pages-en-planetid-news-planetid-start-js": () => import("./../src/pages/en/planetid/news/planetid-start.js" /* webpackChunkName: "component---src-pages-en-planetid-news-planetid-start-js" */),
  "component---src-pages-en-planetid-planetid-faq-js": () => import("./../src/pages/en/planetid/planetid-faq.js" /* webpackChunkName: "component---src-pages-en-planetid-planetid-faq-js" */),
  "component---src-pages-en-planetid-planetid-news-js": () => import("./../src/pages/en/planetid/planetid-news.js" /* webpackChunkName: "component---src-pages-en-planetid-planetid-news-js" */),
  "component---src-pages-en-planetid-planetid-user-guide-js": () => import("./../src/pages/en/planetid/planetid-user-guide.js" /* webpackChunkName: "component---src-pages-en-planetid-planetid-user-guide-js" */),
  "component---src-pages-en-planetid-privacy-notice-js": () => import("./../src/pages/en/planetid/privacy-notice.js" /* webpackChunkName: "component---src-pages-en-planetid-privacy-notice-js" */),
  "component---src-pages-en-planetway-data-sovereignty-alliance-js": () => import("./../src/pages/en/planetway-data-sovereignty-alliance.js" /* webpackChunkName: "component---src-pages-en-planetway-data-sovereignty-alliance-js" */),
  "component---src-pages-en-privacy-notice-js": () => import("./../src/pages/en/privacy-notice.js" /* webpackChunkName: "component---src-pages-en-privacy-notice-js" */),
  "component---src-pages-en-recruit-js": () => import("./../src/pages/en/recruit.js" /* webpackChunkName: "component---src-pages-en-recruit-js" */),
  "component---src-pages-en-security-service-for-gdpr-js": () => import("./../src/pages/en/security-service-for-gdpr.js" /* webpackChunkName: "component---src-pages-en-security-service-for-gdpr-js" */),
  "component---src-pages-en-thanks-js": () => import("./../src/pages/en/thanks.js" /* webpackChunkName: "component---src-pages-en-thanks-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-media-js": () => import("./../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-our-vision-js": () => import("./../src/pages/our-vision.js" /* webpackChunkName: "component---src-pages-our-vision-js" */),
  "component---src-pages-pdsa-partners-aktors-js": () => import("./../src/pages/pdsa-partners-aktors.js" /* webpackChunkName: "component---src-pages-pdsa-partners-aktors-js" */),
  "component---src-pages-pdsa-partners-housall-js": () => import("./../src/pages/pdsa-partners-housall.js" /* webpackChunkName: "component---src-pages-pdsa-partners-housall-js" */),
  "component---src-pages-pdsa-partners-icefire-js": () => import("./../src/pages/pdsa-partners-icefire.js" /* webpackChunkName: "component---src-pages-pdsa-partners-icefire-js" */),
  "component---src-pages-pdsa-partners-js": () => import("./../src/pages/pdsa-partners.js" /* webpackChunkName: "component---src-pages-pdsa-partners-js" */),
  "component---src-pages-pdsa-partners-netgroup-js": () => import("./../src/pages/pdsa-partners-netgroup.js" /* webpackChunkName: "component---src-pages-pdsa-partners-netgroup-js" */),
  "component---src-pages-pdsa-partners-nortal-js": () => import("./../src/pages/pdsa-partners-nortal.js" /* webpackChunkName: "component---src-pages-pdsa-partners-nortal-js" */),
  "component---src-pages-planetcross-js": () => import("./../src/pages/planetcross.js" /* webpackChunkName: "component---src-pages-planetcross-js" */),
  "component---src-pages-planetcross-slo-js": () => import("./../src/pages/planetcross/slo.js" /* webpackChunkName: "component---src-pages-planetcross-slo-js" */),
  "component---src-pages-planetcross-terms-of-service-js": () => import("./../src/pages/planetcross/terms-of-service.js" /* webpackChunkName: "component---src-pages-planetcross-terms-of-service-js" */),
  "component---src-pages-planeteco-js": () => import("./../src/pages/planeteco.js" /* webpackChunkName: "component---src-pages-planeteco-js" */),
  "component---src-pages-planetforum-download-js": () => import("./../src/pages/planetforum-download.js" /* webpackChunkName: "component---src-pages-planetforum-download-js" */),
  "component---src-pages-planetforum-questionnaire-js": () => import("./../src/pages/planetforum-questionnaire.js" /* webpackChunkName: "component---src-pages-planetforum-questionnaire-js" */),
  "component---src-pages-planetforum-signup-js": () => import("./../src/pages/planetforum-signup.js" /* webpackChunkName: "component---src-pages-planetforum-signup-js" */),
  "component---src-pages-planetid-js": () => import("./../src/pages/planetid.js" /* webpackChunkName: "component---src-pages-planetid-js" */),
  "component---src-pages-planetid-news-planetid-start-js": () => import("./../src/pages/planetid/news/planetid-start.js" /* webpackChunkName: "component---src-pages-planetid-news-planetid-start-js" */),
  "component---src-pages-planetid-planetid-faq-js": () => import("./../src/pages/planetid/planetid-faq.js" /* webpackChunkName: "component---src-pages-planetid-planetid-faq-js" */),
  "component---src-pages-planetid-planetid-news-js": () => import("./../src/pages/planetid/planetid-news.js" /* webpackChunkName: "component---src-pages-planetid-planetid-news-js" */),
  "component---src-pages-planetid-planetid-user-guide-js": () => import("./../src/pages/planetid/planetid-user-guide.js" /* webpackChunkName: "component---src-pages-planetid-planetid-user-guide-js" */),
  "component---src-pages-planetid-privacy-notice-js": () => import("./../src/pages/planetid/privacy-notice.js" /* webpackChunkName: "component---src-pages-planetid-privacy-notice-js" */),
  "component---src-pages-planetid-slo-js": () => import("./../src/pages/planetid/slo.js" /* webpackChunkName: "component---src-pages-planetid-slo-js" */),
  "component---src-pages-planetid-terms-of-service-js": () => import("./../src/pages/planetid/terms-of-service.js" /* webpackChunkName: "component---src-pages-planetid-terms-of-service-js" */),
  "component---src-pages-planetway-data-sovereignty-alliance-js": () => import("./../src/pages/planetway-data-sovereignty-alliance.js" /* webpackChunkName: "component---src-pages-planetway-data-sovereignty-alliance-js" */),
  "component---src-pages-privacy-notice-js": () => import("./../src/pages/privacy-notice.js" /* webpackChunkName: "component---src-pages-privacy-notice-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-recruit-js": () => import("./../src/pages/recruit.js" /* webpackChunkName: "component---src-pages-recruit-js" */),
  "component---src-pages-security-service-for-gdpr-js": () => import("./../src/pages/security-service-for-gdpr.js" /* webpackChunkName: "component---src-pages-security-service-for-gdpr-js" */),
  "component---src-pages-thanks-js": () => import("./../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-templates-pressrelease-js": () => import("./../src/templates/pressrelease.js" /* webpackChunkName: "component---src-templates-pressrelease-js" */),
  "component---src-templates-teammember-js": () => import("./../src/templates/teammember.js" /* webpackChunkName: "component---src-templates-teammember-js" */)
}

