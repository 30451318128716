import React from 'react'
import { Helmet } from 'react-helmet'
import Link from 'gatsby-link'
import OgImage from "img/ogimg-planetid.jpg"
import get from 'lodash/get'
import { graphql } from 'gatsby'

class RootIndex extends React.Component {
  render() {
    const langPrefix = this.props.location.pathname.match(/^\/en/) ? '/en' : '';

    const siteTitle = get(this, 'props.data.site.siteMetadata.en.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.en.description')
    const siteKeywords = get(this, 'props.data.site.siteMetadata.en.keywords')

    return (
      <div>
        <Helmet
        	title={siteTitle}
        	meta={[
        		{ name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: 'https://planetway.com' + OgImage },
        		{ property: 'og:description', content: siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />
        <main>

          <section className="pt-5">
            <div className="container">
              <div className="row">
                <div className="col"></div>
                <div className="col-sm-10">
                  <h4 className="underline">Privacy Notice</h4>

                  <div className="mb-5">
                    <span class="news-date margin-bottom-sm">Dated 27th March 2020, version 1.0</span>
                    <p>We explain in this Privacy Notice ("Notice" or "here") how Planetway (see our profile "we" or "our" or "us") collects or processes the personal data of the visitors and users of the website planetway.com („website“), mobile apps and any other platforms and services (altogether "Planetway solutions"). We will refer to any visitor and user as “you”, "your" or the “data subject”.</p>
                    <p>We are a data controller for all information that is processed with Planetway solutions. This means that we are responsible for the purposes and means of processing of personal data. We also have the right to amend this notice unilaterally, and in that case, we will publish the change on our website, inside mobile apps, app store, or otherwise.</p>
                    <p>This Privacy Notice does not apply to the personal data processed in the PlanetCross platform itself. In such case the data controller is the company acquiring and using the PlanetCross platform and they are responsible for the processing of personal data. Planetway is in this instance what we call a "data processor".</p>

                    <h5 className="mb-3 margin-top-lg">What kind of personal data we process</h5>
                    <p>We may process the following categories of personal data of you as the data subject:</p>
                    <ol>
                      <li>Website visit data and cookies:
                        <ul className="pt-2">
                          <li>The data regarding how you use the website. Please see the explanation in section “Cookies and other web technologies” below.</li>
                          <li>The IP address</li>
                          <li>Your generic geolocation</li>
                        </ul>
                      </li>
                      <li className="pt-2">Contact data, e.g. in contracts or by filling in web forms:
                        <ul className="pt-2">
                          <li>First name and surname</li>
                          <li>Company</li>
                          <li>Email address</li>
                          <li>Phone number</li>
                        </ul>
                      </li>
                      <li className="pt-2">Customer identifying data:
                        <ul className="pt-2">
                          <li>Employer (Company)</li>
                          <li>First name and surname</li>
                          <li>Job position</li>
                          <li>Customer communication history</li>
                          <li>Customer ID or user ID (e.g PlanetID or PlanetCross company ID)</li>
                        </ul>
                      </li>
                      <li className="pt-2">Contract related data (see also Contact data):
                        <ul className="pt-2">
                          <li>Transaction/payment history (no credit card data)</li>
                          <li>Agreements related to the customer</li>
                        </ul>
                      </li>
                      <li className="pt-2">Inquiry data (regardless of what is the reason for sending the inquiry or contacting us):
                        <ul className="pt-2">
                          <li>Information about an incident</li>
                          <li>Customer, visitor or user communication history</li>
                        </ul>
                      </li>

                    </ol>

                    <aside className="notes-box ml-0 margin-top-lg margin-bottom-lg" style={{ maxWidth: "none" }}>
                      <h5 className="box-header icon-warning">Please note</h5>
                      <div className="box-body">
                        <p>Planetway does not request any visitor to submit special categories of data (racial or ethnic origin, political opinions, religious or philosophical beliefs, or trade union membership, genetic data, biometric data, health data or data concerning a natural person’s sex life or sexual orientation). If such data is submitted by visitors for any reason, we will not process them and delete them.</p>
                      </div>
                    </aside>

                    <h5 className="mb-3 margin-top-lg">For what purpose do we process your personal data</h5>
                    <p>We use your personal data to operate and improve our Planetway solutions, customer support and to maintain contracts:</p>
                    <p><strong className="medium">Processing for the performance of the contract</strong></p>
                    <p>What situations are covered here (use cases):</p>
                    <ol>
                      <li>Establish a contract relationship such as:
                        <ul className="pt-2">
                          <li>User Agreement for any of the Planetway products and services</li>
                          <li>Terms and Conditions for becoming a member of a Planetway community (e.g. PX FreeTrial or Planetway Data Sovereignty Alliance)</li>
                        </ul>
                      </li>
                      <li>Develop an existing contract relationship</li>
                      <li>Identify yourself as a customer</li>
                      <li>Receive service support</li>
                    </ol>
                    <p>What data may we process:</p>
                    <ol>
                      <li>First name and surname</li>
                      <li>Email address</li>
                      <li>Phone number</li>
                      <li>Employer (Company)</li>
                      <li>Company domain name</li>
                      <li>Job position</li>
                      <li>Customer communication history</li>
                      <li>Transaction/payment history</li>
                      <li>Agreements related to the customer</li>
                    </ol>
                    <p><strong className="medium">Processing based on the legitimate interest</strong></p>
                    <p>What situations are covered here (use cases):</p>
                    <ol>
                      <li>Create your account for the interaction with other community members of Planetway community</li>
                      <li>Manage information security and prevent data breaches</li>
                      <li>Answer your inquiries about Planetway</li>
                      <li>Digitally signed documents with PlanetID (after the end of PlanetID usage)</li>
                    </ol>
                    <p>What data may we process:</p>
                    <ol>
                      <li>First name and surname</li>
                      <li>Email address</li>
                      <li>Phone number</li>
                      <li>Employer (Company)</li>
                      <li>Company domain name</li>
                      <li>Job position</li>
                      <li>Information about an incident</li>
                      <li>Customer communication history</li>
                      <li>PlanetID digital signature</li>
                    </ol>
                    <p><strong className="medium">Processing based on user consent</strong></p>
                    <p>What situations are covered here (use cases):</p>
                    <ol>
                      <li>Marketing and sales campaigns and relationship management</li>
                      <li>Inform about upcoming seminars and other relevant events</li>
                      <li>Feedback on how we run our services and events</li>
                    </ol>
                    <p>What data may we process:</p>
                    <ol>
                      <li>The data regarding how you use the Planetway solution</li>
                      <li>Your generic geolocation</li>
                      <li>First name and surname</li>
                      <li>Email address</li>
                      <li>Phone number</li>
                      <li>Employer (Company)</li>
                      <li>Job position</li>
                    </ol>

                    <h5 className="mb-3 margin-top-lg">How do we process personal data</h5>

                    <p>We process the personal data as follows:</p>

                    <ul>
                      <li>All data is processed in electronic format</li>
                      <li>You, the data subject are instructed to read this Privacy Notice</li>
                      <li>We process the data subject’s personal data in accordance with the requirements of the GDPR and the APPI (個人情報の保護に関する法律).</li>
                      <li>We set up the following retention policies for the personal data
                        <ul>
                          <li>2 years for marketing purpose</li>
                          <li>5 years for personal data related to customer support inquiry</li>
                          <li>10 years for personal data needed for the performance of the contract (started from the due date of the contract)</li>
                          <li>15 years for personal data related to security incidents or data breaches</li>
                          <li>permanent for signed documents with PlanetID</li>
                        </ul>
                      </li>
                      <li>We are not obliged to preserve the personal data of the data subjects longer as indicated above, unless required by applicable law</li>
                    </ul>

                    <h5 className="mb-3 margin-top-lg">Processors</h5>
                    <p>While Planetway is the data controller, we are using the following sub-processors for processing the data on Planetway solutions:</p>
                    <ul>
                      <li>Amazon AWS (<a href="https://aws.amazon.com/privacy/" target="_blank">https://aws.amazon.com/privacy/</a>)</li>
                      <li>Microsoft Office 365 (<a href="https://privacy.microsoft.com/en-GB/privacystatement" target="_blank">https://privacy.microsoft.com/en-GB/privacystatement</a>)</li>
                      <li>Google for Google Analytics and Youtube functionality on our homepage (<a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>)</li>
                      <li>Vimeo for video functionality on our homepage (<a href="https://vimeo.com/privacy" target="_blank">https://vimeo.com/privacy</a>)</li>
                      <li>Formspree website services (<a href="https://formspree.io/legal/privacy-policy" target="_blank">https://formspree.io/legal/privacy-policy</a>)</li>
                      <li>Slack (<a href="https://slack.com/intl/en-ee/privacy-policy" target="_blank">https://slack.com/intl/en-ee/privacy-policy</a>).</li>
                    </ul>

                    <h5 className="mb-3 margin-top-lg">Your rights as the data subject</h5>
                    <p>You as the data subject are entitled, at any time and in accordance with GDPR, to:</p>
                    <ul>
                      <li>Request information about data processing</li>
                      <li>Request access to or copy of your personal data</li>
                      <li>Rectify inaccurate or incomplete personal data</li>
                      <li>Request that we erase your personal data</li>
                      <li>Withdraw your consent for processing of personal data</li>
                    </ul>
                    <p>In order to exercise these rights, the data subject shall forward respective application to <a href="mailto:privacy@planetway.com">privacy@planetway.com</a></p>

                    <h5 className="mb-3 margin-top-lg">Security of your personal data</h5>
                    <p>Planetway has in place organisational, physical and technical security measures to ensure the security of the personal data processed with Planetway solutions.</p>
                    <p>Any suspicious activity related to the confidentiality, integrity or availability of the personal or other data of you or other data subjects shall be reported to <a href="mailto:security@planetway.com">security@planetway.com</a>.</p>
                    <p>We will promptly notify you, the data subject(s) of any facts known to us concerning any personal data breach, which is likely to result in a high risk to your rights. In doing that, we will communicate in clear and plain language the nature of the breach and describe the likely consequences. We will also explain measures to limit the negative effects of the event for you, the data subject(s).</p>

                    <h5 className="mb-3 margin-top-lg">Cookies</h5>
                    <p>We use cookies on our website to improve your browsing experience and also collecting generic non-personalised statistical information. Web site provides option to manage cookies. If you disable or refuse cookies, please note that some parts of the site may then be inaccessible or not function properly.</p>
                    <p>PlanetID cross-authentication web application is using only functional cookies to identify the PlanetID user. No other cookies are used.</p>

                    <h5 className="mb-3 margin-top-lg">Transferring your personal data</h5>
                    <p>Planetway shall not forward, sell or disclose the personal data to third parties without informing you.</p>
                    <p>We may share the data within the Planetway group companies (<Link to={langPrefix + "/corporate-profile"} className="internal-link">https://planetway.com/corporate-profile/</Link>) located in Japan, Estonia and in the United States of America.</p>

                    <h5 className="mb-3 margin-top-lg">Disclosing your personal data</h5>
                    <p>There may be circumstances when we are required to disclose personal data by law or governmental authorities. This is done in accordance with GDPR.</p>

                    <h5 className="mb-3 margin-top-lg">Termination of this notice</h5>
                    <p>This Privacy Notice is in effect for an indefinite period of time.</p>

                  </div>

                </div>
                <div className="col"></div>
              </div>
            </div>
          </section>

        </main>

      </div>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query PlanetidPrivacyQueryEn {
    site {
      siteMetadata {
        en {
          title
          description
          keywords
        }
      }
    }
  }
`
