import React from 'react'
import { Helmet } from 'react-helmet'
import Link from 'gatsby-link'
import OgImage from "img/ogimg-planetcross.jpg"
import PartnerLogoHousall from "img/partner-logo-housall.png"
import get from 'lodash/get'
import { graphql } from 'gatsby'
import Layout from "../components/layout"

class RootIndex extends React.Component {
  render() {
    const langPrefix = this.props.location.pathname.match(/^\/en/) ? '/en' : '';

    const siteTitle = get(this, 'props.data.site.siteMetadata.en.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.en.description')
    const siteKeywords = get(this, 'props.data.site.siteMetadata.en.keywords')

    return (
      <Layout location={this.props.location}>
        <Helmet
        	title={siteTitle}
        	meta={[
        		{ name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: 'https://planetway.com' + OgImage },
        		{ property: 'og:description', content: siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />
        <main className="no-header">

          <div className="clear" />

          <section className="padding-top-lg">
            <div className="container">
              <div className="row">
                <div className="col" />
                <div className="col-sm-10"><div className="partner-logo"><img src={PartnerLogoHousall} /></div>
                  <h4 className="underline"><strong>株式会社</strong>HOUSALL</h4>
                  <h5 className="margin-bottom-sm"><strong>住まいと暮らしにまつわる新たな</strong>B2B2C<strong>ビジネスモデルを創出し、業種・業態を超えた効率性・利便性を可能とするプラットフォーム実現を目指します。</strong></h5>
                  <p>「家・住まい」および「暮らし・生活」における専門家・プロを集結し、様々な産業が活用できる “住生活サービスプラットフォーム”を構築致します。生活者の皆様にはワンストップサービスの利便性を、企業の皆様には効率化と付加価値向上の土台づくりをご実現します。画一的なサービスのご提供ではなく、常に新奇性の高いサービスやビジネスモデルを考案し、いつまでも住みやすい住宅、安心・安全に暮らせる街、より快適で便利な生活を実現することで、企業や地域の魅力度が向上し、活性化することをめざします。</p>
                  <h5 className="margin-top-md margin-bottom-sm"><strong>主な得意分野</strong></h5>
                  <p>生活者に対する住まい、暮らしの総合サポート。住宅・不動産事業・生活サービスに関するBPO、仲介、マッチング、物販。事業創出、経営革新、業務効率化に関するコンサルティング。マーケティング、広告、ＣＲＭ代行。</p>
                  <h5 className="margin-top-md margin-bottom-sm"><strong>ケーススタディ</strong></h5>
                  <p>HOUSALLは、多くの住生活関連企業（住宅/エネルギー/鉄道等）の重要なソリューションパートナーであるため、日本における住宅関連産業へのITインフラビジネス展開の主要なプレイヤーです。</p>
                  <p>住宅関連のお客様向けには、住宅長期利用に資する保証サービス、防犯・防災に対応するための商材・サービス開拓及び住宅業界全体の効率化を実現し、関連労働人口減少という社会課題解決の施策となりうる施工・物流最適化等に深く貢献し、今後、業種・業態を超えたプラットフォーム化の実現を目指しています。その強みである、事業、企業商材・サービスマッチング力、新ビジネスモデル構想・企画力を駆使するとともに、情報共有化インフラとして、PLANETWAY利活用を推進致します。</p>
                  {/* <p>詳細はこちら: <a href="http://www.housall.co.jp/" target="_blank">http://www.housall.co.jp/</a></p> */}
                  <p className="text-center mt-5"><Link to={langPrefix + "/pdsa-partners"} className="btn internal-link">一覧に戻る</Link></p>
                </div>
                <div className="col" />
              </div>
            </div>
          </section>

        </main>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query PdsaPartnersHousallQuery {
    site {
      siteMetadata {
        en {
          title
          description
          keywords
        }
      }
    }
  }
`
