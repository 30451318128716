import React from 'react'
import { Helmet } from 'react-helmet'
import Link from 'gatsby-link'
import OgImage from "img/ogimg-planetcross.jpg"
import PartnerLogoNortal from "img/partner-logo-nortal.png"
import get from 'lodash/get'
import { graphql } from 'gatsby'
import Layout from "../components/layout"

class RootIndex extends React.Component {
  render() {
    const langPrefix = this.props.location.pathname.match(/^\/en/) ? '/en' : '';

    const siteTitle = get(this, 'props.data.site.siteMetadata.en.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.en.description')
    const siteKeywords = get(this, 'props.data.site.siteMetadata.en.keywords')

    return (
      <Layout location={this.props.location}>
        <Helmet
        	title={siteTitle}
        	meta={[
        		{ name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: 'https://planetway.com' + OgImage },
        		{ property: 'og:description', content: siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />
        <main className="no-header">

          <div className="clear" />

          <section className="padding-top-lg">
            <div className="container">
              <div className="row">
                <div className="col" />
                <div className="col-sm-10"><div className="partner-logo"><img src={PartnerLogoNortal} /></div>
                  <h4 className="underline">Nortal AS</h4>
                  <h5 className="margin-bottom-sm"><strong>エストニアの電子政府ソリューションの</strong>40%<strong>以上を構築したマーケットリーダー</strong></h5>
                  <p>Nortalは、多国籍な戦略的変革およびテクノロジー企業です。Nortalは エストニアをデジタルリーダーに変え、ビジネスに変化をもたらすというユニークな実績を持ち合わせています。この実績を戦略的アプローチとデータ駆動型テクノロジーと組み合わせることで、シームレスな社会を構築することが私たちのビジョンです。 Nortalは10か国に存在し、ヨーロッパ、中東、アフリカ、北米でインパクトのあるプロジェクトを遂行するプロフェッショナルメンバーが揃っています。</p>
                  <p>Nortalは、組織や国がデータ主導の戦略を構築、変革することをサポートし、変更管理とテクノロジーの提供を行います。 もともと2000年に設立されたNortalは、エストニアの電子政府ソリューションのデジタル変革の40％以上を計画および実行してきました。つまり、同社はデジタルでシームレスな社会を構築するユニークな実績を持っています。 つい最近、Nortalがオマーンで新しい事業登記システムを作成し、3分足らずで会社を登録できるようになりました。 その結果、オマーンの格付けは、世界銀行によるビジネス環境ランキングで127位上昇しています。</p>
                  <h5 className="margin-top-md margin-bottom-sm"><strong>主な得意分野</strong></h5>
                  <p>世界銀行によるビジネス環境の格付け向上、財政管理、デジタルヘルスケア、電子ID、ビッグデータ分析、データ保護、収益科学、EC、インダストリー4.0、クラウド、デザインサービス</p>
                  <h5 className="margin-top-md margin-bottom-sm"><strong>ケーススタディ</strong></h5>
                  <p>Nortalは、チップカード（IDカード）、SIMベースのMobile-ID、物理チップに接続されていないモバイルアプリケーションSmart-IDに基づくeIDシステムの開発における重要なパートナーであるため、エストニアeIDインフラストラクチャ開発の主要なプレーヤーです。</p>
                  <p>バルト諸国では、オンラインバンキング用の新しいeIDソリューションを開発することで、特別なSIMカードを必要としない認証およびデジタル署名用の超セキュアなモバイルベースのシステムという、はるかに強力なものが生まれました。 任意のオンラインサービスプロバイダーがこれを採用でき、ユーザーには無料で提供、当然、またたく間に浸透しています。</p>
                  <p>過去10年にわたり、Nortalは既存のeIDソリューションの構築、変革を支援し、それらを統合してサービスを提供してきました。NortalはeIDを電子政府インフラストラクチャの自然な部分にすることに大きく貢献してきました。 NortalはeIDインフラストラクチャ、信頼サービス、電子署名、認証ソリューション、ポリシーの確立、法的環境のサポート、全国的なeGovernanceなど、電子IDの実装を成功させるために必要なすべての側面をサポートしています。</p>
                  <p>詳細はこちら: <a href="https://nortal.com/" target="_blank">https://nortal.com/</a></p>
                  <p className="text-center mt-5"><Link to={langPrefix + "/pdsa-partners"} className="btn internal-link">一覧に戻る</Link></p>
                </div>
                <div className="col" />
              </div>
            </div>
          </section>

        </main>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query PdsaPartnersNortalQuery {
    site {
      siteMetadata {
        en {
          title
          description
          keywords
        }
      }
    }
  }
`
