import React from 'react'
import { Helmet } from 'react-helmet'
import Link from 'gatsby-link'
import OgImage from "img/ogimg-planetcross.jpg"
import get from 'lodash/get'
import { graphql } from 'gatsby'
import Layout from "../components/layout"

class RootIndex extends React.Component {
  render() {
    const langPrefix = this.props.location.pathname.match(/^\/en/) ? '/en' : '';

    const siteTitle = get(this, 'props.data.site.siteMetadata.en.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.en.description')
    const siteKeywords = get(this, 'props.data.site.siteMetadata.en.keywords')

    return (
      <Layout location={this.props.location}>
        <Helmet
        	title={siteTitle}
        	meta={[
        		{ name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: 'https://planetway.com' + OgImage },
        		{ property: 'og:description', content: siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />

        <div className="page-header gdpr-header">
          <div className="container text-on-dark">
            <div className="row">
              <div className="col-md-6"><div className="gdpr-artwork" /></div>
              <div className="col-md-6 mt-5">
                <h3>Security Service for GDPR</h3>
                <p>EU内の全ての個人のためにデータ保護を強化し統合する法律GDPRに準拠するための対策支援サービスを提供します。</p>
                <Link to={langPrefix + "/contact"} className="btn internal-link">Contact Us</Link>
              </div>
            </div>
          </div>
        </div>

        <main>

          <div className="clear" />

          <section>
            <div className="container">
              <div className="row">
                <div className="col" />
                <div className="col-lg-10 text-center mobile-text-left">
                  <h4 className="underline">GDPR<strong>対策支援サービス</strong></h4>
                  <p className="padding-bottom-sm">2018年5月25日から施行されたGDPRとは、一般データ保護規則（General Data Protection Regulation）の略であり、日本を含むEU域外の企業にも広く影響があり、また昨今、違反企業への高額な制裁金を課したこと等で認識されています。本法律に違反することなく事業展開を行うための対策サービスを提供します。</p>
                </div>
                <div className="col" />
              </div>
            </div>
          </section>

          <section className="pwdsa-infini-blur-bg text-on-dark">
            <div className="container">
              <div className="row padding-top-md">
                <div className="col-sm-6 padding-bottom-lg">
                  <div className="gdpr-icon-text d-flex align-items-center">
                    <div className="icon-user-check" />
                    <div className="flex-fill">
                      <h4><strong>アセスメント&amp;トレーニング</strong></h4>
                      <p>法令遵守状況を評価、分析、トレーニング実施。</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 padding-bottom-lg">
                  <div className="gdpr-icon-text d-flex align-items-center">
                    <div className="icon-target" />
                    <div className="flex-fill">
                      <h4><strong>要件定義</strong></h4>
                      <p>法令遵守対応要件を整理。</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 padding-bottom-lg">
                  <div className="gdpr-icon-text d-flex align-items-center">
                    <div className="icon-tools" />
                    <div className="flex-fill">
                      <h4><strong>実装支援</strong></h4>
                      <p>規定、ポリシー、プロセスの策定支援。</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 padding-bottom-lg">
                  <div className="gdpr-icon-text d-flex align-items-center">
                    <div className="icon-clipboard" />
                    <div className="flex-fill">
                      <h4><strong>定期監査</strong></h4>
                      <p>遵守状況の継続チェック、監査実施。</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 text-center padding-bottom-md">
                  <Link to={langPrefix + "/contact"} className="btn internal-link">Contact Us</Link>
                </div>
              </div>
            </div>
          </section>
        </main>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query GdprQuery {
    site {
      siteMetadata {
        en {
          title
          description
          keywords
        }
      }
    }
  }
`
