import React from 'react'
import { Helmet } from 'react-helmet'
import OgImage from "img/ogimg-planetid.jpg"
import get from 'lodash/get'
import { graphql } from 'gatsby'
import Layout from "../components/layout"

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.description')
    const siteKeywords = get(this, 'props.data.site.siteMetadata.keywords')

    return (
      <Layout location={this.props.location}>
        <Helmet
        	title={`PlanetID - ${siteTitle}`}
        	meta={[
        		{ name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: 'PlanetID - ' + siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: 'https://planetway.com' + OgImage },
        		{ property: 'og:description', content: siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />

        <div className="page-header planetid-header dia-bottom">
          <div className="container text-on-dark text-left">
            <div className="row">
              <div className="col-md-6">
                <div className="hp-slider-planetid-logo" />
                <h2><span className="text-light">Planet</span>ID</h2>
                <strong className="subtitle-small">The key to your digital self</strong>
                <p>PlanetIDは、ICT先進国エストニアにおける最先端技術の活用により、居住者/ 非居住者にかかわらず個々のエンドユーザーやIoT機器等に付与される ユニバーサルなIDです。</p>
              </div>
              <div className="col-md-6">
                <div className="hp-slider-planetid-device" />
              </div>
            </div>
          </div>
        </div>

        <main>

          <div className="clear" />

          <section className="padding-bottom-none">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 order-lg-0 order-12">
                  <h3 className="underline"><strong>認証</strong></h3>
                  <p>金融機関や通信会社等を対象に法律で義務付けられている本人確認の手続きを経て、PlanetIDは発行されます。エンドユーザーの認証用の電子証明書を元にした秘密鍵が分割/暗号化されるため、サービスログインの際には強固なセキュリティ技術による認証が行われます。（生体認証を含む多要素認証が可能）</p>
                </div>
                <div className="col-lg-4 text-center padding-bottom-lg"><div className="planetid-authenticate block-center" /></div>
              </div>
            </div>
          </section>

          <section className="padding-bottom-none">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 text-center padding-bottom-lg"><div className="planetid-digitally-sign block-center" /></div>
                <div className="col-lg-8">
                  <h3 className="underline"><strong>電子署名</strong></h3>
                  <p>データ参照/取得や様々なトランザクション実行時には、エンドユーザーの署名用の電子証明書を活用した電子署名を行うことが可能です。これによりサインや印鑑は不要となり、スマートコントラクトの実現を後押します。</p>
                </div>
              </div>
            </div>
          </section>

          <section className="padding-bottom-none">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 order-lg-0 order-12">
                  <h3 className="underline"><strong>情報共有</strong></h3>
                  <p>企業間の情報連携基盤であるPlanetCrossとの併用により、エンドユーザーは自らの個人データの他企業への共有を許諾することが可能となります。PlanetIDにより許諾がされた後、データを保有する企業は外部のデータを活用したい企業へ連携され、個人に対してサービス提供が行われます。</p>
                </div>
                <div className="col-lg-4 text-center padding-bottom-lg"><div className="planetid-share-information block-center" /></div>
              </div>
            </div>
          </section>

          <section>
            <div className="container">
              <div className="row">
                <div className="col-lg-4 text-center padding-bottom-lg"><div className="planetid-protect-your-data block-center" /></div>
                <div className="col-lg-8">
                  <h3 className="underline"><strong>個人データ保護</strong></h3>
                  <p>個人データは強固なセキュリティ基盤によりなりすましを極限まで回避できます。加えて誰が自らのデータを参照したかを確認でき、誰に対して公開するかを設定（制御）することが可能です。</p>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h3 className="underline">How it works</h3>
                </div>
              </div>
              <div className="row margin-bottom-lg">
                <div className="col-md-4 text-center">
                  <div className="planetid-how-step-1 block-center" />
                  <h4>Step 1</h4>
                  <p>PlanetIDアプリを<a href="https://apps.apple.com/jp/app/planetid/id1448126526" target="_blank">App Store</a>または
                  <a href="https://play.google.com/store/apps/details?id=com.planetway.planetid.public" target="_blank">Google Play</a>よりダウンロードし、スマートフォンにインストールします</p>
                </div>
                <div className="col-md-4 text-center">
                  <div className="planetid-how-step-2 block-center" />
                  <h4>Step 2</h4>
                  <p>アプリケーション上で初期登録を行い、本人確認企業を選択した上で本人確認手続きを行い、PlanetIDを発行します</p>
                </div>
                <div className="col-md-4 text-center">
                  <div className="planetid-how-step-3 block-center" />
                  <h4>Step 3</h4>
                  <p>企業/団体等のサービスにおいて、発行されたPlanetIDを認証や署名時に活用します</p>
                </div>
              </div>
              <div className="row margin-bottom-sm">
                <div className="col-md-12 text-center">
                  {/* <a href="#/" className="app-store-button">Available on the App Store</a><a href="#/" className="google-play-button">Android App on Google Play</a> */}
                </div>
              </div>
            </div>
          </section>
        </main>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query PlanetIdQuery {
    site {
      siteMetadata {
        title
        description
        keywords
      }
    }
  }
`
