import React from 'react'
import { Helmet } from 'react-helmet'
import Link from 'gatsby-link'
import OgImage from "img/ogimg-planetid.jpg"
import get from 'lodash/get'
import { graphql } from 'gatsby'

class RootIndex extends React.Component {
  render() {
    const langPrefix = this.props.location.pathname.match(/^\/en/) ? '/en' : '';
    
    const siteTitle = get(this, 'props.data.site.siteMetadata.en.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.en.description')
    const siteKeywords = get(this, 'props.data.site.siteMetadata.en.keywords')

    return (
      <div>
        <Helmet
        	title={siteTitle}
        	meta={[
        		{ name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: 'https://planetway.com' + OgImage },
        		{ property: 'og:description', content: siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />
        <main>

          <section className="pt-5">
            <div className="container">
              <div className="row">
                <div className="col"/>
                <div className="col-sm-10">
                  <h4 className="underline">PlanetID News</h4>

                  <div className="pid-news-item mb-5">
                    <span class="news-date margin-bottom-sm">March 19, 2020</span>
                    <h5 className="mb-3">Planetway Releases Universal ID Platform “PlanetID” to Realize Personal Data Sovereignty</h5>
                    <p>Planetway Corporation (Headquarters: San Jose, California, USA; CEO & Founder: Noriaki Hirao; Planetway) will commence commercialization of PlanetID, a universal ID platform, in early April.</p>
                    <p><Link to={langPrefix + "/planetid/news/planetid-start"} className="btn internal-link">Read More</Link></p>
                  </div>

                </div>
                <div className="col"/>
              </div>
            </div>
          </section>

        </main>

      </div>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query PlanetidNewsQueryEn {
    site {
      siteMetadata {
        en {
          title
          description
          keywords
        }
      }
    }
  }
`
