import React from 'react'
import { Helmet } from 'react-helmet'
import OgImage from "img/ogimg-planetid.jpg"
import get from 'lodash/get'
import { graphql } from 'gatsby'

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.description')
    const siteKeywords = get(this, 'props.data.site.siteMetadata.keywords')

    return (
      <div>
        <Helmet
        	title={siteTitle}
        	meta={[
        		{ name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: 'https://planetway.com' + OgImage },
        		{ property: 'og:description', content: siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />
        <main>

          <section className="pt-5">
            <div className="container">
              <div className="row">
                <div className="col"></div>
                <div className="col-sm-10">
                  <h4 className="underline">PlanetID FAQ</h4>

                  <div className="pid-faq-item mb-5">
                    <h5 className="mb-3">1. PlanetID <strong>とは何ですか？</strong></h5>
                    <p>PlanetID は、個人を識別する新しい便利なソリューションです。シンプルで使いやすく、様々な会社が提供するスマートフォンやウェブサイトにおける新しい電子サービスを便利にセキュアに利用することができます。</p>
                    <p><strong className="medium">PlanetID </strong><strong>を利用すると、具体的には下記のような新しいサービスの体験ができます。</strong></p>
                    <ul>
                      <li>PlanetID を使用した電子サービスは、PIN コードを使用することにより利用することができます。このため、各社のサービスごとに ID とパスワードを覚える必要は無くなります。</li>
                      <li>本人確認を行っていただくと、PlanetID を使用している電子サービスにおいて、個人情報の登録をボタン一つで行うことができます。名前、住所、電話番号といった個人情報を一つ一つ書いたり入力したりする必要は無くなります。</li>
                      <li>PlanetID の電子署名により、契約をオンラインで行うことができるようになります。自宅からでも契約に対する署名を電子署名で行うことができるようになります。契約書に対する印鑑が不要になります。</li>
                      <li>サービスごとに個人データの提供に対して、承認・否認の意思を表明することができます。個人データの提供に承認すると、企業から様々な便益を得ることができます。</li>
                    </ul>
                  </div>

                  <div className="pid-faq-item mb-5">
                    <h5 className="mb-3">2. <strong>なぜ、２個の </strong>PIN<strong>コード（認証コード、署名コード）が必要ですか？</strong></h5>
                    <p>PlanetID は２個の PIN コードを使用します。</p>
                    <p><strong className="medium">認証コード</strong>は、スマートフォンアプリやウェブサイトにログインしてアカウントにアクセスするために使用します。</p>
                    <p><strong className="medium">署名コード</strong>は、契約書に対するサイン等の電子署名に使用します。また、署名コードは、個人データの提供に対して許諾を行うときにも使用します。</p>
                  </div>

                  <div className="pid-faq-item mb-5">
                    <h5 className="mb-3">3. PlanetID <strong>の利用にはお金がかかりますか？</strong></h5>
                    <p>PlanetID は無料でダウンロードして使用することができます。PlanetID のトランザクションは、認証（ログイン）や電子署名等のスマートフォンアプリやウェブサイトにおける電子サービスの提供会社により支払われます。</p>
                  </div>

                  <div className="pid-faq-item mb-5">
                    <h5 className="mb-3">4. <strong>本人確認とは何ですか？（なぜ本人確認を行う必要がありますか？）</strong></h5>
                    <p>本人確認を実施すると、個人情報が信頼される第３社機関によって本人確認された PlanetID と結びつきます。 本人確認を実施していないと、PlanetID は名前、住所、電話番号などといった個人情報と結びついてはいません。</p>
                    <p>本人確認を行うと、より便利な電子サービスを利用することができるようになります。一度、本人確認を実施すると、様々な企業が提供する電子サービスにおいて、本人確認を再度実施する必要がなくなります。PlanetID を利用することにより、個人情報を何度も入力する必要は無くなります。<strong>本人確認は、近日サービス利用可能となる予定です。</strong></p>
                  </div>

                  <div className="pid-faq-item mb-5">
                    <h5 className="mb-3">5. <strong>本人確認はどのように行いますか？</strong></h5>
                    <p>本人確認機関にて、容易に行うことができます。本人確認は、犯罪収益移転防止法に準じた基準で行われます。身分証明書の提示が必要になります。 <strong className="medium">本人確認は、近日サービス利用可能となる予定です。</strong></p>
                  </div>

                  <div className="pid-faq-item mb-5">
                    <h5 className="mb-3">6. <strong>１台のスマートフォンで複数の</strong> PlanetID<strong>（ナンバー）を発行することはできますか？</strong></h5>
                    <p>１台のスマートフォンに対して、PlanetID（ナンバー）は１個発行されます。そのため、複数の PlanetID を発行することはできません。</p>
                  </div>

                  <div className="pid-faq-item mb-5">
                    <h5 className="mb-3">7. <strong>複数のスマートフォンで１個の </strong>PlanetID<strong>（ナンバー）を使用することはできますか？</strong></h5>
                    <p>現在は、複数のスマートフォンで１個の PlanetID（ナンバー）を使用することはできません。</p>
                  </div>

                  <div className="pid-faq-item mb-5">
                    <h5 className="mb-3">8. <strong>複数のスマートフォンで異なる</strong> PlanetID<strong>（ナンバー）を使用することはできますか？</strong></h5>
                    <p>本人確認を実施していない場合、複数のスマートフォンで異なる PlanetID を使用することはできます。本人確認は、個人情報を一つの PlanetID に結びつけることになります。そのため、本人確認を実施する時、一つの PlanetID を選択することが必要です。その際、他方の PlanetID を今後使用しないか、または、本人確認を実施しないままにするという選択が必要です。</p>
                  </div>

                  <div className="pid-faq-item mb-5">
                    <h5 className="mb-3">9. PlanetID <strong>を新しいスマートフォンに移行するにはどのようにすれば良いですか？</strong></h5>
                    <p>新しいスマートフォンと古いスマートフォンを用意してください。古いスマートフォンの「メニュー」から「IDを新しいスマホへ移行」を選択してください。新しいスマートフォンに PlanetID アプリをインストールしてください。インストール後の画面では「PlanetIDを発行する」ボタンではなく「既存PlanetIDからの移行」ボタンを選択し、その後ガイダンスに従ってください。</p>
                  </div>

                  <div className="pid-faq-item mb-5">
                    <h5 className="mb-3">10. <strong>スマートフォンを無くした時は、どうしたら良いですか？</strong></h5>
                    <p>本人確認が未実施の場合には、本人確認機関により PlanetID をロックすることができません。安全のために、リンク済みのサービスサイトにログインして、PlanetID とリンク済みのサービスをアンリンクしてください。</p>
                  </div>

                  <div className="pid-faq-item mb-5">
                    <h5 className="mb-3">11. PIN <strong>コードとは何ですか？</strong></h5>
                    <p>認証コード（４桁）と署名コード（６桁）のコードになります。</p>
                    <p><strong>認証コード</strong>は、スマートフォンアプリやウェブサイトにログインしてアカウントにアクセスするために使用します。</p>
                    <p><strong>署名コード</strong>は、契約書に対するサイン等の電子署名に使用します。また、署名コードは、個人データの提供に対して許諾を行うときにも使用します。</p>
                  </div>

                  <div className="pid-faq-item mb-5">
                    <h5 className="mb-3">12. PIN <strong>コードのロック・アンロック</strong></h5>
                    <p>PIN コードの入力を複数回間違えると、PlanetID がロックされ、一定期間 PlanetID アプリを使用できない状態となります。一定期間が経過すると、PlanetID がアンロックされ、PlanetID アプリを使用できるようになります。</p>
                  </div>

                  <div className="pid-faq-item mb-5">
                    <h5 className="mb-3">13. PIN <strong>コードを変更するには、どのようにすれば良いですか？</strong></h5>
                    <p>PlanetID アプリのメニューから PIN コードを変更することができます。PIN コードの変更には、古い PIN コードと新しい PIN コードの入力が必要になります。</p>
                  </div>

                  <div className="pid-faq-item mb-5">
                    <h5 className="mb-3">14. PIN <strong>コードを忘れてしまった時は、どのようにすれば良いですか？</strong></h5>
                    <p>本人確認が未実施の場合には、本人確認機関により PIN コードの再設定を行うことができません。PINコードを思い出せない場合、「メニュ」の「PlanetIDを削除」を選択し PlanetID を削除してください。そして、再度新しい PlanetID をインストールしてください。PlanetID アプリにリンク済みのサービスがある場合には、一度、アンリンクしていただき、新しい PlanetID で再度リンクを実施してください。</p>
                  </div>
                </div>
                <div className="col"></div>
              </div>
            </div>
          </section>

        </main>

      </div>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query PlanetidFaqQuery {
    site {
      siteMetadata {
        title
        description
        keywords
      }
    }
  }
`
