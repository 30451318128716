import React from 'react'
import styles from './article-preview.module.css'
import Link from 'gatsby-link'
import AmericaFlag from "../img/icon-flag-usa.svg"

export default ({ member }) => {

  const to = (member.node_locale === 'en' ? '/en' : '') + `/teammembers/${member.slug}`;

  let america;
  if (member.america) {
    america = <span className="region-icon us"></span>
  }

  let japan;
  if (member.japan) {
    japan = <span className="region-icon jp"></span>
  }

  let estonia;
  if (member.estonia) {
    estonia = <span className="region-icon es"></span>
  }

  return (
    <div className="member">
      <div className="d-sm-flex">
        <div className="profile-pic mb-3" style={{backgroundImage: "url(" + member.profilePicture.file.url + ")", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "102% auto"}}><span></span></div>
        <div className="profile-para">
          <h4>{member.name}</h4>
          <h5>{member.position}</h5>
          {america} {japan} {estonia}
          <div>
            <span
              dangerouslySetInnerHTML={{
                __html: member.excerpt.childMarkdownRemark.html,
              }}
            />
          </div>
          <Link to={to} className="btn internal-link">Read More</Link>
        </div>
      </div>
    </div>
  );
};
