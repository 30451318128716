import React from 'react'
import { Helmet } from 'react-helmet'
import Container from './container'
import Navigation from './navigation'
import EnglishNavigation from './navigation-en'
import Footer from './footer'
import EnglishFooter from './footer-en'
import base from './base.css'

class Template extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    const { location, children } = this.props

    let rootPath = `/`
    if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
      rootPath = __PATH_PREFIX__ + `/`
    }

    // Black text = light background is the default.
    // If path matches these, we use white text, dark background
    // This looks ridiculous, but if the Navigation components are not included in the pages,
    // you cannot access Navigation components from the page components.
    const isWhiteText = this.props.location.pathname.match("(^/$|^/en/?$|/planetid/?$|/planetcross/?$|/planetcross-free-trial/?$|/planetway-data-sovereignty-alliance/?$|/media/?$|/pressreleases/|/recruit/?$|/corporate-profile/?$|/security-service-for-gdpr)");

    let { nav, footer } = function (pathname) {
      let nav,footer;

      // Special navigation and footer for PlanetID pages that are shown in apps.
      // /planetid or /planetid/ should have the header, but
      // /planetid/planetid-news or /planetid/privacy-notice should NOT.
      if (pathname.match(/^(\/en)?\/planetid\/./)) {
        nav = null;
        footer = null;
        return { nav, footer };
      }

      if (pathname.match(/^\/en\//)) {
        nav    = (<React.Fragment> <EnglishNavigation location={location} isWhiteText={isWhiteText} /> <Helmet bodyAttributes={{ class: 'en' }} /> </React.Fragment>);
        footer = (<EnglishFooter location={location} />);
      }
      else {
        nav    = (<React.Fragment> <Navigation location={location} isWhiteText={isWhiteText} /> <Helmet bodyAttributes={{ class: 'ja' }} /> </React.Fragment>);
        footer = (<Footer location={location} />);
      }
      return { nav, footer };
    }(this.props.location.pathname);

    return (
      <Container>
        { nav }
        { children }
        { footer }
      </Container>
    )
  }
}

export default Template
