import React from 'react'
import { Helmet } from 'react-helmet'
import Link from 'gatsby-link'
import OgImage from "img/ogimg-recruit.jpg"
import get from 'lodash/get'
import { graphql } from 'gatsby'

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.description')
    const siteKeywords = get(this, 'props.data.site.siteMetadata.keywords')

    return (
      <div>
        <Helmet
        	title={siteTitle}
        	meta={[
        		{ name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: 'https://planetway.com' + OgImage },
        		{ property: 'og:description', content: siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />

        <div className="page-header recruit-header dia-bottom">
          <div className="container text-on-dark text-center">
            <div className="row">
              <div className="col-md-12">
                <strong className="subtitle-small">Join the Planetway team</strong>
                <h2>Change the World for the Better</h2>
              </div>
            </div>
          </div>
        </div>

        <main>

          <div className="clear"></div>

          <section className="padding-bottom-none">
            <div className="container">
              <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-lg-8 text-center">
                  <h4 className="underline text-bold">データ個人主権の回帰と人中心の<br className="full-width-only" />安心・安全な世界の実現のために</h4>
                  <p>我々は、データは、組織でなく個人に帰属すべきであり、個人が自身の意思でデータを安全かつ自由に公開していける世界の到来として「インディビジュアル・データ ・ドリブン・ソーシャルイノベーション」というビジョン掲げており、個人許諾ベースで、情報を第三者に公開可能とする事で、 自身と社会にとって好ましい形でデータ が利活用される世界（『データ個人主権の新時代』）の創出を目指します。そして近い将来訪れる、資本主義を超えた社会の実現を目指します。</p>
                </div>
                <div className="col-lg-2"></div>
              </div>
            </div>
          </section>

          <section className="padding-bottom-none">
            <div className="container-fluid recruit-montage-photo text-center">
              <div className="row">
                <div className="col"></div>
              </div>
            </div>
          </section>

          <section className="padding-bottom-none">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <h4>Exceptional team members</h4>
                  <p>MySQL開発のコアメンバーであるトーニュ・サミュエルを筆頭に様々な経験を持つメンバー多数！</p>
                </div>
                <div className="col-md-6">
                  <h4>Team diversity</h4>
                  <p>本社はアメリカのサンノゼ、その他日本、エストニアが稼働中。 外国人社員も多く、英語やエストニア語がオフィスで飛び交っています。</p>
                </div>
              </div>
            </div>
          </section>

          {/* <section className="padding-bottom-none">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <h4 className="underline text-bold">バックオフィス</h4>
                  <p className="subtitle-small text-bold">バックオフィス機能は日本のオフィスで、海外とも連携し業務を進めています</p>
                  <p>お客さまの来社対応から、経理処理、営業支援まで幅広く対応しています。それぞれメインの業務を持ちながらも、繁忙期などはメンバー同士で仕事をフォローしあって円滑に業務が進むよう取り組んでいます。</p>
                  <p><strong>日本オフィス</strong><br />セールスや、ビジネスディベロップメント、バックオフィス機能などは現在日本に構えています。日本人はもちろんですが、外国人社員も多く多国籍な環境で全体の事業を支えています。日本語、英語、エストニア語が飛び交う環境です。</p>
                  <p><strong>エストニアオフィス</strong><br />Planetway Europe CTOの大塚をはじめ、エンジニアのほとんどがエストニアに居住しエストニアで働いています。</p>
                  <p><strong>業務拡大、人員増強に伴い様々な業務に対応できるバックオフィスメンバーを大募集</strong><br />「今まで経理しかしたことないけど、総務にもチャレンジしたい」「営業事務だったけどスキルアップのために、他の業務もやってみたい」などなどこれまでの経験を活かしつつ、新たなことにチャレンジすることも可能です！」</p>
                </div>
                <div className="col-lg-4">
                  <div className="recruit-logo-planetway margin-bottom-lg"></div>
                  <p className="subtitle-small text-bold">経理</p>
                  <ul>
                    <li>営業メンバーの経費精算の支援</li>
                    <li>会計ソフトの管理、経費の処理</li>
                  </ul>
                  <p className="subtitle-small text-bold">総務</p>
                  <ul>
                    <li>処理や資料の整理を行なっていただきます</li>
                    <li>備品の発注や郵便など</li>
                  </ul>
                  <Link to="/contact" className="btn internal-link">Apply Now</Link>
                </div>
              </div>
            </div>
          </section>

          <section className="padding-bottom-none">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <h4 className="underline text-bold">セールスエンジニア、サポートエンジニア </h4>
                  <p className="subtitle-small text-bold">仕事内容</p>
                  <p>セールス/サポートエンジニアとして、営業（Businessdevelopment）と共にお客様に提案・導入、および運用の技術面でのサポートを担当していただきます。</p>
                  <p>顧客やパートナー企業の課題ヒアリング、製品デモの実施、仕様の作成、サーバインフラの構築。また、エストニア拠点のエンジニアと連携し、ソフトウェアのセットアップとテスト、トラブルシューティングがメイン業務です。</p>
                  <p><strong>求める経験、スキル</strong><br />セールスや、ビジネスディベロップメント、バックオフィス機能などは現在日本に構えています。日本人はもちろんですが、外国人社員も多く多国籍な環境で全体の事業を支えています。日本語、英語、エストニアが飛び交う環境です。</p>
                  <p><strong>業務拡大、人員増強に伴い様々な業務に対応できるエンジニアメンバーを大募集</strong><br />以下のいずれかまたは複数の経験を持つ人を求めています。</p>
                  <ul>
                    <li>日本の大企業に技術製品の提案、導入を進めたことがある</li>
                    <li>英語でエンジニアとコミュニケーションできる</li>
                    <li>AWS、Azure等クラウドを使ったサーバインフラの構築経験がある</li>
                    <li>公開鍵暗号などセキュリティについての知識がある</li>
                    <li>MySQL、PostgreSQL、OracleなどのRDBの運用経験がある</li>
                    <li>tcpdump、WireSharkを使用できる</li>
                    <li>RFCを読める</li>
                    <li>認証と認可の違いについて理解</li>
                  </ul>
                </div>
                <div className="col-lg-4">
                  <div className="recruit-logo-planetway margin-bottom-lg"></div>
                  <p className="subtitle-small text-bold">求める人物像 </p>
                  <p>Planetwayはまだ小さなスタートアップです。お客様とエストニアのエンジニアチームの間で、必要に応じて主体的に幅広い範囲で動ける人を求めています。</p>
                  <Link to="/contact" className="btn internal-link">Apply Now</Link>
                </div>
              </div>
            </div>
          </section>

          <section className="padding-bottom-none">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <h4 className="underline text-bold">PlanetGuardians</h4>
                  <p className="subtitle-small text-bold">(ホワイトハッカー/サイバーセキュリティ人材育成事業)</p>
                  <p><strong>担当事業</strong><br />PlanetGuardians (ホワイトハッカー/サイバーセキュリティ人材育成事業)</p>
                  <p><strong>担当ミッション</strong></p>
                  <ul>
                    <li>PlanetGuardiansの育成およびコミュニティ形成</li>
                    <li>売上拡大</li>
                  </ul>
                  <p><strong>期待するや職務・役割</strong><br />PlanetGuardians (ホワイトハッカー/サイバーセキュリティ人材育成事業)</p>
                  <p><strong>担当ミッション</strong></p>
                  <ul>
                    <li>Guardians育成対象となるパートナー企業の新規開拓およびリレーション構築(コンサルティング会社、SIer、Cloud Vendor, セキュリティソリューションプロバイダー等)</li>
                    <li>Guardians育成のためのプログラムの企画/開発教育コンテンツ（e-Learning based）およびサイバー演習空間</li>
                    <li>Guardians認定スキームの企画/推進</li>
                    <li>Guardians Communityの企画/運営</li>
                    <li>年度予算(売上計画)の策定と実績管理</li>
                  </ul>
                  <p><strong>求める経験・スキル</strong><br />マネジメントクラス。これまでの経験を活かして頂ける方を募集しております。英語・エストニア語が話せる方大歓迎です。</p>
                </div>
                <div className="col-lg-4">
                  <div className="recruit-logo-planetguardians margin-bottom-lg"></div>
                  <p className="subtitle-small text-bold">求める人物像</p>
                  <ul>
                    <li>Planetwayが持つビジョンに共感し、顧客やパートナーと共に成長していく意志を持っている</li>
                    <li>日本の国家的課題であるサイバーセキュリティ人材不足に対し、現状の問題点を理解し、変革しようとする強いマインドを持っている</li>
                    <li>Guardians(個人主権のデータ利活用社会を護衛する正義のハッカー)に必要な人材像を理解し、Planetwayが提供すべき教育プログラムの制作/展開を主体的に推進できる</li>
                    <li>知識より柔軟性、経験より新規性を重んじ、何事も吸収する意志とチャレンジ精神を有している</li>
                  </ul>
                  <Link to="/contact" className="btn internal-link">Apply Now</Link>
                </div>
              </div>
            </div>
          </section>

          <section className="padding-bottom-none">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <h4 className="underline text-bold">PlanetEco事業企画</h4>
                  <p className="subtitle-small text-bold">PlanetEcoの事業企画及びセールス</p>
                  <p><strong>担当事業</strong><br />PlanetEco (Open Innovation Platform 事業) の事業企画及びセールス</p>
                  <p><strong>担当ミッション</strong></p>
                  <ul>
                    <li>Planetwayのコア製品 (PlanetCross:企業間の情報連携基盤、PlanetID:個人認証基盤)の普及（国内/海外）</li>
                    <li>Planetway Eco-Partnerネットワークの拡大（ユーザー企業またはパートナー企業）</li>
                    <li>売上拡大</li>
                  </ul>
                  <p><strong>期待するや職務・役割</strong></p>
                  <ul>
                    <li>ユーザー企業またはパートナー企業の新規開拓およびリレーション構築（コンサルティング会社、システム・インテグレーター等）</li>
                    <li>個別企業によるPlanetway製品のPoCの企画立案</li>
                    <li>複数企業による Open Innovation Program の企画立案</li>
                    <li>Project Manager職との連携による上記 Project/Program 推進体制検討 • 年度予算(売上計画)の策定と実績管理</li>
                  </ul>
                  <p><strong>求める経験・スキル</strong><br />メンバーからマネジメントクラスまで、幅広く求めております。これまでの経験を活かして頂ける方を募集しております。英語・エストニア語が話せる方大歓迎です。</p>
                </div>
                <div className="col-lg-4">
                  <div className="recruit-logo-planeteco margin-bottom-lg"></div>
                  <p className="subtitle-small text-bold">求める人物像</p>
                  <ul>
                    <li>今後のデジタルソサエティへの変革を促進するため、高い志を持って能動的に活動できる</li>
                    <li>社会的課題や各業界の課題解決に向けて、弊社プロダクト拡販をベースとした具体的提案や活動に落とし込める</li>
                    <li>弊社プロダクトやサービスに関連する技術の習得はもちろんのこと、さらなる付加価値を提供できるよ うに、絶えず新技術動向や周辺技術にも関心を持ち、実現可能性を踏まえた提案に落とし込める</li>
                    <li>顧客、パートナー企業、弊社サプライヤーおよび社内他部門のメンバの考え方や状況を理解・尊重し、現実的なアクションを取れる</li>
                    <li>自分の担当や領域にこだわらず、必要に応じて他メンバーと協力して業務遂行できる</li>
                  </ul>
                  <Link to="/contact" className="btn internal-link">Apply Now</Link>
                </div>
              </div>
            </div>
          </section>

          <section className="padding-bottom-none">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <h4 className="underline text-bold">PlanetEcoプロジェクトマネージャー</h4>
                  <p className="subtitle-small text-bold">PlanetEcoのプロジェクトマネージャー</p>
                  <p><strong>担当事業</strong><br />PlanetEco (Open Innovation Platform事業)</p>
                  <p><strong>担当ミッション</strong></p>
                  <ul>
                    <li>セールスフェーズ：BD(Business Development)担当と連携の上、適切な提案スキームの作成</li>
                    <li>デリバリーフェーズ：プロジェクトマネージメントおよびプロダクト/サービスデリバリー</li>
                    <li>リテンションフェーズ：BD(Business Development)担当と連携の上、顧客およびパートナー企業とのリレーション強化および拡販促進</li>
                  </ul>
                  <p><strong>期待するや職務・役割</strong><br />提案技術支援、プロジェクト推進および拡販</p>
                  <p><strong>1. セールスフェーズ</strong></p>
                  <ul>
                    <li>Business Development担当およびEngineerと連携し、顧客およびパートナー企業に対して弊社プロダクトおよび　サービスの位置付けを固め、適切な見積り・提案・クロージングを行うこと。 （顧客およびパートナー企業向けデモや技術的質問への対応、提案スキームのリスク回 避等を含む）</li>
                    <li>マーケット状況を鑑み、弊社プロダクトのプライシングモデルの構築および改正に適切なフィードバックを行い、課金機能の開発等さらなる付加価値提供を促進すること</li>
                    <li>より効果的なデモを行うための、デモシナリオの企画やデモシステムの要件定義等さらなる付加価値提供を促進すること、等</li>
                  </ul>
                  <p><strong>2. デリバリーフェーズ</strong></p>
                  <ul>
                    <li>Business Engineering担当と連携し、顧客およびパートナー企業の環境に弊社プロダクトのインストレーション</li>
                    <li>初期セットアップを行い、顧客およびパートナー企業の担当者へ適切なプロダクトレクチャーまたテクニカルサポートを行う</li>
                    <li>Engineerにデリバリーフェーズの機能拡張や性能向上等の要件をフィードバックし、さらなる付加価値提供ができるようにリードする 等</li>
                  </ul>
                  <p><strong>3. リテンションフェーズ</strong></p>
                  <ul>
                    <li>Business Development担当と連携し、顧客およびパートナー企業担当者とのリレーションを強化し、 クロスセル・アップセルの機会を創出する</li>
                    <li>EngineerにRetention Phaseでの機能拡張や性能向上等の要件をフィードバックし、さらなる付加価値提供ができるようにリードする 等</li>
                  </ul>
                  <p><strong>求める経験・スキル</strong><br />今回メンバーから部長クラスまで幅広く求めております。これまでの経験を活かして頂ける方を募集しております。英語・エストニア語が話せる方大歓迎です。</p>
                </div>
                <div className="col-lg-4">
                  <div className="recruit-logo-planeteco margin-bottom-lg"></div>
                  <p className="subtitle-small text-bold">求める人物像</p>
                  <ul>
                    <li>今後のデジタルソサエティへの変革を促進するため、高い志を持って能動的に活動できる</li>
                    <li>社会的課題や各業界の課題解決に向けて、弊社プロダクト拡販をベースとした具体的提案や活動に落とし込める</li>
                    <li>弊社プロダクトやサービスに関連する技術の習得はもちろんのこと、さらなる付加価値を提供できるよ うに、絶えず新技術動向や周辺技術にも関心を持ち、実現可能性を踏まえた提案に落とし込める</li>
                    <li>顧客、パートナー企業、弊社サプライヤーおよび社内他部門のメンバの考え方や状況を理解・尊重し、現実的なアクションを取れる</li>
                    <li>自分の担当や領域にこだわらず、必要に応じて他メンバーと協力して業務遂行できる</li>
                  </ul>
                  <Link to="/contact" className="btn internal-link">Apply Now</Link>
                </div>
              </div>
            </div>
          </section> */}

          <section className="padding-bottom-lg">
            <div className="container">
              <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-lg-8 text-center mobile-text-left">
                  <h3>Questions?</h3>
                  <Link to="/contact" className="btn internal-link">Contact Us</Link>
                </div>
                <div className="col-lg-2"></div>
              </div>
            </div>
          </section>

        </main>

      </div>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query RecruitQuery {
    site {
      siteMetadata {
        title
        description
        keywords
      }
    }
  }
`
