import React from 'react'
import { Helmet } from 'react-helmet'
import Link from 'gatsby-link'
import OgImage from "img/ogimg-planetcross.jpg"
import PartnerLogoAktors from "img/partner-logo-aktors.png"
import get from 'lodash/get'
import { graphql } from 'gatsby'
import Layout from "../../components/layout"

class RootIndex extends React.Component {
  render() {
    const langPrefix = this.props.location.pathname.match(/^\/en/) ? '/en' : '';

    const siteTitle = get(this, 'props.data.site.siteMetadata.en.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.en.description')
    const siteKeywords = get(this, 'props.data.site.siteMetadata.en.keywords')

    return (
      <Layout location={this.props.location}>
        <Helmet
        	title={siteTitle}
        	meta={[
        		{ name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: 'https://planetway.com' + OgImage },
        		{ property: 'og:description', content: siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />
        <main className="no-header">

          <div className="clear" />

          <section className="padding-top-lg">
            <div className="container">
              <div className="row">
                <div className="col" />
                <div className="col-sm-10"><div className="partner-logo"><img src={PartnerLogoAktors} /></div>
                  <h4 className="underline">Aktors OÜ</h4>
                  <h5 className="margin-bottom-sm">Pioneer in X-road technology</h5>
                  <p>Aktors concentrates on software development, starting from feasibility and requirements analysis up to the implementation and end-user training. Aktors operates mainly in three sectors and its main customers are the government, financial industry, military and homeland security organizations:</p>
                  <ul>
                    <li>E-government solutions – mainly X-Road information exchange layer-based solutions for different countries (for instance, Estonia, Finland, Palestine and Azerbaijan). Development of Court Information System in Sulaymaniyah region in Kurdistan.</li>
                    <li>Financial and banking industry</li>
                    <li>Defence sector – Aktors is an industrial partner in NATO Alliance Ground Surveillance (integrated C4ISR system). Our main customers are both in private and public sector, from government agencies to commercial banks and system integrators.</li>
                  </ul>
                  <p>Aktor’s services include software development and consulting, project management (prime contractor) and training.</p>
                  <h5 className="margin-top-md margin-bottom-sm">Key Competence Areas</h5>
                  <p>In e-governance: public sector budget management, enterprise data collection and aggregation, civil and criminal court administration, digital data exchange platform X-Road</p>
                  <p>In finance: software for clearing houses (clearing and settlement), payments (SEPA, SWIFT, domestic), deposits, loans, leasing, microfinancing, direct debiting, securities and portfolio management, risk management, internet and mobile banking, credit card payments data exchange and processing, financial accounting, electronic invoices</p>
                  <h5 className="margin-top-md margin-bottom-sm">Case Study</h5>
                  <p><strong>Estonian X-Road data exchange layer</strong></p>
                  <p>X-Road is the information exchange layer comprising the main databases of the Republic of Estonia. The CEO of Aktors, Aleksander Reitsakas was the first project manager for X-road in 2001 and Aktors has dealt with the development of several X-Road interface databases also later on various platforms. In addition, Aktors handles the maintenance and development of the X-Road portal MISP. Their major X-Road client is Estonian Information System Authority.</p>
                  <p><strong>State Treasury Payments System for Ministry of Finance Estonia</strong></p>
                  <p>The system’s goal is to manage all payments by all government institutions, credit and debit cards, loans and financing, monitor budget compliance and provide necessary reporting.</p>
                  <p>For more information: <a href="http://www.aktors.ee" target="_blank">http://www.aktors.ee</a></p>
                  <p className="text-center mt-5"><Link to={langPrefix + "/pdsa-partners"} className="btn internal-link">Back</Link></p>
                </div>
                <div className="col" />
              </div>
            </div>
          </section>

        </main>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query PdsaPartnersAktorsQueryEn {
    site {
      siteMetadata {
        en {
          title
          description
          keywords
        }
      }
    }
  }
`
