import React from 'react'
import { Helmet } from 'react-helmet'
import Link from 'gatsby-link'
import OgImage from "img/ogimg-planetcross.jpg"
import PartnerLogoAktors from "img/partner-logo-aktors.png"
import get from 'lodash/get'
import { graphql } from 'gatsby'
import Layout from "../components/layout"

class RootIndex extends React.Component {
  render() {
    const langPrefix = this.props.location.pathname.match(/^\/en/) ? '/en' : '';

    const siteTitle = get(this, 'props.data.site.siteMetadata.en.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.en.description')
    const siteKeywords = get(this, 'props.data.site.siteMetadata.en.keywords')

    return (
      <Layout location={this.props.location}>
        <Helmet
        	title={siteTitle}
        	meta={[
        		{ name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: 'https://planetway.com' + OgImage },
        		{ property: 'og:description', content: siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />
        <main className="no-header">

          <div className="clear" />

          <section className="padding-top-lg">
            <div className="container">
              <div className="row">
                <div className="col" />
                <div className="col-sm-10"><div className="partner-logo"><img src={PartnerLogoAktors} /></div>
                  <h4 className="underline">Aktors OÜ</h4>
                  <h5 className="margin-bottom-sm">X-Road<strong>テクノロジーの先駆者</strong></h5>
                  <p>Aktorsは、実現可能性検討や要件分析から実装とエンドユーザートレーニングまで、一貫してソフトウェア開発に専念しています。 Aktorsは主に3つのセクターで事業を展開しており、その主な顧客は政府、金融業界、軍事、国土安全保障機関です。</p>
                  <ul>
                    <li>電子政府ソリューション–主にさまざまな国（エストニア、フィンランド、パレスチナ、アゼルバイジャンなど）向けのX-Road情報交換レイヤーベースのソリューション。 クルディスタンのスレイマニヤ地域における裁判所情報システムの開発。</li>
                    <li>金融および銀行業界</li>
                    <li>防衛部門– Aktorsは、NATO同盟地上偵察機（統合C4ISRシステム）の産業パートナーです。 主な顧客は、政府機関から商業銀行、システムインテグレーターまで、民間部門と公共部門の両方です。</li>
                  </ul>
                  <p>Aktorsのサービスはソフトウェア開発にとどまらず、コンサルティング、プロジェクト管理、およびトレーニングにも及びます。</p>
                  <h5 className="margin-top-md margin-bottom-sm"><strong>主な得意分野</strong></h5>
                  <p>電子政府：公共部門の予算管理、企業データの収集と集約、民事および刑事裁判所の管理、デジタルデータ交換プラットフォームX-Road</p>
                  <p>金融：クリアリングハウス向けのソフトウェア（決済）、支払い（SEPA、SWIFT、国内）、預金、ローン、リース、マイクロファイナンス、口座引き落とし、証券およびポートフォリオ管理、リスク管理、インターネットおよびモバイルバンキング、クレジットカード支払いデータ交換および処理、財務会計、電子請求書</p>
                  <h5 className="margin-top-md margin-bottom-sm"><strong>ケーススタディ</strong></h5>
                  <p><strong>エストニアのX-Roadデータ交換レイヤー</strong></p>
                  <p>X-Roadは、エストニア共和国の主要なデータベースで構成される情報交換レイヤーです。 AktorsのCEOであるAleksander Reitsakasは2001年にX-Roadの最初のプロジェクトマネージャーであり、Aktorsは後でさまざまなプラットフォームでいくつかのX-Roadインターフェイスデータベースの開発に取り組んできました。 さらに、AktorsはX-RoadポータルMISPのメンテナンスと開発を担当しています。 X-Roadを利用する主要なお客様はエストニア情報システム局です。</p>
                  <p><strong>エストニア財務省のための公庫支払いシステム</strong></p>
                  <p>システムの目標は、すべての政府機関によるすべての支払い、クレジットカードとデビットカード、ローンと資金調達を管理し、予算の順守を監視し、必要なレポートを提供することです。</p>
                  <p>詳細はこちら: <a href="http://www.aktors.ee" target="_blank">http://www.aktors.ee</a></p>
                  <p className="text-center mt-5"><Link to={langPrefix + "/pdsa-partners"} className="btn internal-link">一覧に戻る</Link></p>
                </div>
                <div className="col" />
              </div>
            </div>
          </section>

        </main>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query PdsaPartnersAktorsQuery {
    site {
      siteMetadata {
        en {
          title
          description
          keywords
        }
      }
    }
  }
`
