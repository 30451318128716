import React from 'react'
import { Helmet } from 'react-helmet'
import Link from 'gatsby-link'
import OgImage from "img/ogimg-planetcross.jpg"
import get from 'lodash/get'
import { graphql } from 'gatsby'
import Layout from "../components/layout"

class RootIndex extends React.Component {
  render() {
    const langPrefix = this.props.location.pathname.match(/^\/en/) ? '/en' : '';

    const siteTitle = get(this, 'props.data.site.siteMetadata.en.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.en.description')
    const siteKeywords = get(this, 'props.data.site.siteMetadata.en.keywords')

    return (
      <Layout location={this.props.location}>
        <Helmet
        	title={siteTitle}
        	meta={[
        		{ name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: 'https://planetway.com' + OgImage },
        		{ property: 'og:description', content: siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />

        <div className="page-header pwdsa-header">
          <div className="container text-on-dark">
            <div className="row">
              <div className="col-sm-6">
                <div className="pwdsa-logo margin-bottom-lg" />
                <h3><strong>個人のデータを個人が<br />コントロールする世界</strong></h3>
                <p>個人データ主権の実現に向けたビジネスアライアンス</p>
                {/* <Link to={langPrefix + "/contact"} className="btn internal-link">事前申し込み</Link> */}
              </div>
              <div className="col-sm-6"><div className="pwdsa-pid-px-artwork" /></div>
            </div>
          </div>
        </div>

        <main>

          <div className="clear"/>

          <section>
            <div className="container">
              <div className="row">
                <div className="col"/>
                <div className="col-lg-10 text-center mobile-text-left">
                  <h4 className="underline"><strong>Planetway&reg; Data Sovereignty Alliance（PDSA）とは</strong></h4>
                  <h5 className="padding-bottom-sm"><strong>弊社がビジョンに掲げる「個人データ主権の実現」を推進するための企業向けグローバルビジネスアライアンスです。</strong></h5>
                  <p>PDSAはDX、GDPR、情報セキュリティなどの企業課題を対象としたソリューションやユースケースのコンテンツを提供することで、企業の課題解決を支援します。
                  また、ビジネス・技術をサポートするコンテンツを提供することで、パートーナー企業が弊社提供製品およびサービスを構築、マーケティング、販売することを支援します。</p>
                  {/* <Link to={langPrefix + "/contact"} className="btn internal-link">事前申し込み</Link> */}
                </div>
                <div className="col"/>
              </div>
            </div>
          </section>

          <section className="pwdsa-infini-blur-bg text-on-dark">
            <div className="container">
              <div className="row padding-top-md">
                <div className="col-sm-4 padding-bottom-md">
                  <h4 className="icon-title icon-shield-check">Empower</h4>
                  <p>データの主権を個人に取り戻す。</p>
                </div>
                <div className="col-sm-4 padding-bottom-md">
                  <h4 className="icon-title icon-arrows-out">Ethics</h4>
                  <p>インターネットを本来の形に取り戻す。</p>
                </div>
                <div className="col-sm-4 padding-bottom-md">
                  <h4 className="icon-title icon-scan-one">Unify</h4>
                  <p>全人類・全モノへの統合ID化。</p>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4 padding-bottom-md">
                  <h4 className="icon-title icon-connect">Evolve</h4>
                  <p>資本主義の先をアップデートする。</p>
                </div>
                <div className="col-sm-4 padding-bottom-md">
                  <h4 className="icon-title icon-arrows-out">Protect</h4>
                  <p>AIなどに制御される未来を作らない。</p>
                </div>
                <div className="col-sm-4 padding-bottom-md">
                  <h4 className="icon-title icon-scan-one">Optimize</h4>
                  <p>人間の可能性を拡張していく。</p>
                </div>
              </div>
            </div>
          </section>

          {/*<section>
            <div className="container">
              <div className="row">
                <div className="col"></div>
                <div className="col-lg-10 text-center mobile-text-left">
                  <h4 className="underline">Become a Member</h4>
                  <p>Contact us today and we will assess your eligibility for membership.</p>
                  <Link to={langPrefix + "/contact"} className="btn internal-link">Apply for membership</Link>
                </div>
                <div className="col"></div>
              </div>
            </div>
          </section>*/}

        </main>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query PwdsaQuery {
    site {
      siteMetadata {
        en {
          title
          description
          keywords
        }
      }
    }
  }
`
