import React from 'react'
import { Helmet } from 'react-helmet'
import BodyClassName from 'react-body-classname'
import OgImage from "img/ogimg-corporate-leadership.jpg"
import get from 'lodash/get'
import TeamMemberMedium from 'components/teammember-medium'
import { graphql } from 'gatsby'
import Layout from "../components/layout"

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.description')
    const siteKeywords = get(this, 'props.data.site.siteMetadata.keywords')
    const members = get(this, 'props.data.allContentfulTeamMember.edges')

    return (
      <Layout location={this.props.location}>
        <Helmet
        	title={siteTitle}
        	meta={[
        		{ name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: 'https://planetway.com' + OgImage },
        		{ property: 'og:description', content: siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />
        <BodyClassName className='corporate-leadership' />

        <main className="no-header">

          <div className="clear" />

          <section>
            <div className="container">
              <div className="row">
                <div className="col" />
                <div className="col-sm-10 text-center">
                  <h3 className="underline">Corporate Leadership</h3>
                  <h4 className="margin-top-lg margin-bottom-md text-bold text-left">役員</h4>

                  {members.map(({ node }) => {
                    if (node.type === "Board") {
                      return (
                        <TeamMemberMedium key={node.slug} member={node} />
                      )
                    }
                  })}

                  <h4 className="margin-top-lg margin-bottom-md text-bold text-left">コアメンバー</h4>

                  {members.map(({ node }) => {
                    if (node.type === "Executive") {
                      return (
                        <TeamMemberMedium key={node.slug} member={node} />
                      )
                    }
                  })}

                  <h4 className="margin-top-lg margin-bottom-md text-bold text-left">アドバイザリーボード</h4>

                  {members.map(({ node }) => {
                    if (node.type === "Advisory Board") {
                      return (
                        <TeamMemberMedium key={node.slug} member={node} />
                      )
                    }
                  })}

                  <h4 className="margin-top-lg margin-bottom-md text-bold text-left">パートナー</h4>

                  {members.map(({ node }) => {
                    if (node.type === "Partner") {
                      return (
                        <TeamMemberMedium key={node.slug} member={node} />
                      )
                    }
                  })}

                </div>
                <div className="col" />
              </div>
            </div>
          </section>
        </main>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query TeamQuery {
    site {
      siteMetadata {
        title
        description
        keywords
      }
    }
    allContentfulTeamMember(
      filter: {node_locale: {eq: "ja"}},
      sort: { fields: [publishDate], order: ASC },
      limit: 400
    ) {
      edges {
        node {
          name
          slug
          position
          profilePicture {
            file {
              url
            }
          }
          excerpt {
            childMarkdownRemark {
              html
            }
          }
          profile {
            childMarkdownRemark {
              html
            }
          }
          america
          japan
          estonia
          type
          publishDate(formatString: "LL", locale: "ja")
        }
      }
    }
  }
`
