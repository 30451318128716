import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'

import PidUg1EnHome from "img/pid-ug-1-en-home.png"
import PidUg2EnCreateAuthCode from "img/pid-ug-2-en-createauthcode.png"
import PidUg3EnCreateAuthCode from "img/pid-ug-3-en-confirmauthcode.png"
import PidUg4EnCreateAuthCode from "img/pid-ug-4-en-createsigncode.png"
import PidUg5EnCreateAuthCode from "img/pid-ug-5-en-confirmsigncode.png"
import PidUg7EnCreateAuthCode from "img/pid-ug-7-en-successfullyactivated.png"
import PidUg8EnCreateAuthCode from "img/pid-ug-8-en-howtouseauthcode.png"
import PidUg9EnCreateAuthCode from "img/pid-ug-9-en-howtousesigncode.png"
import PidUg10EnCreateAuthCode from "img/pid-ug-10-en-homescreen.png"

import PidUg11EnLinkButton from "img/pid-ug-11-en-linkbutton.png"
import PidUg12EnHome from "img/pid-ug-12-en-home.png"
import PidUg13EnAuthRequest from "img/pid-ug-13-en-authrequest.png"
import PidUg14EnAuthCode from "img/pid-ug-14-en-authcode.png"
import PidUg15EnAuthenticated from "img/pid-ug-15-en-authenticated.png"

import PidUg16EnSigninButton from "img/pid-ug-16-en-signinbutton.png"
import PidUg17EnHome from "img/pid-ug-17-en-home.png"
import PidUg18EnAuthRequest from "img/pid-ug-18-en-authrequest.png"
import PidUg19EnAuthCode from "img/pid-ug-19-en-authcode.png"
import PidUg20EnAuthenticated from "img/pid-ug-20-en-authenticated.png"

import PidUg21EnSignatureButton from "img/pid-ug-21-en-signaturebutton.png"
import PidUg22EnSignRequest from "img/pid-ug-22-en-signrequest.png"
import PidUg23EnSignCode from "img/pid-ug-23-en-signcode.png"
import PidUg24EnSigned from "img/pid-ug-24-en-signed.png"

import PidUg25EnConsentButton from "img/pid-ug-25-en-consentbutton.png"
import PidUg26EnConsentRequest from "img/pid-ug-26-en-consentrequest.png"
import PidUg27EnSignCode from "img/pid-ug-27-en-signcode.png"
import PidUg28EnSigned from "img/pid-ug-28-en-signed.png"

import OgImage from "img/ogimg-planetid.jpg"
import get from 'lodash/get'
import LanguageLink from 'components/language-link'

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.en.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.en.description')
    const siteKeywords = get(this, 'props.data.site.siteMetadata.en.keywords')

    return (
      <div>
        <Helmet
        	title={siteTitle}
        	meta={[
        		{ name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: 'https://planetway.com' + OgImage },
        		{ property: 'og:description', content: siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />
        <main>

          <section className="pt-5">
            <div className="container">
              <div className="row">
                <div className="col-md-12"><h4 className="underline">PlanetID User Guide</h4></div>
              </div>
              <div className="row">
                <div className="col-md-12"><h5 className="mb-3">PlanetID registration</h5></div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-center"><img src={PidUg1EnHome} className="threequarter-width-image mb-3" /></div>
                    <p><strong className="medium">1. Begin Activation</strong></p>
                    <p>Get started by downloading the Smart-ID app.</p>
                    <p>Open your app and click "Activation".</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-center"><img src={PidUg2EnCreateAuthCode} className="threequarter-width-image mb-3" /></div>
                    <p><strong className="medium">2. Create your authcode</strong></p>
                    <p>Enter four random digits to create your authcode. Make sure to remember it! You will need your authcode when you log into e-services with PlanetID.</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-center"><img src={PidUg3EnCreateAuthCode} className="threequarter-width-image mb-3" /></div>
                    <p><strong className="medium">3. Confirm your authcode</strong></p>
                    <p>Insert your authcode one more time to confirm it.</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-center"><img src={PidUg4EnCreateAuthCode} className="threequarter-width-image mb-3" /></div>
                    <p><strong className="medium">4. Create your signcode</strong></p>
                    <p>Enter six random digits to create your signcode. Make sure to remember it! You will need your signcode, when you sign a document or give a consent for the e-service company to disclose your personal data.</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-center"><img src={PidUg5EnCreateAuthCode} className="threequarter-width-image mb-3" /></div>
                    <p><strong className="medium">5. Confirm your signcode</strong></p>
                    <p>Insert your authcode one more time to confirm it.</p>
                  </div>
                </div>

                {/*<div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-center"><img src={PidUg6EnCreateAuthCode} className="threequarter-width-image mb-3" /></div>
                    <p><strong className="medium">6. Key generation</strong></p>
                    <p>Wait until your keys are generated and your PlanetID comes active.</p>
                  </div>
                </div>*/}

                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-center"><img src={PidUg7EnCreateAuthCode} className="threequarter-width-image mb-3" /></div>
                    <p><strong className="medium">6. Congratulations! Your PlanetID was successfully activated</strong></p>
                    <p>Click "Next" to read the instructions or click "Skip" if you wish to skip them.</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-center"><img src={PidUg8EnCreateAuthCode} className="threequarter-width-image mb-3" /></div>
                    <p><strong className="medium">7. How to user your authcode</strong></p>
                    <p>You can use your authcode to log into websites and e-services.</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-center"><img src={PidUg9EnCreateAuthCode} className="threequarter-width-image mb-3" /></div>
                    <p><strong className="medium">8.  How to use your signcode</strong></p>
                    <p>You can use your signcode to sign contracts or give a consent to a third party to use your personal data.</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-center"><img src={PidUg10EnCreateAuthCode} className="threequarter-width-image mb-3" /></div>
                    <p><strong className="medium">9. Home screen</strong></p>
                    <p>Your PlanetID is ready to use!</p>
                  </div>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-md-12"><h5 className="mb-5">Linking Your PlanetID</h5></div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-left"><img src={PidUg11EnLinkButton} className="half-width-image mb-3" /></div>
                    <p><strong className="medium">1. Link your PlanetID</strong></p>
                    <p>On your e-service web browser or mobile application, log into your account with your usual username and password and click "Link PlanetID".</p>
                    <p>In case your e-service is on web browser, your website will show you a QR code.</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-center"><img src={PidUg12EnHome} className="threequarter-width-image mb-3" /></div>
                    <p><strong className="medium">2. Tap to scan a QR code</strong></p>
                    <p>Tap to scan a QR code button on your PlanetID app to open the camera view. Read the QR code with your app from the e-service website view.</p>
                    <p>In case your e-service is on smartphone application, this step will be skipped.</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-center"><img src={PidUg13EnAuthRequest} className="threequarter-width-image mb-3" /></div>
                    <p><strong className="medium">3. Confirm security token</strong></p>
                    <p>Your app will show you a security token. Check if you can see the same security token also in your e-service website view. If the tokens match, then you are on a right website and can click accept. If they don't match, click reject.</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-center"><img src={PidUg14EnAuthCode} className="threequarter-width-image mb-3" /></div>
                    <p><strong className="medium">4. Enter your authcode</strong></p>
                    <p>Enter the 4 digit authcode you created during PlanetID activation process.</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-center"><img src={PidUg15EnAuthenticated} className="threequarter-width-image mb-3" /></div>
                    <p><strong className="medium">5. Authenticated</strong></p>
                    <p>Now your PlanetID is successfully linked with your e-service account.</p>
                  </div>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-md-12"><h5 className="mb-5">Sign in with PlanetID</h5></div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-left"><img src={PidUg16EnSigninButton} className="half-width-image mb-3" /></div>
                    <p><strong className="medium">1. Sign in with PlanetID</strong></p>
                    <p>On your e-service web browser or mobile application, click "Sign in with PlanetID".</p>
                    <p>In case your e-service is on web browser , your website will show you a QR code.</p>

                  </div>
                </div>

                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-center"><img src={PidUg17EnHome} className="threequarter-width-image mb-3" /></div>
                    <p><strong className="medium">2. Tap to scan a QR code</strong></p>
                    <p>Tap to scan a QR code button on your PlanetID app to open the camera view. Read the QR code with your app from your e-service website.</p>
                    <p>In case your e-service is on smartphone application, this step will be skipped.</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-center"><img src={PidUg18EnAuthRequest} className="threequarter-width-image mb-3" /></div>
                    <p><strong className="medium">3. Confirm security token</strong></p>
                    <p>Your app will show you a security token. Check if you can see the same security token also in your e-service website view. If the tokens match, click accept. If they don't match, click reject.</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-center"><img src={PidUg19EnAuthCode} className="threequarter-width-image mb-3" /></div>
                    <p><strong className="medium">4. Enter your authcode</strong></p>
                    <p>Enter the 4 digit authcode you created during PlanetID activation process.</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-center"><img src={PidUg20EnAuthenticated} className="threequarter-width-image mb-3" /></div>
                    <p><strong className="medium">5. Authenticated</strong></p>
                    <p>You are successfully signed into your e-service account.</p>
                  </div>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-md-12"><h5 className="mb-5">Sign a document with PlanetID</h5></div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-left"><img src={PidUg21EnSignatureButton} className="half-width-image mb-3" /></div>
                    <p><strong className="medium">1. Provide your signature with PlanetID</strong></p>
                    <p>With PlanetId you can sign documents on your e-service account.</p>
                    <p>To digitally sign with PlanetID, click the "Signature" button on your e-service account.</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-center"><img src={PidUg22EnSignRequest} className="threequarter-width-image mb-3" /></div>
                    <p><strong className="medium">2. Signing Request</strong></p>
                    <p>Your Planet ID will send you a Signing Request. Check the content carefully.</p>
                    <p>Also, make sure that the security token displayed on the e-service account matches the security token displayed on the app. If they match, you are on the right website.</p>
                    <p>After confirming the above, tap the "Approve" button.</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-center"><img src={PidUg23EnSignCode} className="threequarter-width-image mb-3" /></div>
                    <p><strong className="medium">3. Enter your signcode</strong></p>
                    <p>Enter the 6 digit singcode you created during PlanetID activation process.</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-center"><img src={PidUg24EnSigned} className="threequarter-width-image mb-3" /></div>
                    <p><strong className="medium">4. Document signed</strong></p>
                    <p>You have successfully signed a document with your PlanetID.</p>
                  </div>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-md-12"><h5 className="mb-5">Give Consent with PlanetID</h5></div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-left"><img src={PidUg25EnConsentButton} className="half-width-image mb-3" /></div>
                    <p><strong className="medium">1. Give consent with PlanetID</strong></p>
                    <p>With your PlanetID you can give a consent to disclose your personal data to a third party.</p>
                    <p>Before you give your consent, check carefully that the data source and addressee are correct and exactly which data is going to be disclosed.</p>
                    <p>Only after confirming the above, start the process by clicking a button on your e-service website.</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-center"><img src={PidUg26EnConsentRequest} className="threequarter-width-image mb-3" /></div>
                    <p><strong className="medium">2. Consent Request</strong></p>
                    <p>Your Planet ID will send you a Consent Request. Check the content carefully.</p>
                    <p>Also, make sure that the security token displayed on the the e-service account matches the security token displayed on the app. If they match, you are on the right website.</p>
                    <p>After confirming the above, tap the "Approve" button.</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-center"><img src={PidUg27EnSignCode} className="threequarter-width-image mb-3" /></div>
                    <p><strong className="medium">3. Enter your signcode</strong></p>
                    <p>Enter the 6 digit singcode you created during PlanetID activation process.</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-center"><img src={PidUg28EnSigned} className="threequarter-width-image mb-3" /></div>
                    <p><strong className="medium">4. Consent given</strong></p>
                    <p>Consent has been granted to disclose your personal data to third party.</p>
                  </div>
                </div>
              </div>


            </div>
          </section>

        </main>

      </div>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query PlanetidUserGuideQueryEn {
    site {
      siteMetadata {
        en {
          title
          description
          keywords
        }
      }
    }
  }
`
