import React from 'react'
import { Helmet } from 'react-helmet'
import Link from 'gatsby-link'
import OgImage from "img/ogimg-recruit.jpg"
import get from 'lodash/get'
import { graphql } from 'gatsby'

class RootIndex extends React.Component {
  render() {
    const langPrefix = this.props.location.pathname.match(/^\/en/) ? '/en' : '';

    const siteTitle = get(this, 'props.data.site.siteMetadata.en.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.en.description')
    const siteKeywords = get(this, 'props.data.site.siteMetadata.en.keywords')

    return (
      <div>
        <Helmet
        	title={siteTitle}
        	meta={[
        		{ name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: 'https://planetway.com' + OgImage },
        		{ property: 'og:description', content: siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />

        <div className="page-header recruit-header dia-bottom">
          <div className="container text-on-dark text-center">
            <div className="row">
              <div className="col-md-12">
                <strong className="subtitle-small">Join the Planetway team</strong>
                <h2>Change the World for the Better</h2>
              </div>
            </div>
          </div>
        </div>

        <main>

          <div className="clear"></div>

          <section className="padding-bottom-none">
            <div className="container">
              <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-lg-8 text-center">
                  <h4 className="underline text-bold">Creating a human centric, data-driven society for a safe and secure world</h4>
                  <p>We firmly believe that data should belong to individuals, not organizations and that individuals should be able to grant access to their data safely, freely and at their own discretion.
                    Essentially, our vision is to realise <strong>independent, data-driven social innovation</strong>.  We aim to usher in a new era of <strong>human-centered data sovereignty</strong> where
                    data is utilized in a manner favorable to the individual and society by giving individuals personal control of when and how their data is used by third parties. Building on this, in the
                    near future, we strive to realize a society that goes beyond traditional capitalism.</p>
                </div>
                <div className="col-lg-2"></div>
              </div>
            </div>
          </section>

          <section className="padding-bottom-none">
            <div className="container-fluid recruit-montage-photo text-center">
              <div className="row">
                <div className="col"></div>
              </div>
            </div>
          </section>

          <section className="padding-bottom-none">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <h4>Exceptional team members</h4>
                  <p>We are a diverse team with extensive experience. For example, our CTO Tõnu Samuel was a core developer of MySQL, now the world's most popular open source database.</p>
                </div>
                <div className="col-md-6">
                  <h4>Team diversity</h4>
                  <p>Our Headquarters are in San Jose, USA and we have operational offices in Japan and Estonia. We have employees from all over the world and a multilingual office environment.</p>
                </div>
              </div>
            </div>
          </section>

          {/* <section className="padding-bottom-none">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <h4 className="underline text-bold">Back Office</h4>
                  <p className="subtitle-small text-bold">The Back Office operates mainly in Japan coordinating with our other global branches</p>
                  <p>Back Office has a wide range of responsibilities including welcoming customers, accounting, and sales support. Each member of the team has different duties,
                    but we all work to ensure that cooperation between team members runs smoothly especially during busy periods.</p>
                  <p><strong>Japan Office</strong><br />Our Sales, Business Development, and Back Office operations are currently based in Japan. It’s an international working environment
                  supporting both overseas and local employees, with Japanese, English and Estonian speaking staff.</p>
                  <p><strong>Estonia Office</strong><br />Along with our CIO, Masakazu Ohtsuka, most of our engineers live and work in Estonia.</p>
                  <p><strong>We are looking for back office staff who can fulfill various roles as our business and team expand.</strong><br />"Until recently, I had only been doing accounting
                  but I wanted to challenge myself with something new.", "I was a salesperson but I really wanted to try something different, to learn new skills." Planetway encourages employees
                  to take advantage of their previous experience but also challenge themselves in new ways!</p>
                </div>
                <div className="col-lg-4">
                  <div className="recruit-logo-planetway margin-bottom-lg"></div>
                  <p className="subtitle-small text-bold">Accounting</p>
                  <ul>
                    <li>Supporting sales members in expense adjustments</li>
                    <li>Managing accounting software and processing expenses</li>
                  </ul>
                  <p className="subtitle-small text-bold">General Affairs</p>
                  <ul>
                    <li>Processing and filing documents</li>
                    <li>Ordering and managing products, handling mail, etc.</li>
                  </ul>
                  <Link to={langPrefix + "/contact"} className="btn internal-link">Apply Now</Link>
                </div>
              </div>
            </div>
          </section>

          <section className="padding-bottom-none">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <h4 className="underline text-bold">Sales Engineer, Support Engineer</h4>
                  <p className="subtitle-small text-bold">Job Description</p>
                  <p>As a sales/support engineer, you liaise with our Business Development team, to introduce our products and provide technical support to the customers.</p>
                  <p>The tasks mostly involve communicating with our customers and partner companies, conducting product demonstrations, creating manuals, and building server infrastructure. Also, you will cooperate with our Estonia-based
                    engineer team to set up, test, and troubleshoot our software.</p>
                  <p><strong>Candidate requirements</strong><br />Our Sales, Business Development and Back Office functions are currently based in Japan. We have a multinational environment supporting both foreign and local employees, with Japanese, English and Estonian speaking staff.</p>
                  <p><strong>Estonia Office</strong><br />Along with our CIO, Masakazu Ohtsuka, most of our engineers live and work in Estonia.</p>
                  <p><strong>We are looking for back office staff who can respond to various tasks as our business and team expand.</strong><br />We are looking a person with one or more of the following experiences or skills:</p>
                  <ul>
                    <li>Introducing technical products into large Japanese companies</li>
                    <li>Able to communicate with engineers in English</li>
                    <li>Building the server infrastructure using the cloud (AWS, Azure, etc.)</li>
                    <li>Security knowledge such as public key cryptography</li>
                    <li>Experience with RDB operations such as MySQL, PostgreSQL, Oracle</li>
                    <li>Can use WireShark, tpcdump</li>
                    <li>Can read RFC</li>
                    <li>Can explain the differences between authentication and authorization</li>
                  </ul>
                </div>
                <div className="col-lg-4">
                  <div className="recruit-logo-planetway margin-bottom-lg"></div>
                  <p className="subtitle-small text-bold">Candidate Profile</p>
                  <p>Planetway is still a small startup so we are looking for individuals who can adapt to a wide range of challenges and mediate between customers and the Estonian-based engineering team.</p>
                  <Link to={langPrefix + "/contact"} className="btn internal-link">Apply Now</Link>
                </div>
              </div>
            </div>
          </section>

          <section className="padding-bottom-none">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <h4 className="underline text-bold">PlanetGuardians</h4>
                  <p className="subtitle-small text-bold">Education program for White Hat Hackers and Cyber Security Personnel</p>
                  <p><strong>Responsibilities</strong><br />PlanetGuardians (Education program for White Hat Hackers and Cyber Security Personnel)</p>
                  <p><strong>Our Mission</strong></p>
                  <ul>
                    <li>Educate PlanetGuardians and form a community</li>
                    <li>Expand sales</li>
                  </ul>
                  <p><strong>Candidate requirements</strong><br />PlanetGuardians (Education program for White Hat Hackers and Cyber Security Personnel)</p>
                  <p><strong>Our Mission</strong></p>
                  <ul>
                    <li>To cultivate and build relationships with our partner companies who are to be trained to become Guardians (Consulting Companies, SIer, Cloud Vendor, Security Solutions Companies)</li>
                    <li>Planning and developing educational programs (e-Learning based) and Cyber Range for training Guardians</li>
                    <li>Planning and promoting the Guardians’ certification system</li>
                    <li>Planning and operating the Guardians Community</li>
                    <li>Preparing and managing the annual sales budget</li>
                  </ul>
                  <p><strong>Candidate requirements</strong><br />We are now recruiting members from manager level. Someone who can utilize his or her past experience. English, Estonian or Japanese speakers welcome!</p>
                </div>
                <div className="col-lg-4">
                  <div className="recruit-logo-planetguardians margin-bottom-lg"></div>
                  <p className="subtitle-small text-bold">Candidate Profile</p>
                  <ul>
                    <li>Somebody who shares our vision and is willing to grow together with our customers and partners</li>
                    <li>Has an understanding and strong desire to change the current shortage of security talent in Japan’s cyber security field, which has become a national issue</li>
                    <li>Understands and embodies the personal image of the Guardians (ethical hacker, who protects the human centered data driven society) and is individually able to contribute into the creation and development of our educational program</li>
                    <li>Values flexibility more than knowledge, novelty more than experience, and has a will and spirit to absorb anything new</li>
                  </ul>
                  <Link to={langPrefix + "/contact"} className="btn internal-link">Apply Now</Link>
                </div>
              </div>
            </div>
          </section>

          <section className="padding-bottom-none">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <h4 className="underline text-bold">PlanetEco Business Planning</h4>
                  <p className="subtitle-small text-bold">Business Planning and Sales of PlanetEco</p>
                  <p><strong>Responsibilities</strong><br />Business planning and sales of PlanetEco (Open Innovation Platform project)</p>
                  <p><strong>Our Mission</strong></p>
                  <ul>
                    <li>Diffusion of Planetway's Core Product (PlanetCross: Cross Industry Data-Access Platform, and PlanetID: Personal Authentication Platform) for domestic and overseas utilization.</li>
                    <li>Expansion of Planetway Eco-Partner network (user and partner companies)</li>
                    <li>Increase sales</li>
                  </ul>
                  <p><strong>Duties and roles</strong></p>
                  <ul>
                    <li>To cultivate and build relations with our partner companies(consulting companies, system integrators, etc.)</li>
                    <li>Planning of PoC of Planetway products for multiple companies</li>
                    <li>Planning of Open Innovation Program for multiple companies</li>
                    <li>Drafting and management of annual budget and sales plan in collaboration with Project Manager</li>
                  </ul>
                  <p><strong>Candidate requirements</strong><br />We are now recruiting a wide range of members from staff to manager level. Someone who can utilize his or her past experience. English, Estonian or Japanese speakers welcome!</p>
                </div>
                <div className="col-lg-4">
                  <div className="recruit-logo-planeteco margin-bottom-lg"></div>
                  <p className="subtitle-small text-bold">Candidate Profile</p>
                  <ul>
                    <li>A driven person with high aspirations to promote change in the future digital society</li>
                    <li>Someone who can propose concrete ideas and activities based on our product sales expansion to solve social issues and problems the industries are currently facing</li>
                    <li>Someone who in addition to mastering technologies related to our products, is passionate about new technology trends and peripheral technologies, that could add extra value to our products</li>
                    <li>A person who can respect and relate to the way of thinking of our customers, partner companies, suppliers and other staff members and take realistic action on this</li>
                    <li>A person who is ready to cooperate and perform business activities in areas, which are not directly related to his or her duties, when the situation demands it</li>
                  </ul>
                  <Link to={langPrefix + "/contact"} className="btn internal-link">Apply Now</Link>
                </div>
              </div>
            </div>
          </section>

          <section className="padding-bottom-none">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <h4 className="underline text-bold">PlanetEco Project Manager</h4>
                  <p className="subtitle-small text-bold">Project Manager for PlanetEco</p>
                  <p><strong>Responsibilities</strong><br />PlanetEco (Open Innovation Platform)</p>
                  <p><strong>Our Mission</strong></p>
                  <ul>
                    <li>Sales Phase: Creating appropriate proposal schemes with the BD (Business Development) team</li>
                    <li>Delivery Phase: Project Management and Product or Service Delivery</li>
                    <li>Retention Phase: Collaborate with BD (Business Development) personnel, strengthen relationships with customers and partner companies, and promote sales expansion</li>
                  </ul>
                  <p><strong>Duties and roles</strong><br />Technology support, project promotion and sales expansion</p>
                  <p><strong>1. Sales phase</strong></p>
                  <ul>
                    <li>Cooperate with the Business Development team and Engineers to firmly position our products and services to customers and partner companies and make appropriate estimates, suggestions, and closings.
                      (Includes giving demos, answering technical questions for customers and partner companies, risk avoidance of the proposed schemes, etc.)</li>
                    <li>Evaluate the market situation and give appropriate feedback on the construction and revision of our pricing models. Promote further added value by developing the billing and other functions</li>
                    <li>Optimize scenario planning and define the clients’ requirements to give more effective demonstrations</li>
                  </ul>
                  <p><strong>2. Delivery phase</strong></p>
                  <ul>
                    <li>Work with Business Engineering members to install our products in customer and partner company’s environment</li>
                    <li>Perform initial setup and give appropriate product information or technical support to customers and partner companies</li>
                    <li>Give feedback to the engineers on how to expand and improve the delivery phase services</li>
                  </ul>
                  <p><strong>3. Retention Phase</strong></p>
                  <ul>
                    <li>Cooperate with business development personnel to strengthen relations with customers and partner companies and to create cross-sell/ upsell opportunities</li>
                    <li>Give feedback to the engineers how to expand and improve the retention phase services</li>
                  </ul>
                  <p><strong>Candidate requirements</strong><br />We are now recruiting wide range of members from staff to manager level. Someone who can utilize his or her past experience. English, Estonian or Japanese speakers welcome!</p>
                </div>
                <div className="col-lg-4">
                  <div className="recruit-logo-planeteco margin-bottom-lg"></div>
                  <p className="subtitle-small text-bold">Candidate Profile</p>
                  <ul>
                    <li>A driven person with high aspirations to promote change in the future digital society.</li>
                    <li>Someone who can propose concrete ideas and activities based on our product sales expansion to solve social issues and problems the industries are currently facing.</li>
                    <li>Someone who in addition to mastering technologies related to our products, is passionate about new technology trends and peripheral technologies, that could add extra value to our products</li>
                    <li>A person who can respect and relate to the way of thinking of our customers, partner companies, suppliers and other staff members and take realistic action on this.</li>
                    <li>A person who is ready to cooperate and perform business activities in areas, which are not directly related to his or her duties, when the situation demands it.</li>
                  </ul>
                  <Link to={langPrefix + "/contact"} className="btn internal-link">Apply Now</Link>
                </div>
              </div>
            </div>
          </section> */}

          <section className="padding-bottom-lg">
            <div className="container">
              <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-lg-8 text-center mobile-text-left">
                  <h3>Questions?</h3>
                  <Link to={langPrefix + "/contact"} className="btn internal-link">Contact Us</Link>
                </div>
                <div className="col-lg-2"></div>
              </div>
            </div>
          </section>

        </main>

      </div>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query RecruitQueryEn {
    site {
      siteMetadata {
        en {
          title
          description
          keywords
        }
      }
    }
  }
`
