import React from 'react'
import { Helmet } from 'react-helmet'
import Link from 'gatsby-link'
import OgImage from "img/ogimg-planetid.jpg"
import get from 'lodash/get'
import { graphql } from 'gatsby'

class RootIndex extends React.Component {
  render() {
    const langPrefix = this.props.location.pathname.match(/^\/en/) ? '/en' : '';

    const siteTitle = get(this, 'props.data.site.siteMetadata.en.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.en.description')
    const siteKeywords = get(this, 'props.data.site.siteMetadata.en.keywords')

    return (
      <div>
        <Helmet
        	title={siteTitle}
        	meta={[
        		{ name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: 'https://planetway.com' + OgImage },
        		{ property: 'og:description', content: siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />
        <main>

          <section className="pt-5">
            <div className="container">
              <div className="row">
                <div className="col"></div>
                <div className="col-sm-10">
                  <h4 className="underline">PlanetID News</h4>

                  <div className="pid-news-item mb-5">
                    <span class="news-date margin-bottom-sm">March 19, 2020</span>
                    <h5 className="mb-3">Planetway<strong>、データ個人主権の実現に向け、ユニバーサル</strong>ID<strong>プラットフォーム</strong>PlanetID<strong>リリース</strong></h5>
                    <p>PlanetwayCorporation（本社: 米国カリフォルニア州サンノゼ、CEO&Founder: 平尾 憲映、以下Planetway）は、ユニバーサルIDプラットフォームPlanetIDの商用提供を4月上旬に開始いたします。</p>
                    <p>PlanetwayCorporation（本社: 米国カリフォルニア州サンノゼ、CEO&Founder: 平尾 憲映、以下Planetway）は、ユニバーサルIDプラットフォームPlanetIDの商用提供を4月上旬に開始いたします。Planetwayは、PlanetIDとデータ連携基盤PlanetCrossを活用し、個人情報を個人の価値として自らコントロールできる世界、データ個人主権の実現に向けて世界をリードしていきます。</p>
                    <p>PlanetIDは、世の中に乱立しているIDの統合に向けて、必要とされる高度なセキュリティ等の機能を有しており、エンドユーザーに圧倒的な利便性を提供いたします。また、個人の実在性を保証したユニバーサルなIDであること、また、電子証明書を活用していることから、スマートコントラクトの領域における利活用が期待されています。さらに、許諾機能を有することから、個人情報やプライバシーに関する情報等、秘匿性の高いデータを利活用する領域、例えば、個人情報銀行などの領域において大きな効果を発揮します。個人を主体として、個人データを安全に利活用する新たなサービスを作りたいというご要望にお応えすることができます。</p>
                    <p>PlanetIDは、個人を識別する新しいユニバーサルIDプラットフォームです。シンプルで使いやすく、ウェブサイトやスマートフォンアプリにおける新しいサービスを便利にセキュアに利用することができます。PlanetIDを利用すると、例えば、下記のような新しいサービスを体験する世界を実現することが可能です。</p>
                    <ul>
                      <li>（認証機能）エンドユーザーは、様々なウェブサイトやスマートフォンアプリのIDとパスワードを覚える必要は無くなります。PlanetIDで設定したPINコードを利用するだけで、様々なサービスにセキュアにログインすることが可能となります。</li>
                      <li>（電子署名）電子署名を利用することにより、オンライン上で登録や契約を行うことができます。すなわち、これまで印鑑を必要とした手続きを、全てオンラインで行うことができるようになります。</li>
                      <li>（許諾機能）電子サービスにおける個人のデータ提供に対して、承認または否認の意思を表明すること（個人データのコントロール）ができます。
                      この度は、昨年リリースしたMVP（Minimum Viable Product）版から各種品質向上を行い製品版としての販売を開始するものとなります。</li>
                    </ul>
                    <h5>Planetway について</h5>
                    <p>Planetway Corporation は、米国カリフォルニア州に本社を構え、電子行政先進国とされるエストニア共和国を支える情報連携技術「X-Road」に、独自開発を加えたプラットホームのコア技術 「PlanetCross」とエストニアの国民番号制度を参考にしたデジタルIDプラットフォームであり、本人確認レベルに応じたIDの付与、認証、および電子署名の機能を提供する「PlanetID」とを展開するスタートアップ企業です。なお、弊社はX-ROAD TECHNOLOGY PARTNERS – SILVERに認定されております。弊社は、個人およびプライバシーに関する情報の権利は、本来企業や団体ではなく個人に帰属すべきであるとの理念のもと、「For the Human-centric and Secure Planet」をミッションとして掲げ、『本人自身の判断による、許諾、否認』を前提とした個人情報の公開/活用の促進を目指しています。</p>
                    <p><strong>コーポレートウェブサイト:</strong> <a href="https://planetway.com/en/">https://planetway.com/en/</a></p>
                    <p><strong>X-ROAD TECHNOLOGY PARTNERS-SILVER:</strong> <a href="https://x-road.global/xroad-technology-partners">https://x-road.global/xroad-technology-partners</a></p>
                    <p>※本文中に記載されている会社名、製品名などは、各社の商標または登録商標です。</p>
                    <h5>代表プロフィールについて</h5>
                    <p>1983年生まれ。エンタメ、半導体、IoT分野で、3度の起業と1度の会社清算を経験。学生時代、米国にて宇宙工学、有機化学、マーケティングを学ぶ傍ら、ハリウッド映画およびゲーム機向けコンテンツ制作スタートアップを創業。2008年、カリフォルニア州立大学を卒業後、ソフトバンクに入社し、800名超の全国携帯販売コンテストでの優勝を含む複数の社内アワードを受賞。2011年より、東北大学と共同開発研究契約を締結した技術ライセンス会社（TLO）を共同創業し、500件の特許戦略を軸に、次世代半導体分野で事業拡大を試みるも、清算を経験。2014年に、台湾のIoT機器ODMベンダー、サーコムの事業開発マネージャー、ワイヤレスゲートの新事業イノベーション室長に就任し、主にIoT分野の新事業開発を担当した。2015年7月より、自身の多様な成功・失敗体験を糧に、グローバルスタートアップ(米国、日本、エストニア)のPlanetwayを創業。2017年、東京海上日動と飯塚病院の間をセキュアに情報連携する基盤技術を導入し、同プロジェクトで世界No.1の評価を得た。2019年には自社製品(PlanetCross)の初商用化が決定し、現在130万人を超えるサービスユーザーへの重要なインフラ部分を担っている。2020年以降は、日本国内、グローバルでの本格的なインフラ拡張を目指している。データ個人主権時代の実現と資本主義の進化について独自のビジョンを掲げている。</p>
                    <p><Link to={langPrefix + "/planetid/planetid-news"} className="btn internal-link">Back</Link></p>
                  </div>

                </div>
                <div className="col"></div>
              </div>
            </div>
          </section>

        </main>

      </div>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query PlanetidNews1Query {
    site {
      siteMetadata {
        en {
          title
          description
          keywords
        }
      }
    }
  }
`
