import React from 'react'
import { Helmet } from 'react-helmet'
import OgImage from "img/ogimg-contact.jpg"
import get from 'lodash/get'
import { graphql } from 'gatsby'

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.description')
    const siteKeywords = get(this, 'props.data.site.siteMetadata.keywords')

    return (
      <div>
        <Helmet
        	title={siteTitle}
        	meta={[
        		{ name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: 'https://planetway.com' + OgImage },
        		{ property: 'og:description', content: siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />
        <main className="no-header">

          <div className="clear"/>

          <section className="padding-top-md padding-bottom-none">
            <div className="container">
              <div className="row">
                <div className="col"/>
                <div className="col-lg-8 text-center">
                  <h4 className="underline"><strong>「</strong>PlanetForum 1st<strong>」アンケートのお願い</strong></h4>
                  <h5>Request for feedback by questionnaire for the 1st PlanetForum</h5>
                  <p>本日はPlanetForumへのご参加ありがとうございました。アンケートにご協力頂ければと思います。</p>
                  <p>Thank you for participating in PlanetForum today. We would be grateful if you could fill in this questionnaire.</p>
                  <p className="text-small">記載していただいた個人情報は、PlanetForum、PlanetEchoならびにPlanetway社製品に関するご連絡以外に利用しません。
                  ご本人の同意がなければ第三者に個人情報を提供することもございません。取得した個人情報は管理責任者を定め、紛失や漏洩
                  などが発生しないよう積極的な安全対策を実施いたします。</p>
                  <p className="text-small">The personal information provided will not be used for any purpose other than contacting PlanetForum,
                  PlanetEcho and Planetway products. We will not provide personal information to third parties without
                  your consent. The manager of the acquired personal information will be assigned to the responsible
                  manager, and we will take active measures to prevent loss and leakage.</p>
                </div>
                <div className="col"/>
              </div>
            </div>
          </section>

          <section className="padding-top-none padding-bottom-lg">
            <div className="container">
              <div className="row">
                <div className="col"/>
                <div className="col-lg-8">
                  <form action="https://formspree.io/pr@planetway.com" method="POST">
                    <input type="hidden" name="_next" value="https://planetway.com/thanks" />
                    <input type="hidden" name="_language" value="ja" />

                    <fieldset id="name">
                      <label htmlFor="name">名前 / Name</label>
                      <input type="text" name="name" placeholder="" required />
                    </fieldset>
                    <fieldset id="company">
                      <label htmlFor="company">会社名 / Company</label>
                      <input type="text" name="company" placeholder="" required />
                    </fieldset>
                    <fieldset id="email">
                      <label htmlFor="email">メールアドレス / E-mail</label>
                      <input type="email" name="email" placeholder="" required />
                    </fieldset>

                    <hr className="margin-top-lg margin-bottom-lg" />

                    <fieldset id="satisfaction">
                      <label htmlFor="satisfaction">1. 本日のPlanetForumの満足度を教えてください</label>
                      <p>How satisfied are/were you with PlanetForum today?</p>
                      <p><input type="radio" name="satisfaction" value="Extremely satisfied" /> 大変よかった / Extremely satisfied</p>
                      <p><input type="radio" name="satisfaction" value="Moderately satisfied" /> まあまあよかった / Moderately satisfied</p>
                      <p><input type="radio" name="satisfaction" value="Moderately dissatisfied" /> あまりよくなかった / Moderately dissatisfied</p>
                      <p><input type="radio" name="satisfaction" value="Extremely dissatisfied" /> よくなかった / Extremely dissatisfied</p>
                    </fieldset>

                    <fieldset id="satisfaction_reason">
                      <label htmlFor="satisfaction_reason">2. 1の理由を記載ください(任意)</label>
                      <p>What is the reason for the answer you gave for question 1?</p>
                      <input type="text" name="satisfaction_reason" placeholder="" />
                    </fieldset>

                    <hr className="margin-top-lg margin-bottom-lg" />

                    <fieldset id="session_preference">
                      <label htmlFor="session_preference">3. 本日のPlanetforumのうち、興味を持った、実施してよかったセッションは何でしょうか？（複数回答可）</label>
                      <p>Which sessions did you most enjoy today? (multiple answers ok)</p>
                      <p className="d-flex"><input type="checkbox" name="session_preference" value="Davos Intro" /><span className="d-block">13:50〜14:05 DAVOS報告とオープニングスピーチ / DAVOS report with full video and Nori's opening speech</span></p>
                      <p className="d-flex"><input type="checkbox" name="session_preference" value="Ratas Speech" /><span className="d-block">14:05〜14:15 ラタス首相スピーチ / Speech by Prime Minister Ratas</span></p>
                      <p className="d-flex"><input type="checkbox" name="session_preference" value="Tsuruho Speech" /><span className="d-block">14:15〜14:20 鶴保参議院議員のご挨拶 / Speech by Mr.Tsuruho, Member of the House of Councilors</span></p>
                      <p className="d-flex"><input type="checkbox" name="session_preference" value="VIP Photoshoot" /><span className="d-block">14:20〜14:30 VIP写真撮影 / VIP photoshoot</span></p>
                      <p className="d-flex"><input type="checkbox" name="session_preference" value="Signing Ceremony" /><span className="d-block">14:30〜14:40 MOUサインセレモニー / Signing ceremony of MOU</span></p>
                      <p className="d-flex"><input type="checkbox" name="session_preference" value="PDSA Presentation" /><span className="d-block">14:50〜15:00 Planetway®︎Data Sovereignty Alliance説明 /  Presentation outlining Planetway®︎Data Sovereignty Alliance</span></p>
                      <p className="d-flex"><input type="checkbox" name="session_preference" value="PXFT Presentation" /><span className="d-block">15:00〜15:10 PlanetCross Free Trial説明 / Presentation outlining PlanetCross Free Trial</span></p>
                      <p className="d-flex"><input type="checkbox" name="session_preference" value="NICIGAS Case Study" /><span className="d-block">15:10〜15:20 ニチガス様事例紹介 / NICIGAS case study</span></p>
                      <p className="d-flex"><input type="checkbox" name="session_preference" value="Panel Discussion" /><span className="d-block">15:20〜15:40 トークセッション / Panel session</span></p>
                      <p className="d-flex"><input type="checkbox" name="session_preference" value="Group Photoshoot" /><span className="d-block">15:40〜15:55  全体写真撮影 / Group photoshoot</span></p>
                      <p className="d-flex"><input type="checkbox" name="session_preference" value="Business Card Exchange" /><span className="d-block">16:05〜16:30 名刺交換会 / Business card exchange</span></p>
                      <p className="d-flex"><input type="checkbox" name="session_preference" value="None" /><span className="d-block">特にない / None in particular</span></p>
                    </fieldset>

                    <fieldset id="session_preference_reason">
                      <label htmlFor="session_preference_reason">4. 3の理由を教えてください(任意)</label>
                      <p>What is the reason for the answer you gave for question 3?</p>
                      <input type="text" name="session_preference_reason" placeholder="" />
                    </fieldset>

                    <hr className="margin-top-lg margin-bottom-lg" />

                    <h4 className="underline">Planetway®︎Data Sovereignty Alliance<strong>につきまして</strong></h4>
                    <h5>About Planetway®︎Data Sovereignty Alliance</h5>

                    <fieldset id="pdsa_difficulty">
                      <label htmlFor="pdsa_difficulty">5. Planetway®︎Data Sovereignty Allianceについてご理解頂けましたでしょうか</label>
                      <p>How easy did you find it to understand Planetway®︎Data Sovereignty Alliance?</p>
                      <p><input type="radio" name="pdsa_difficulty" value="Easy" /> よくわかった / Easy to understand</p>
                      <p><input type="radio" name="pdsa_difficulty" value="Understandable" /> まあまあわかった / Understandable</p>
                      <p><input type="radio" name="pdsa_difficulty" value="Difficult" /> あまりわからなかった / Difficult to understand</p>
                      <p><input type="radio" name="pdsa_difficulty" value="Impossible" /> 全くわからなかった / Impossible to understand</p>
                    </fieldset>

                    <fieldset id="pdsa_difficulty_reason">
                      <label htmlFor="pdsa_difficulty_reason">6. 5の理由を教えてください(任意)</label>
                      <p>What is the reason for the answer you gave for question 5?</p>
                      <input type="text" name="pdsa_difficulty_reason" placeholder="" />
                    </fieldset>

                    <hr className="margin-top-lg margin-bottom-lg" />

                    <fieldset id="pdsa_participation">
                      <label htmlFor="pdsa_participation">7. 現時点でのPlanetway®︎ Data Sovereignty Allianceへの参加意思を教えてください</label>
                      <p>Would you be willing to participate in Planetway®︎Data Sovereignty Alliance at this time?</p>
                      <p><input type="radio" name="pdsa_participation" value="Would like to" /> 是非参加したい / I would like to participate</p>
                      <p><input type="radio" name="pdsa_participation" value="Depends" /> 今後の詳細によっては参加したい / I would like to participate depending on the details</p>
                      <p><input type="radio" name="pdsa_participation" value="Not sure" /> 現時点でどちらともいえない / Not sure</p>
                      <p><input type="radio" name="pdsa_participation" value="No" /> 参加したいと思わない / I wouldn't like to participate</p>
                    </fieldset>

                    <fieldset id="pdsa_participation_reason">
                      <label htmlFor="pdsa_participation_reason">8. 7の理由を教えてください(任意)</label>
                      <p>What is the reason for the answer you gave for question 7?</p>
                      <input type="text" name="pdsa_participation_reason" placeholder="" />
                    </fieldset>

                    <hr className="margin-top-lg margin-bottom-lg" />

                    <fieldset id="comments">
                      <label htmlFor="comments">9. 自由記入欄（全体を通しての感想・期待・改善点など）</label>
                      <p>Please add any comments in the box below</p>
                      <textarea name="comments"/>
                    </fieldset>

                    <button type="submit" className="btn">Submit</button>
                  </form>
                </div>
                <div className="col"/>
              </div>
            </div>
          </section>

        </main>

      </div>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query PlanetForumQuestionnaireQueryEn {
    site {
      siteMetadata {
        title
        description
        keywords
      }
    }
  }
`
