import React, {Component} from "react"

//renders sections for documentation and faq
class DocSection extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const htmlData = this.props.text;

    function createMarkup() {
      return {__html: htmlData};
    }

    function myComponent() {
      return <div dangerouslySetInnerHTML={createMarkup()}/>;
    }
    return (
      <div className="docLink">
        <h4 className="margin-top-md">{this.props.title}</h4>
        {this.props.info.type !== "faqSection" ? <div className='sectionContent'>{myComponent()}</div>
                                               : <div className='faqSectionContent'>{myComponent()}</div>}
        {this.props.info.type === "faqSection" ? this.props.info.underline ? <div className="hr"/>
                                                                           : null
                                               : this.props.info.underline ? <div className="hr-light"/>
                                                                           : null}
      </div>
    )
  }
}


export default DocSection
