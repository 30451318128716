import React from 'react'
import Link from 'gatsby-link'
import styles from './navigation.module.css'
import { NavbarToggler } from 'reactstrap'
import LanguageLink from 'components/language-link'

class Navigation extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {

    //this.bgCheck()
    const langPrefix = this.props.location.pathname.match(/^\/en/) ? '/en' : '';
    /* let body = document.querySelector('body');
     let compStyles = window.getComputedStyle(body).backgroundPosition;*/

    const colorClass = this.props.isWhiteText ? "dark-header" : "light-header";

    return (
      <div id="header-wrapper" className={colorClass}>
      <header>

        <nav className="main-nav">
          <div className='container-fluid'>

            <h1 className="brand"><Link to={langPrefix + "/"} className="internal-link">Planetway</Link></h1>

            <LanguageLink lang='ja' to={this.props.location.pathname.replace(/^\/en/,'')} />

            <div className="menu-wrapper">

              <ul className="main-menu">
                <li>
                  <Link to={langPrefix + "/our-vision"} className="internal-link">About</Link>
                  <div className="dropdown" style={{ zIndex: "103" }}>
                    <Link to={langPrefix + "/our-vision"} className="internal-link">Our Vision</Link>
                    <Link to={langPrefix + "/corporate-profile"} className="internal-link">Corporate Profile</Link>
                    <Link to={langPrefix + "/corporate-leadership"} className="internal-link">Corporate
                      Leadership</Link>
                  </div>
                </li>
                <li>
                  <Link to={langPrefix + "/planetcross"} className="internal-link">Solutions</Link>
                  <div className="dropdown" style={{ zIndex: "102" }}>
                    <Link to={langPrefix + "/planetcross"} className="internal-link">PlanetCross</Link>
                    <Link to={langPrefix + "/planetid"} className="internal-link">PlanetID</Link>
                    <Link to={langPrefix + "/security-service-for-gdpr"} className="internal-link">Security Service for GDPR</Link>
                  </div>
                </li>
                <li><Link to={langPrefix + "/media"} className="internal-link">Media</Link></li>
                {/*<li><Link to={langPrefix + "/recruit"} className="internal-link">Recruit</Link></li>*/}
                <li><Link to={langPrefix + "/contact"} className="internal-link">Contact</Link></li>
              </ul>
            </div>

            <div className="hamburger" onClick={() => {document.body.classList.toggle('mobile-menu-open')}}>
              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>
            </div>
            <div className="clear"></div>
          </div>
        </nav>
      </header>
      </div>
    );
  }
}

export default Navigation
