import React, {Component} from "react"
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
// import '../assets/css/main.css'

class TopScroll extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <Link to={this.props.link} className="topScroll">
        { /* <FontAwesomeIcon icon="arrow-up"/> */ }
        </Link>
    )
  }
}

export default TopScroll
