import React from 'react'
import { Helmet } from 'react-helmet'
import Link from 'gatsby-link'
import OgImage from "img/ogimg-planetcross.jpg"
import PartnerLogoIcefire from "img/partner-logo-icefire.png"
import get from 'lodash/get'
import { graphql } from 'gatsby'
import Layout from "../../components/layout"

class RootIndex extends React.Component {
  render() {
    const langPrefix = this.props.location.pathname.match(/^\/en/) ? '/en' : '';

    const siteTitle = get(this, 'props.data.site.siteMetadata.en.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.en.description')
    const siteKeywords = get(this, 'props.data.site.siteMetadata.en.keywords')

    return (
      <Layout location={this.props.location}>
        <Helmet
        	title={siteTitle}
        	meta={[
        		{ name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: 'https://planetway.com' + OgImage },
        		{ property: 'og:description', content: siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />
        <main className="no-header">

          <div className="clear" />

          <section className="padding-top-lg">
            <div className="container">
              <div className="row">
                <div className="col" />
                <div className="col-sm-10"><div className="partner-logo"><img src={PartnerLogoIcefire} /></div>
                  <h4 className="underline">Icefire OÜ</h4>
                  <h5 className="margin-bottom-sm">Digital banking and fintech solutions are part of Icefire’s DNA from the beginning</h5>
                  <p>Icefire designs and builds transformative technologies for banks and fintech companies. The company simplifies and automates complicated processes, replacing tedium with meaningful work fit for humans.</p>
                  <p>Icefire's story started in the 1990s when our future core team members helped build Hansabank, a wildly successful, innovative, technologically advanced bank that was later acquired by Swedbank. The company has evolved a lot since then, but the original values remain ingredients in Icefire's magic sauce: innovation, lightweight processes, quick decisions, zero bureaucracy, and complete trust in our people.</p>
                  <p>Since 2002, Icefire has been designing and building technological solutions, mainly for clients in the financial sector.</p>
                  <h5 className="margin-top-md margin-bottom-sm">Key Competence Areas</h5>
                  <p>business, industry 4.0 and finance e-solutions, e-Tax, e-Banking</p>
                  <h5 className="margin-top-md margin-bottom-sm">Case Study</h5>
                  <p>Icefire helped turn the Estonian Tax and Customs Board into one of the most modern and efficient tax authorities in the world. This was one of the first projects that helped to push forward Estonia’s e-government services and systems. Estonias new e-Tax and e-Customs will be given new face by Icefire at the moment. Each year, around 98 per cent of all tax declarations in Estonia are filed electronically via e-Tax, an electronic tax filing system set up by the Estonian Tax and Customs Board. Online tax and customs solutions in Estonia are considered the most advanced in the world.</p>
                  <p>For more information: <a href="https://www.icefire.ee/" target="_blank">https://www.icefire.ee/</a></p>
                  <p className="text-center mt-5"><Link to={langPrefix + "/pdsa-partners"} className="btn internal-link">Back</Link></p>
                </div>
                <div className="col" />
              </div>
            </div>
          </section>

        </main>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query PdsaPartnersIcefireQueryEn {
    site {
      siteMetadata {
        en {
          title
          description
          keywords
        }
      }
    }
  }
`
