import React from 'react'
import { Helmet } from 'react-helmet'
import Link from 'gatsby-link'
import OgImage from "img/ogimg-planetcross.jpg"
import get from 'lodash/get'
import { graphql } from 'gatsby'
import Layout from "../components/layout"

class RootIndex extends React.Component {
  render() {
    const langPrefix = this.props.location.pathname.match(/^\/en/) ? '/en' : '';

    const siteTitle = get(this, 'props.data.site.siteMetadata.en.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.en.description')
    const siteKeywords = get(this, 'props.data.site.siteMetadata.en.keywords')

    return (
      <Layout location={this.props.location}>
        <Helmet
        	title={siteTitle}
        	meta={[
        		{ name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: 'https://planetway.com' + OgImage },
        		{ property: 'og:description', content: siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />
        <main className="no-header">

          <div className="clear" />

          <section className="padding-top-lg">
            <div className="container">
              <div className="row">
                <div className="col" />
                <div className="col-sm-10 text-center">
                  <h4 className="underline">Find a Partner</h4>
                  <h5 className="margin-bottom-md"><strong>提携しているパートナー企業をご紹介します。</strong></h5>

                  <aside className="card-box housall margin-bottom-sm">
                    <div className="card-body">
                      <h6 className="card-title">Housall</h6>
                      <p className="padding-top-xs">住まいと暮らしにまつわる新たなB2B2Cビジネスモデルを創出し…</p>
                    </div>
                    <div className="card-cta">
                      <Link to={langPrefix + "/pdsa-partners-housall"} className="btn internal-link">Read More</Link>
                    </div>
                  </aside>

                  <aside className="card-box nortal margin-bottom-sm">
                    <div className="card-body">
                      <h6 className="card-title">Nortal AS</h6>
                      <p className="padding-top-xs">エストニアの電子政府ソリューションの40％以上を構築した…</p>
                    </div>
                    <div className="card-cta">
                      <Link to={langPrefix + "/pdsa-partners-nortal"} className="btn internal-link">Read More</Link>
                    </div>
                  </aside>

                  <aside className="card-box aktors margin-bottom-sm">
                    <div className="card-body">
                      <h6 className="card-title">Aktors OÜ</h6>
                      <p className="padding-top-xs">X-Roadテクノロジーの先駆者</p>
                    </div>
                    <div className="card-cta">
                      <Link to={langPrefix + "/pdsa-partners-aktors"} className="btn internal-link">Read More</Link>
                    </div>
                  </aside>

                  <aside className="card-box icefire margin-bottom-sm">
                    <div className="card-body">
                      <h6 className="card-title">Icefire OÜ</h6>
                      <p className="padding-top-xs">デジタルバンキングとフィンテックソリューションは設立から…</p>
                    </div>
                    <div className="card-cta">
                      <Link to={langPrefix + "/pdsa-partners-icefire"} className="btn internal-link">Read More</Link>
                    </div>
                  </aside>

                  <aside className="card-box netgroup margin-bottom-sm">
                    <div className="card-body">
                      <h6 className="card-title">Net Group OÜ</h6>
                      <p className="padding-top-xs">ルワンダとタンザニアのデジタルガバナンスをアップグレードして…</p>
                    </div>
                    <div className="card-cta">
                      <Link to={langPrefix + "/pdsa-partners-netgroup"} className="btn internal-link">Read More</Link>
                    </div>
                  </aside>
                </div>
                <div className="col" />
              </div>
            </div>
          </section>
        </main>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query PdsaPartnersQuery {
    site {
      siteMetadata {
        en {
          title
          description
          keywords
        }
      }
    }
  }
`
