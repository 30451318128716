import React from 'react'
import { Helmet } from 'react-helmet'
import Link from 'gatsby-link'
import OgImage from "img/ogimg-planetid.jpg"
import get from 'lodash/get'
import { graphql } from 'gatsby'

class RootIndex extends React.Component {
  render() {
    const langPrefix = this.props.location.pathname.match(/^\/en/) ? '/en' : '';

    const siteTitle = get(this, 'props.data.site.siteMetadata.en.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.en.description')
    const siteKeywords = get(this, 'props.data.site.siteMetadata.en.keywords')

    return (
      <div>
        <Helmet
        	title={siteTitle}
        	meta={[
        		{ name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: 'https://planetway.com' + OgImage },
        		{ property: 'og:description', content: siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />
        <main>

          <section className="pt-5">
            <div className="container">
              <div className="row">
                <div className="col"></div>
                <div className="col-sm-10">
                  <h4 className="underline"><strong>プライバシーノーティス</strong></h4>

                  <div className="mb-5">
                    <span class="news-date margin-bottom-sm">2020年3月27日 version 1.0</span>
                    <p>本プライバシーノーティス内では、Planetway（以下「当社」、プロフィール参照）がウェブサイトplanetway.com（以下「ウェブサイト」）、モバイルアプリまたはその他プラットフォームやサービス（まとめて以下「Planetwayソリューション」）の訪問者およびユーザーの個人データをどのように収集し処理するかを説明しています。訪問者およびユーザーを「利用者」もしくは「データ主体」と記載してあります。</p>
                    <p>当社はPlanetwayソリューションで処理される全ての情報のデータ管理者です。 当社が個人データ処理の目的と手段に関して責任を負います。当社はまた、このプライバシーノーティスを一方的に修正する権利を有し、その場合、当社ウェブサイト、モバイルアプリ、アプリストアまたはその他の方法で変更について公表します。</p>
                    <p>本プライバシーノーティスは、PlanetCrossプラットフォーム上で処理された個人データには適用されません。 そのような場合、データ管理者はPlanetCrossプラットフォームを取得し使用している組織がデータ管理者となり、個人データ処理の責任を負います。この場合にはPlanetwayは「データ処理者」となります。</p>

                    <h5 className="mb-3 margin-top-lg"><strong>当社が処理する個人データの種類</strong></h5>
                    <p>当社は、データ主体としての利用者の次の個人データのカテゴリーを処理することがあります。</p>
                    <ol>
                      <li>ウェブサイトへの訪問データおよびクッキー：
                        <ul className="pt-2">
                          <li>ウェブサイトの利用方法に関するデータ。以下の「クッキー及びその他ウェブ技術」の説明をご覧ください。</li>
                          <li>IPアドレス</li>
                          <li>利用者の位置情報</li>
                        </ul>
                      </li>
                      <li className="pt-2">契約書内やウェブフォームなどから入力された連絡先データ：
                        <ul className="pt-2">
                          <li>氏名</li>
                          <li>組織</li>
                          <li>メールアドレス</li>
                          <li>電話番号</li>
                        </ul>
                      </li>
                      <li className="pt-2">顧客識別データ：
                        <ul className="pt-2">
                          <li>雇用先（組織）</li>
                          <li>氏名</li>
                          <li>役職</li>
                          <li>コミュニケーション記録</li>
                          <li>顧客IDもしくはユーザーID（例：PlanetIDもしくはPlanetCross company ID）</li>
                        </ul>
                      </li>
                      <li className="pt-2">契約関連データ：
                        <ul className="pt-2">
                          <li>取引履歴・支払い履歴（クレジットカードデータは含まれない）</li>
                          <li>顧客に関連する契約</li>
                        </ul>
                      </li>
                      <li className="pt-2">問い合わせデータ（問い合わせの理由にかかわらず）：
                        <ul className="pt-2">
                          <li>インシデントに関する情報</li>
                          <li>顧客、訪問者またはユーザーのコミュニケーション履歴</li>
                        </ul>
                      </li>

                    </ol>

                    <aside className="notes-box ml-0 margin-top-lg margin-bottom-lg" style={{ maxWidth: "none" }}>
                      <h5 className="box-header icon-warning"><strong>注意事項</strong></h5>
                      <div className="box-body">
                        <p>Planetwayが訪問者に対し特別なカテゴリーのデータ（人種的もしくは民族的な出自、政治的な意見、宗教上もしくは思想上の信条、又は、労働組合への加入、遺伝子データ、生体データ健康に関するデータ、又は自然人の性生活もしくは性指向）の提出を要求することはありません。何らかの理由で訪問者から送信されたこのようなデータは処理されること無く削除されます。</p>
                      </div>
                    </aside>

                    <h5 className="mb-3 margin-top-lg"><strong>利用者の個人データ処理の目的</strong></h5>
                    <p>当社は、Planetwayソリューション、カスタマーサポートの運用および改善、そして契約の維持のために利用者の個人データを使用します。</p>
                    <p><strong>契約の履行に関する処理</strong></p>
                    <p>対象となる状況（ユースケース）</p>
                    <ol>
                      <li>次の様な契約関係を確立するため：
                        <ul className="pt-2">
                          <li>Planetway製品およびサービスのいずれかに関するユーザー契約</li>
                          <li>Planetwayコミュニティに加入するための利用規約（例：PXの無料試用もしくはPlanetway Data Sovererign Alliance）</li>
                        </ul>
                      </li>
                      <li>既存の契約関係を発展させるため</li>
                      <li>顧客として自身を識別するため</li>
                      <li>サポートサービスを受けるため</li>
                    </ol>
                    <p>処理するデータ</p>
                    <ol>
                      <li>氏名</li>
                      <li>メールアドレス</li>
                      <li>電話番号</li>
                      <li>雇用先（組織）</li>
                      <li>組織のドメイン名</li>
                      <li>役職</li>
                      <li>顧客コミュニケーション履歴</li>
                      <li>取引・支払い履歴</li>
                      <li>顧客に関する契約</li>
                    </ol>
                    <p><strong>正当な利益に基づく処理</strong></p>
                    <p>対象となる状況（ユースケース）</p>
                    <ol>
                      <li>Planetwayコミュニティ内のメンバーと交流するためのアカウントの作成</li>
                      <li>情報セキュリティの管理およびデータ漏洩の防止</li>
                      <li>Planetwayに関する問い合わせへの回答</li>
                      <li>PlanetIDを試用してデジタル署名されたドキュメント（PlanetIDの使用終了後）</li>
                    </ol>
                    <p>処理するデータ</p>
                    <ol>
                      <li>氏名</li>
                      <li>メールアドレス</li>
                      <li>電話番号</li>
                      <li>雇用先（組織）</li>
                      <li>役職</li>
                      <li>インシデントに関する情報</li>
                      <li>顧客コミュニケーション履歴</li>
                      <li>PlanetID電子署名</li>
                    </ol>
                    <p><strong>ユーザーの同意に基づく処理</strong></p>
                    <p>対象となる状況（ユースケース）</p>
                    <ol>
                      <li>マーケティング、セールスキャンペーンおよびユーザーとの関係の管理</li>
                      <li>今後のセミナーやその他の関連イベントについてのお知らせ</li>
                      <li>サービスやイベントの運営方法に関するフィードバック</li>
                    </ol>
                    <p>処理するデータ</p>
                    <ol>
                      <li>Planetwayソリューションの使用方法に関するデータ</li>
                      <li>利用者の位置情報</li>
                      <li>氏名</li>
                      <li>メールアドレス</li>
                      <li>電話番号</li>
                      <li>雇用先（組織）</li>
                      <li>役職</li>
                    </ol>

                    <h5 className="mb-3 margin-top-lg"><strong>個人データの処理方法</strong></h5>

                    <p>当社は以下の手順で個人データを処理します：</p>

                    <ul>
                      <li>すべてのデータは電子形式で処理されます。</li>
                      <li>データ主体である利用者に対して本プライバシーノーティスを読むよう指示します。</li>
                      <li>当社は、GDPRおよびAPPI（個人情報保護に関する法律）の要件に従って、データ主体の個人データを処理します。</li>
                      <li>当社は個人データに関して次の保持ポリシーを設定します。
                        <ul>
                          <li>マーケティング目的で2年</li>
                          <li>カスタマーサポートに関する個人データを5年</li>
                          <li>契約の履行に必要な個人データを10年（契約期日から開始）</li>
                          <li>セキュリティインシデントまたはデータ漏洩に関する個人データを15年</li>
                          <li>PlanetIDで署名されたドキュメントの場合は期限無し</li>
                        </ul>
                      </li>
                      <li>当社は、適用される法律で義務づけられてる場合を除き、データ主体の個人データを上記で示している以上長く保持する義務を負いません。</li>
                    </ul>

                    <h5 className="mb-3 margin-top-lg"><strong>処理者</strong></h5>
                    <p>Planetwayがデータ管理者である場合、Planetwayソリューション上のデータを処理する目的で次のデータ処理者を使用します。</p>
                    <ul>
                      <li>Amazon AWS (<a href="https://aws.amazon.com/privacy/" target="_blank">https://aws.amazon.com/privacy/</a>)</li>
                      <li>Microsoft Office 365 (<a href="https://privacy.microsoft.com/en-GB/privacystatement" target="_blank">https://privacy.microsoft.com/en-GB/privacystatement</a>)</li>
                      <li>当社ウェブサイト上で使用しているGoogleアナリティクスおよびYoutube機能を提供するGoogle (<a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>)</li>
                      <li>当社ウェブサイト上で使用している動画機能を提供するVimeo (<a href="https://vimeo.com/privacy" target="_blank">https://vimeo.com/privacy</a>)</li>
                      <li>ウェブサイトサービスFormspree (<a href="https://formspree.io/legal/privacy-policy" target="_blank">https://formspree.io/legal/privacy-policy</a>)</li>
                      <li>Slack (<a href="https://slack.com/intl/en-ee/privacy-policy" target="_blank">https://slack.com/intl/en-ee/privacy-policy</a>).</li>
                    </ul>

                    <h5 className="mb-3 margin-top-lg"><strong>データ主体としての利用者の権利</strong></h5>
                    <p>データ主体は常にGDPRに従い次の権利を有します：</p>
                    <ul>
                      <li>データ処理に関する情報の要求</li>
                      <li>利用者の個人データへのアクセスもしくはコピーの要求</li>
                      <li>不正確または不完全な個人データの訂正</li>
                      <li>利用者の個人データの消去の要求</li>
                      <li>利用者の個人データの処理に関する同意の取り下げ</li>
                    </ul>
                    <p>これらの権利を行使するため、データ主体はこちら（<a href="mailto:privacy@planetway.com">privacy@planetway.com</a>）へ申請するものとします。</p>

                    <h5 className="mb-3 margin-top-lg"><strong>利用者の個人データのセキュリティ</strong></h5>
                    <p>PlanetwayはPlanetwayソリューションで処理された個人データのセキュリティを確保するために、組織的、物理的および技術的なセキュリティ対策を講じています。</p>
                    <p>利用者またはその他のデータ主体の個人データまたはその他のデータの機密性、完全性、または可用性に関する不審な活動はこちらまで（<a href="mailto:security@planetway.com">security@planetway.com</a>）報告する者とします。</p>
                    <p>当社は、利用者の権利に対して高いリスクをもたらす可能性のある個人データ漏洩に関して、当社が認識している事実を速やかに利用者に通知致します。その際、漏洩の性質を明確かつ平易な表現でお知らせし、また可能性のある影響について説明致します。また、その事象が与えるデータ主体である利用者の悪影響を制限するための措置についても説明致します。</p>

                    <h5 className="mb-3 margin-top-lg"><strong>クッキー</strong></h5>
                    <p>当社は、利用者のウェブブラウジング体験の向上および一般的な非パーソナライズドされた統計情報を収集するため、当社ウェブサイト上でクッキーを使用しています。ウェブサイト上でクッキーを管理するためのオプションが提供されています。利用者がクッキーを無効にしたり拒否した場合、サイトの一部がアクセス不能または正常に機能しないことがあります。</p>
                    <p>PlanetIDクロス認証Webアプリケーションは機能的なクッキーのみを使用してPlanetIDユーザーを識別しています。その他のクッキーや使用していません。</p>

                    <h5 className="mb-3 margin-top-lg"><strong>利用者の個人データの転送</strong></h5>
                    <p>Planetwayは利用者に通知すること無く、個人データを第三者に転送、販売、または開示する事はありません。</p>
                    <p>当社は、日本、エストニア、米国にあるPlanetwayグループ会社内でデータを共有することがあります。(<Link to={langPrefix + "/corporate-profile"} className="internal-link">https://planetway.com/corporate-profile/</Link>)</p>

                    <h5 className="mb-3 margin-top-lg"><strong>利用者の個人データの開示</strong></h5>
                    <p>法律や行政機関により個人情報の開示が求められる場合があります。この場合、GDPRに従って開示が行われます。</p>

                    <h5 className="mb-3 margin-top-lg"><strong>本プライバシーノーティスの終了</strong></h5>
                    <p>本プライバシーノーティスの有効期限は無期限です。</p>

                  </div>

                </div>
                <div className="col"></div>
              </div>
            </div>
          </section>

        </main>

      </div>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query PlanetidPrivacyQuery {
    site {
      siteMetadata {
        en {
          title
          description
          keywords
        }
      }
    }
  }
`
