import React from 'react'
import { Helmet } from 'react-helmet'
import BodyClassName from 'react-body-classname'
import Link from 'gatsby-link'
import OgImage from "img/ogimg-corporate-profile.jpg"
import get from 'lodash/get'
import { graphql } from 'gatsby'
import Layout from "../components/layout"

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.description')
    const siteKeywords = get(this, 'props.data.site.siteMetadata.keywords')

    return (
      <Layout location={this.props.location}>
        <Helmet
          title={siteTitle}
          meta={[
          { name: 'description', content: siteDescription },
          { name: 'keywords', content: siteKeywords },
          { property: 'og:title', content: siteTitle },
          { property: 'og:type', content: 'website' },
          { property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
          { property: 'og:image', content: 'https://planetway.com' + OgImage },
          { property: 'og:description', content: siteDescription },
          { name: 'twitter:card', content: 'summary' },
          { name: 'twitter:site', content: '@Planetway_Tw' },
          ]}
          />
        <BodyClassName className='footer-top-dark-blue' />
        <div className="corporate-profile-header text-center text-on-dark dark-blue-gradient dia-bottom">
          <h3 className="underline">Corporate Profile</h3>
          <div className="corporate-profile-intro text-center">
            <h4>International Reach &amp; Collaboration</h4>
            <p>当社は、本社はサンノゼ（米国カリフォルニア州）、開発拠点をタリン（エストニア）、そしてビジネス開発とバックオフィス機能を東京（日本）に置いています。ICT先進国エストニアで生まれた情報連携基盤を民間向けに応用開発し、世界的にもサービス品質の高い日本でまずは実証し、その実績を持って米国本社を起点として世界展開を目指すグローバル企業です。</p>
          </div>
        </div>

        <main className="intro-box-clear">

          <div className="clear" />

          <section className="padding-top-md">
            <div className="container">
              <div className="row margin-bottom-lg">
                <div className="col">
                  <h3 className="padding-bottom-none margin-bottom-none">Planetway Corporation<strong>（米国本社）</strong></h3>
                  <p className="text-larger">2033 Gateway Pl, San Jose, CA 95110, United States</p>
                  <div className="map-responsive">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12551.985531518905!2d-121.93418127748888!3d37.37107571630416!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fcbc1ee635c81%3A0xaaa4dbbe5e029bb2!2s2033+Gateway+Pl%2C+San+Jose%2C+CA+95110%2C+USA!5e0!3m2!1sen!2sjp!4v1530858441917" width="600" height="450" frameBorder="0" style={{border: "0"}} allowFullScreen />
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="row margin-bottom-lg">
                <div className="col">
                  <h3 className="padding-bottom-none margin-bottom-none"><strong>Planetway Japan 株式会社</strong></h3>
                  <p className="text-larger">〒107-0051 東京都港区元赤坂1-2-7 赤坂Kタワー ４階</p>
                  <div className="map-responsive">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.972339142121!2d139.73274161525876!3d35.677683080195095!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188c7e8415de9b%3A0xfce039e83b2b7fff!2z6LWk5Z2CS-OCv-ODr-ODvA!5e0!3m2!1sen!2sjp!4v1558343126617!5m2!1sen!2sjp" width="600" height="450" frameBorder="0" style={{border: "0"}} allowFullScreen />
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="row margin-bottom-lg">
                <div className="col">
                  <h3 className="padding-bottom-none margin-bottom-none"><strong>Planetway Japan 株式会社　ICTセンター</strong></h3>
                  <p className="text-larger">〒135-0002 東京都江東区住吉2-12-6 ZEEBOONビル 4階</p>
                  <div className="map-responsive">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.488290748823!2d139.81163187656068!3d35.6895998725845!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188c02ba32d77d%3A0x3d5c00f9021846f0!2z44K444O8772l44OW44O844Oz44ix!5e0!3m2!1sen!2see!4v1697566127597!5m2!1sen!2see" width="600" height="450" frameBorder="0" style={{border: "0"}} allowFullScreen />
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="row margin-bottom-lg">
                <div className="col">
                  <h3 className="padding-bottom-none margin-bottom-none">Planetway Japan <strong>株式会社 京都支社</strong></h3>
                  <p className="text-larger">〒600-8118 京都府京都市下京区平居町55番地１</p>
                  <div className="map-responsive">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.972339142121!2d139.73274161525876!3d35.677683080195095!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188c7e8415de9b%3A0xfce039e83b2b7fff!2z6LWk5Z2CS-OCv-ODr-ODvA!5e0!3m2!1sen!2sjp!4v1558343126617!5m2!1sen!2sjp" width="600" height="450" frameBorder="0" style={{border: "0"}} allowFullScreen />
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d408.560295946667!2d135.7661722!3d34.9946284!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6001092c69fa7d61%3A0x79557a17e1c2815d!2sUNKNOWN%20KYOTO!5e0!3m2!1sen!2see!4v1686155096291!5m2!1sen!2see" width="600" height="450" frameBorder="0" style={{border: "0"}} allowFullScreen />
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="row margin-bottom-lg">
                <div className="col">
                  <h3 className="padding-bottom-none margin-bottom-none">Planetway Europe OÜ<strong>（エストニアオフィス）</strong></h3>
                  <p className="text-larger">Valukoja 8, Öpiku 2, Tallinn, 11415 Estonia</p>
                  <div className="map-responsive">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1014.8972572165654!2d24.8028104!3d59.4198226!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4692eb5705c562f7%3A0x2c3516941a1d9437!2s%C3%96piku+Konverentsikeskus!5e0!3m2!1sen!2sjp!4v1558585106391!5m2!1sen!2sjp" width="600" height="450" frameBorder="0" style={{border: "0"}} allowFullScreen />
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="row">
                <div className="col">
                  <Link to="/contact" className="btn internal-link">Contact Us</Link>
                </div>
              </div>
            </div>
          </section>

          <section className="dark-blue-bg text-on-dark dia-top padding-bottom-xl">
            <div className="container">
              <div className="row">
                <div className="col">
                  <h3 className="underline">Corporate Information</h3>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-lg-1" />
                <div className="col-lg-10">
                  <div className="info-table">
                    <div className="info-table-row">
                      <div className="info-table-cell text-right"><strong>CEO &amp; Founder</strong></div>
                      <div className="info-table-cell">Noriaki Hirao</div>
                    </div>
                    <div className="info-table-row">
                      <div className="info-table-cell text-right"><strong>Headquarters</strong></div>
                      <div className="info-table-cell">San Jose, USA</div>
                    </div>
                    <div className="info-table-row">
                      <div className="info-table-cell text-right"><strong>Asia & Pacific</strong></div>
                      <div className="info-table-cell">Tokyo, Japan</div>
                    </div>
                    <div className="info-table-row">
                      <div className="info-table-cell text-right"><strong>R&amp;D Department</strong></div>
                      <div className="info-table-cell">Tallinn, Tartu Estonia</div>
                    </div>
                    <div className="info-table-row">
                      <div className="info-table-cell text-right"><strong>Founded</strong></div>
                      <div className="info-table-cell">July 2015</div>
                    </div>
                    <div className="info-table-row">
                      <div className="info-table-cell info-table-cell-fw text-center">
                        <p>〒107-0051 東京都港区元赤坂1-2-7 赤坂Kタワー４階</p>
                        <p>Tel: 03-6897-9419</p>
                        <p>Email: <a href="mailto:pr@planetway.com">pr@planetway.com</a></p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-1" />
              </div>
            </div>
          </section>
        </main>
      </Layout>
    )
  }
}

export default RootIndex

export const CorporateProfileQuery = graphql`
  query CorporateProfileQuery {
    site {
      siteMetadata {
        title
        description
        keywords
      }
    }
  }
`
