import React from 'react'
import { Helmet } from 'react-helmet'
import OgImage from "img/news-placeholder.jpg"
import get from 'lodash/get'
import { graphql } from 'gatsby'

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.en.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.en.description')
    const siteKeywords = get(this, 'props.data.site.siteMetadata.en.keywords')

    return (
      <div>
        <Helmet
        	title={siteTitle}
        	meta={[
        		{ name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: 'https://planetway.com' + OgImage },
        		{ property: 'og:description', content: siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />
        <main className="no-header">

          <div className="clear"></div>

          <section>
            <div className="container">
              <div className="row">
                <div className="col"></div>
                <div className="col-sm-8 text-center">
                  <h3 className="underline text-bold">Thank you</h3>
                  <p className="subtitle-small">Thanks for contacting us.</p>
                  <p>Please bear with us and a member of staff will be getting back to you shortly.</p>

                  <div className="pr-contactinfo">
                    <p>Planetway Japan Corp.<br />
                    Public Relations<br />
                    <strong>TEL:</strong> 03-6897-9419<br />
                    <strong>MAIL:</strong> <a href="mailto:pr@planetway.com">pr@planetway.com</a></p>
                  </div>
                </div>
                <div className="col"></div>
              </div>
            </div>
          </section>

        </main>

      </div>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query ThanksQueryEn {
    site {
      siteMetadata {
        en {
          title
          description
          keywords
        }
      }
    }
  }
`
