import React from 'react'
import { Helmet } from 'react-helmet'
import OgImage from "img/ogimg-media.jpg"
import PlanetwayOnAsciiArtwork from "img/planetway-on-ascii-artwork.png"
import get from 'lodash/get'
import PressReleaseHeader from 'components/pressrelease-header-en'
import PressReleaseMedium from 'components/pressrelease-medium'
import PressReleaseSmall from 'components/pressrelease-small'
import { graphql } from 'gatsby'
import Layout from "../../components/layout"

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.en.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.en.description')
    const siteKeywords = get(this, 'props.data.site.siteMetadata.en.keywords')
    const posts = get(this, 'props.data.allContentfulPressRelease.edges')
    var n = 0;
    var o = 0;
    var p = 0;

    return (
      <Layout location={this.props.location}>
        <Helmet
        	title={siteTitle}
        	meta={[
        		{ name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: 'https://planetway.com' + OgImage },
        		{ property: 'og:description', content: siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />

        {posts.map(({ node }) => {
          n++;
          if(n === 1){return (
            <PressReleaseHeader key={node.slug} post={node} />
          )}
        })}

        <main>

          <div className="clear" />

          <section className="news-cover-stories padding-top-md padding-bottom-sm">
            <div className="container">
              <div className="row">

                {posts.map(({ node }) => {
                  o++;
                  if(o === 2 || o === 3){return (
                    <PressReleaseMedium key={node.slug} post={node} />
                  )}
                })}

              </div>
            </div>
          </section>

          <section className="news">
            <div className="container">
              <div className="row">
                <div className="col">
                  <h3 className="underline margin-bottom-lg">News</h3>
                </div>
              </div>
            </div>

            <div className="container">
              {posts.map(({ node }) => {
                p++;
                if(p >= 4){return (
                  <PressReleaseSmall key={node.slug} post={node} />
                )}
              })}
            </div>

          </section>

          <section className="padding-top-md padding-bottom-lg">
            <div className="container">
              <div className="row">
                <div className="col">
                  <img src={PlanetwayOnAsciiArtwork} className="full-width-image" />
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col text-right"><a href="http://ascii.jp/planetway/" className="btn">Visit Media Page</a></div>
              </div>
            </div>
          </section>
        </main>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query MediaQueryEn {
    site {
      siteMetadata {
        en {
          title
          description
          keywords
        }
      }
    }
    allContentfulPressRelease(
      filter: {node_locale: {eq: "en"}, publishEnglish: {eq: true}},
      sort: { fields: [publishDate], order: DESC },
      limit: 400
    ) {
      edges {
        node {
          title
          slug
          type
          excerpt {
            childMarkdownRemark {
              html
            }
          }
          content {
            childMarkdownRemark {
              html
            }
          }
          photo {
            file {
              url
            }
          }
          cover {
            file {
              url
            }
          }
          publishDate(formatString: "LL", locale: "en")
          node_locale
          publishEnglish
        }
      }
    }
  }
`
