import React from 'react'
import styles from './article-preview.module.css'
import Link from 'gatsby-link'

export default ({ post }) => {

  // photo model is optional in contentful
  let photo;
  if (post.photo && post.photo.file) {
    photo = post.photo.file.url
  }

  const to = (post.node_locale === 'en' ? '/en' : '') + `/pressreleases/${post.slug}`;

  return (
    <div className="row margin-bottom-md">
      <div className="col-md-2">
          <span className="news-category margin-bottom-xs"><strong>{post.type}</strong></span>
          <span className="news-date margin-bottom-xs">{post.publishDate}</span>
      </div>
      <div className="col-md-10">
        <h5 className="news-title"><Link to={to} className="internal-link">{post.title}</Link></h5>
      </div>
    </div>
  );
};
