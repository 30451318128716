import React from 'react'
import { Helmet } from 'react-helmet'
import BodyClassName from 'react-body-classname'
import Link from 'gatsby-link'
import OgImage from "../img/ogimg-corporate-leadership.jpg"
import get from 'lodash/get'
import TeamMemberSidebar from '../components/teammember-sidebar'
import { graphql } from 'gatsby'
import Layout from "../components/layout"

class TeamMemberTemplate extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.description')
    const siteKeywords = get(this, 'props.data.site.siteMetadata.keywords')
    const member = get(this.props, 'data.contentfulTeamMember')
    const members = get(this, 'props.data.allContentfulTeamMember.edges')
    if (member.node_locale === 'en') {
      // if this member is English, only show English team members on the side
      members.filter(function (member) {
        return member.node_locale === 'en' && member.publishEnglish;
      });
    }

    // photo model is optional in contentful
    let profilePicture;
    let ogimg;
    if (member.profilePicture && member.profilePicture.file) {
      profilePicture = member.profilePicture.file.url;
      ogimg = profilePicture;
    } else {
      ogimg = 'https://planetway.com' + OgImage;
    }

    let america;
    if (member.america) {
      america = <span className="region-icon us" />
    }

    let japan;
    if (member.japan) {
      japan = <span className="region-icon jp" />
    }

    let estonia;
    if (member.estonia) {
      estonia = <span className="region-icon es" />
    }

    return (
      <Layout location={this.props.location}>
        <Helmet
        	title={`${member.name} - ${siteTitle}`}
        	meta={[
        		{ name: 'description', content: member.name + ' - ' + siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: member.name + ' - ' + siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: ogimg },
        		{ property: 'og:description', content: member.name + ' - ' + siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />

        <BodyClassName className='team-member' />

        <main className="no-header">

          <div className="clear" />

          <section>
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="member">
                    <div className="d-sm-flex">
                      <div className="profile-pic mb-3 mr-3" style={{backgroundImage: "url(" + profilePicture + "), linear-gradient(#000, #000)", backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "102% auto"}}><span></span></div>
                      <div className="member-details">
                        <h3 className="underline text-bold mb-3">{member.name}</h3>
                        <h4>{member.position}</h4>
                        {america} {japan} {estonia}

                        <div className="clear mb-4" />

                        <div
                          dangerouslySetInnerHTML={{
                            __html: member.profile.childMarkdownRemark.html,
                          }}
                        />
                        </div>
                      </div>
                    </div>
                </div>
                <div className="col-lg-4">
                  <aside className="sidebar">
                    <h4>Board of Directors</h4>
                    <div>
                      {members.map(({ node }) => {
                        if (node.type === "Board") {
                          return (
                              <TeamMemberSidebar key={node.slug} member={node} />
                          )
                        }
                      })}
                      <Link to={member.node_locale === 'en' ? "/en/corporate-leadership" : "/corporate-leadership"} className="btn internal-link">See more</Link>
                    </div>
                  </aside>
                  <div className="region-legend">
                    <div className="legend-line"><span className="region-icon jp" /> 日本の取締役</div>
                    <div className="legend-line"><span className="region-icon us" /> アメリカの取締役</div>
                    <div className="legend-line"><span className="region-icon es" /> エストニアの取締役</div>
                  </div>
                </div>
              </div>
            </div>
          </section>

        </main>
      </Layout>
    )
  }
}

export default TeamMemberTemplate

// don't show this entry in the bottom press releases list
export const pageQuery = graphql`
  query TeamMemberBySlug($slug: String!, $node_locale: String!) {
    site {
      siteMetadata {
        title
        description
        keywords
      }
    }
    contentfulTeamMember(slug: { eq: $slug }, node_locale: { eq: $node_locale }) {
      name
      slug
      position
      excerpt {
        childMarkdownRemark {
          html
        }
      }
      profile {
        childMarkdownRemark {
          html
        }
      }
      profilePicture {
        file {
          url
        }
      }
      america
      japan
      estonia
      type
      publishDate(formatString: "LL", locale: $node_locale)
      node_locale
      publishEnglish
    }
    allContentfulTeamMember(
      filter: {node_locale: {eq: $node_locale}, publishEnglish: {eq: true}},
      sort: { fields: [publishDate], order: ASC },
      limit: 400
    ) {
      edges {
        node {
          name
          slug
          position
          profilePicture {
            file {
              url
            }
          }
          excerpt {
            childMarkdownRemark {
              html
            }
          }
          profile {
            childMarkdownRemark {
              html
            }
          }
          america
          japan
          estonia
          type
          publishDate(formatString: "LL", locale: $node_locale)
          node_locale
          publishEnglish
        }
      }
    }
  }
`
