import React from 'react'
import { Helmet } from 'react-helmet'
import OgImage from "img/ogimg-planetid.jpg"
import get from 'lodash/get'
import { graphql } from 'gatsby'

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.en.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.en.description')
    const siteKeywords = get(this, 'props.data.site.siteMetadata.en.keywords')

    return (
      <div>
        <Helmet
        	title={siteTitle}
        	meta={[
        		{ name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: 'https://planetway.com' + OgImage },
        		{ property: 'og:description', content: siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />
        <main>

          <section className="pt-5">
            <div className="container">
              <div className="row">
                <div className="col"/>
                <div className="col-sm-10">
                  <h4 className="underline">PlanetID FAQ</h4>

                  <div className="pid-faq-item mb-5">
                    <h5 className="mb-3">1. What is PlanetID?</h5>
                    <p>PlanetID is a new convenient solution for a personal authentication. With PlanetID you can use different electronic
                    services on your smartphone or web browser securely and efficiently.</p>
                    <p><strong className="medium">With PlanetID you can do following:</strong></p>
                    <ul>
                      <li>All electronic services, which are using PlanetID can be accessed with PlanetID PIN code. You don't need to memorize different usernames and passwords for each app or web service.</li>
                      <li>Once you have verified your PlanetID, you can send your personal information to a service that is using Planet ID with a single click. This eliminates the need to enter your personal information such as name, address or phone number manually every time you register for a new electronic service.</li>
                      <li>With PlanetID's you can sign contracts online. By using digital signature you can sign contracts electronically at anytime and anywhere.  And there is no need for seal anymore.</li>
                      <li>You can approve or deny the provision of personal data for each electronic service. If you approve to provide your personal data, you can get various benefits from the data consumer in return.</li>
                    </ul>
                  </div>

                  <div className="pid-faq-item mb-5">
                    <h5 className="mb-3">2. Why do I need two PINs (Authcode and Signcode)?</h5>
                    <p>PlanetID uses two different PINs:</p>
                    <p><strong className="medium">Authcode</strong> is used for authentication. When you register or log into your e-service account, you are asked to insert your Authcode.</p>
                    <p><strong className="medium">Signcode</strong> is used for digital signature. When you sign a document or give a consent to use your private data  you are asked to enter your Signcode.</p>
                  </div>

                  <div className="pid-faq-item mb-5">
                    <h5 className="mb-3">3. How much does it cost to use PlanetID?</h5>
                    <p>PlanetID app is free to download and use. PlanetID transactions are paid by companies who allow you log into their electronic services and digitally sign documents by using PlanetID app.</p>
                  </div>

                  <div className="pid-faq-item mb-5">
                    <h5 className="mb-3">4. What is verification? And why is it necessary?</h5>
                    <p>When you verify your PlaneID, then your personal data bound to your PlanetID becomes verified by a third-party trusted authority. Without verification, your PlanetID is kind of anonymous. It is not bound to your data in physical world (name, address, phone number etc). Verifying your PlantID enables you to use electronic services even more conveniently. Once you have verified your PlanetID, you are no longer required to confirm your identity every time you register for a new electronic service. You can send your personal information to a service with a single button click. <strong className="medium">Verifcation function will be available soon.</strong></p>
                  </div>

                  <div className="pid-faq-item mb-5">
                    <h5 className="mb-3">5. How do I verify my PlanetID?</h5>
                    <p>You can verify your PlanetID in the organization, that provides identity verification service. Identity verification is based on the "Act on Prevention of Transfer of Criminal Proceeds". The procedure itself is quick and easy. You just need to be able to authenticate your PlanetID with your personal ID. <strong className="medium">Verifcation function will be available soon.</strong></p>
                  </div>

                  <div className="pid-faq-item mb-5">
                    <h5 className="mb-3">6. Can I have multiple PlanetIDs on one smart device?</h5>
                    <p>One PlanetID (number) is issued for one smart device. It is not possible to issue multiple PlanetIDs for one device.</p>
                  </div>

                  <div className="pid-faq-item mb-5">
                    <h5 className="mb-3">7. Can I have one PlanetID on several smart devices?</h5>
                    <p>Currently it is not possible to use one PlanetID (number) with multiple smart devices.</p>
                  </div>

                  <div className="pid-faq-item mb-5">
                    <h5 className="mb-3">8. Can I have different PlanetIDs on my different devices?</h5>
                    <p>You can have multiple PlanetID on different devices only while they are not (yet) verified. PlanetID verification is binding your personal data to a single PlanetID, so you have to choose one PlanetID that becomes verified and either discard others or leave them unverified.</p>
                  </div>

                  <div className="pid-faq-item mb-5">
                    <h5 className="mb-3">9. I bought a new phone. How can I migrate my PlanetID from my old device to a new one?</h5>
                    <p>You need both your old and new device for this procedure. In your old device open "Menu page" and choose "Migrate to a new device". On your new device install new PlanetID app, but instead of "Begin Activation" choose "Migrate existing PlanetID" and follow the directions.</p>
                  </div>

                  <div className="pid-faq-item mb-5">
                    <h5 className="mb-3">10. My smartphone got stolen or lost. What should I do?</h5>
                    <p>If your Planet ID has not been verified, then your PlanetID cannot be permanently locked. For your safety, please log in to your e-service site and un-link your PlanetID.</p>
                  </div>

                  <div className="pid-faq-item mb-5">
                    <h5 className="mb-3">11. What is PIN?</h5>
                    <p>PINs are your personal identification numbers. PlanetID has two PINs: Authcode (4 digits) and Signcode (6 digits)</p>
                    <p><strong className="medium">Authcode</strong> is used for authentication. When you register or log into your e-service account, you are asked to insert your Authcode.</p>
                    <p><strong className="medium">Signcode</strong> is used for digital signature. When you sign a document or give a consent to use your private data  you are asked to enter your Signcode.</p>
                  </div>

                  <div className="pid-faq-item mb-5">
                    <h5 className="mb-3">12. I inserted wrong PIN too many times and now my PlanetID is locked.</h5>
                    <p>If you enter your PIN code incorrectly multiple times, PlanetID will be locked and you will not be able to use the PlanetID app for a certain period of time. After this PlanetID is unlocked and you can use it again.</p>
                  </div>

                  <div className="pid-faq-item mb-5">
                    <h5 className="mb-3">13. How can I change my PINs?</h5>
                    <p>You can change your PINs from the PlanetID app menu. Changing the PIN requires entering the old PIN and creating a new one.</p>
                  </div>

                  <div className="pid-faq-item mb-5">
                    <h5 className="mb-3">14. I forgot my PINs. What should I do?</h5>
                    <p>If your PlanetID is not verified by identity verification provider, then you cannot reset your PINs. If you cannot remember your PINs, please delete your PlanetID by selecting "Delete PlanetID" from your PlanetID app menu and create a new one. If you already have some e-services linked to your old PlanetID, then log in with your electronic service username and password, un-link your old PlanetID and re-link with new one.</p>
                  </div>
                </div>
                <div className="col"/>
              </div>
            </div>
          </section>

        </main>

      </div>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query PlanetidFaqQueryEn {
    site {
      siteMetadata {
        en {
          title
          description
          keywords
        }
      }
    }
  }
`
