import React from 'react'
import { Helmet } from 'react-helmet'
import Link from 'gatsby-link'
import OgImage from "img/ogimg-planetcross.jpg"
import PartnerLogoHousall from "img/partner-logo-housall.png"
import get from 'lodash/get'
import { graphql } from 'gatsby'
import Layout from "../../components/layout"

class RootIndex extends React.Component {
  render() {
    const langPrefix = this.props.location.pathname.match(/^\/en/) ? '/en' : '';

    const siteTitle = get(this, 'props.data.site.siteMetadata.en.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.en.description')
    const siteKeywords = get(this, 'props.data.site.siteMetadata.en.keywords')

    return (
      <Layout location={this.props.location}>
        <Helmet
        	title={siteTitle}
        	meta={[
        		{ name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: 'https://planetway.com' + OgImage },
        		{ property: 'og:description', content: siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />
        <main className="no-header">

          <div className="clear" />

          <section className="padding-top-lg">
            <div className="container">
              <div className="row">
                <div className="col" />
                <div className="col-sm-10"><div className="partner-logo"><img src={PartnerLogoHousall} /></div>
                  <h4 className="underline">Housall</h4>
                  <h5 className="margin-bottom-sm">We are developing a new B2B2C business model for family living, via our platform that enables unparalleled efficiency and convenience that across multiple industries and business categories.</h5>
                  <p>We are gathering specialists and professionals in "house / home" and "living / life" to build a "living life service platform" that can be utilized by various industries. We will provide consumers with the convenience of one-stop services, and businesses with a new foundation of increased efficiency and added value. Rather than providing uniform services, we devise highly novel services and business models, with the aim to improve and revitalize the attractiveness of companies and local communities by providing people with a safer, more comfortable and convenient lifestyle.</p>
                  <h5 className="margin-top-md margin-bottom-sm">Key Competence Areas</h5>
                  <p>Housing and living support for consumers. BPO, brokerage, matching and merchandise sales for housing, real estate and living services. Consulting on business creation, management innovation and operational efficiency. Marketing, advertising, CRM agency.</p>
                  <h5 className="margin-top-md margin-bottom-sm">Case Study</h5>
                  <p>HOUSALL is a key player in the development of housing-related IT infrastructure business in Japan, and an important solution partner for many housing-related companies (housing / energy / railroad, etc.). For residential customers we provide long term services such as guarantor, and products and services to respond to crime and disaster prevention. By realizing efficiency in the housing industry as a whole and contributing deeply to construction and logistics optimization (which can be a measure to solve social issues of the decreasing working population), we aim to realize a platform that transcends industries and business types.</p>
                  <p>We will leverage our strengths in business, corporate merchandise, service matching, and new business model planning while promoting the use of PLANETWAY as information sharing infrastructure.</p>
                  {/* <p>For more information: <a href="http://www.housall.co.jp/" target="_blank">http://www.housall.co.jp/</a></p> */}
                  <p className="text-center mt-5"><Link to={langPrefix + "/pdsa-partners"} className="btn internal-link">Back</Link></p>
                </div>
                <div className="col" />
              </div>
            </div>
          </section>

        </main>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query PdsaPartnersHousallQueryEn {
    site {
      siteMetadata {
        en {
          title
          description
          keywords
        }
      }
    }
  }
`
