import React from 'react'
import { Helmet } from 'react-helmet'
import Link from 'gatsby-link'
import OgImage from "img/ogimg-planetcross.jpg"
import get from 'lodash/get'
import { graphql } from 'gatsby'
import Layout from "../../components/layout"

class RootIndex extends React.Component {
  render() {
    const langPrefix = this.props.location.pathname.match(/^\/en/) ? '/en' : '';

    const siteTitle = get(this, 'props.data.site.siteMetadata.en.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.en.description')
    const siteKeywords = get(this, 'props.data.site.siteMetadata.en.keywords')

    return (
      <Layout location={this.props.location}>
        <Helmet
        	title={siteTitle}
        	meta={[
        		{ name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: 'https://planetway.com' + OgImage },
        		{ property: 'og:description', content: siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />

        <div className="page-header pwdsa-header">
          <div className="container text-on-dark">
            <div className="row">
              <div className="col-sm-6">
                <div className="pwdsa-logo margin-bottom-lg"/>
                <h3>A world where individuals control their personal data</h3>
                <p>Personal Data Sovereignty Alliance for Businesses and Organizations</p>
                {/* <Link to={langPrefix + "/contact"} className="btn internal-link">Advance Application</Link> */}
              </div>
              <div className="col-sm-6"><div className="pwdsa-pid-px-artwork"/></div>
            </div>
          </div>
        </div>

        <main>

          <div className="clear"/>

          <section>
            <div className="container">
              <div className="row">
                <div className="col"/>
                <div className="col-lg-10 text-center mobile-text-left">
                  <h4 className="underline">About Planetway® Data Sovereignty Alliance (PDSA)</h4>
                  <h5 className="padding-bottom-sm">We have launched a global business alliance for companies that promotes the realization of personal data sovereignty as set forth in our vision.</h5>
                  <p className="padding-bottom-sm">PDSA helps companies solve issues by providing solutions and use case content for enterprise issues such as DX, GDPR, and information security.
                  By providing content that supports business and technology, we help partner companies build, market, and sell our products and services.</p>
                  {/* <Link to={langPrefix + "/contact"} className="btn internal-link">Advance Application</Link> */}
                </div>
                <div className="col"/>
              </div>
            </div>
          </section>

          <section className="pwdsa-infini-blur-bg text-on-dark">
            <div className="container">
              <div className="row padding-top-md">
                <div className="col-sm-4 padding-bottom-md">
                  <h4 className="icon-title icon-shield-check">Empower</h4>
                  <p>Return full data sovereignty back to the individual.</p>
                </div>
                <div className="col-sm-4 padding-bottom-md">
                  <h4 className="icon-title icon-arrows-out">Ethics</h4>
                  <p>Return the Internet back to the original principles on which it was founded.</p>
                </div>
                <div className="col-sm-4 padding-bottom-md">
                  <h4 className="icon-title icon-scan-one">Unify</h4>
                  <p>Integrated ID for all human beings and all things.</p>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4 padding-bottom-md">
                  <h4 className="icon-title icon-connect">Evolve</h4>
                  <p>Revolutionise the free market and bring about a better form of capitalism.</p>
                </div>
                <div className="col-sm-4 padding-bottom-md">
                  <h4 className="icon-title icon-arrows-out">Protect</h4>
                  <p>Avoid a future scenario where everything is controlled by AI.</p>
                </div>
                <div className="col-sm-4 padding-bottom-md">
                  <h4 className="icon-title icon-scan-one">Optimize</h4>
                  <p>Expand the possibilities of what humans can do through the power of technology.</p>
                </div>
              </div>
            </div>
          </section>

          {/*<section>
            <div className="container">
              <div className="row">
                <div className="col"></div>
                <div className="col-lg-10 text-center mobile-text-left">
                  <h4 className="underline">Become a Member</h4>
                  <p>Contact us today and we will assess your eligibility for membership.</p>
                  <Link to={langPrefix + "/contact"} className="btn internal-link">Apply for membership</Link>
                </div>
                <div className="col"></div>
              </div>
            </div>
          </section>*/}

        </main>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query PwdsaQueryEn {
    site {
      siteMetadata {
        en {
          title
          description
          keywords
        }
      }
    }
  }
`
