import React from 'react'
import { Helmet } from 'react-helmet'
import OgImage from "img/ogimg-planetcross.jpg"
import get from 'lodash/get'
import { graphql } from 'gatsby'
import Layout from "../../components/layout"

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.en.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.en.description')
    const siteKeywords = get(this, 'props.data.site.siteMetadata.en.keywords')

    return (
      <Layout location={this.props.location}>
        <Helmet
        	title={`PlanetCross - ${siteTitle}`}
        	meta={[
        		{ name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: 'PlanetCross - ' + siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: 'https://planetway.com' + OgImage },
        		{ property: 'og:description', content: siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />

        <div className="page-header planetcross-header dia-bottom">
          <div className="container text-on-dark text-left">
            <div className="row">
              <div className="col-md-6">
                <div className="hp-slider-planetcross-logo-mark" />
              </div>
              <div className="col-md-6">
                <h2><span className="text-light">Planet</span>Cross</h2>
                <strong className="subtitle-small">Cross-Industry Secure Data Access Platform</strong>
                <p>PlanetCross is an innovative data access platform for the private sector, adapted from the world renowned Estonian e-government X-Road data access system.</p>
              </div>
            </div>
          </div>
        </div>

        <main>

          <div className="clear" />

          <section className="padding-bottom-none">
            <div className="container">
              <div className="row">
                <div className="col-lg-2" />
                <div className="col-lg-8 text-center mobile-text-left">
                  <h3 className="underline">Cross-Industry Secure Data Access Platform</h3>
                  <p>PlanetCross supports various RDBs (Relational Databases). It is a secure solution for exchanging confidential data. It is simple to set up this data access
                  platform with other companies over the internet, without making major changes to existing systems and databases.</p>
                </div>
                <div className="col-lg-2" />
              </div>
            </div>
          </section>

          <section className="padding-bottom-none">
            <div className="container">
              <div className="row">
                <div className="col-md text-center">
                  <div className="planetcross-1 block-center" />
                  <p>Customizing the 20 years-proven infrastructure of Estonia’s e-government for the private sector</p>
                </div>
                <div className="col-md text-center">
                  <div className="planetcross-2 block-center" />
                  <p>Secure Data Exchange</p>
                </div>
                <div className="col-md text-center">
                  <div className="planetcross-3 block-center" />
                  <p>Constructing a large scale decentralized database network</p>
                </div>
                <div className="col-md text-center">
                  <div className="planetcross-4 block-center" />
                  <p>Unlimited scalability due to a multi-protocol that supports a wide range of platforms</p>
                </div>
                <div className="col-md text-center">
                  <div className="planetcross-5 block-center" />
                  <p>Beyond API</p>
                </div>
              </div>
            </div>
          </section>

          <section className="padding-bottom-sm">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h3 className="underline">Core Technologies</h3>
                </div>
              </div>
              <div className="row">
                <div className="col-md">
                  <div className="planetcross-availability block-center" />
                  <h4>Availability</h4>
                  <p>Available on-premises or in the cloud and for any type of database. An"RDB Adapter" module can absorb and standardize differences in RDB. Simple GUI-based settings can control the amount of data published.</p>
                </div>
                <div className="col-md">
                  <div className="planetcross-encryption block-center" />
                  <h4>Encryption</h4>
                  <p>Highly effective TLS encryption guarantees security during data exchange.</p>
                </div>
                <div className="col-md">
                  <div className="planetcross-traceability block-center" />
                  <h4>Traceability</h4>
                  <p>A Certificate Authority (CA) and a Time Stamp Authority (TSA) tracks who views and/or acquires data and the time at which it happens.</p>
                </div>
              </div>
            </div>
          </section>

          {/* <section style={{backgroundColor: "#040D3D"}} className="padding-top-sm padding-bottom-sm">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="planetcross-sys-diagram"></div>
                </div>
              </div>
            </div>
          </section> */}

          {/* <section className="padding-bottom-none">
            <div className="container">
              <div className="row">
                <div className="col">
                  <h3 className="underline">Related Whitepapers</h3>
                </div>
              </div>

              <div className="row">
                <div className="col-lg">
                  <a href="#/" className="download-col">
                    <span className="download-icon orange-pink"></span>
                    <h5 className="download-title">海外出張向けグローバルSIM　TAKT法人向けパッケージ詳細資料ダウンロード</h5>
                  </a>
                </div>
                <div className="col-lg">
                  <a href="#/" className="download-col">
                    <span className="download-icon blue-purple"></span>
                    <h5 className="download-title">IoT実践における重要な3本の柱とは？ IoTイニシアティブ2015講演「IoT時代を加速させるグローバルスタートアップの存在価値」</h5>
                  </a>
                </div>
                <div className="col-lg">
                  <a href="#/" className="download-col">
                    <span className="download-icon blue-green"></span>
                    <h5 className="download-title">世界約200ヵ国以上で利用可能なグローバルSIMカード提供に関する記者発表会資料ダウンロード</h5>
                  </a>
                </div>
              </div>
            </div>
          </section> */}

          <section>
            <div className="container">
              <div className="row">
                <div className="col">
                  <h3 className="underline">Awards</h3>
                </div>
              </div>

              <div className="row">
                <div className="col-sm">
                  <div className="planetcross-award-ibmbluehub" />
                  <div className="text-center"><p>December 17, 2016</p></div>
                  <ul className="list-green-check">
                    <li><h5>Automotive</h5><p>Grand Prize/ Special Jury Prize (Nissan Motor Corporation)</p></li>
                    <li><h5>Healthcare</h5><p>Special Jury Prize (Dentsu Digital Corporation)</p></li>
                  </ul>
                </div>
                <div className="col-sm">
                  <div className="planetcross-award-efma" />
                  <div className="text-center"><p>May 02, 2017</p></div>
                  <ul className="list-green-check">
                    <li><h5>Efma–Accenture Innovation in Insurance Awards</h5><p>Grand Prize winner: Tokio Marine & Nichido Fire Insurance in collaboration with Planetway</p></li>
                  </ul>
                </div>
                <div className="col-sm">
                  <div className="planetcross-award-apec" />
                  <div className="text-center"><p>October 30, 2017</p></div>
                  <ul className="list-green-check">
                    <li><h5>Gartner Financial Services Eye on Innovation Awards for the Asia Pacific region 2017</h5><p>Finalist: Tokio Marine & Nichido Fire Insurance in collaboration with Planetway</p></li>
                  </ul>
                </div>
                <div className="col-sm">
                  <a style={{color: "inherit"}} href="https://planetway.com/en/pressreleases/2022-planetway/">
                    <div className="planetcross-award-cert" />
                    <div className="text-center"><p>April 06, 2022</p></div>
                    <ul className="list-green-check">
                      <li><h5>Top 10 JAPANESE STARTUPS 2022</h5>
                      <p>Planetway is selected as one of the 10 leading startups in Japan by StartUp City magazine</p></li>
                    </ul>
                  </a>
                </div>
              </div>
            </div>
          </section>
        </main>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query PlanetCrossQueryEn {
    site {
      siteMetadata {
        en {
          title
          description
          keywords
        }
      }
    }
  }
`
