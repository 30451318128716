import React from 'react'
import { Helmet } from 'react-helmet'
import BodyClassName from 'react-body-classname'
import Link from 'gatsby-link'
import OgImage from "../img/ogimg-media.jpg"
import get from 'lodash/get'
import PressReleaseSidebar from '../components/pressrelease-sidebar'
import LanguageLink from 'components/language-link'
import { graphql } from 'gatsby'
import Layout from "../components/layout"

class PressReleaseTemplate extends React.Component {
  // new release 07.08.2024 v7
  render() {
    const post = get(this.props, 'data.contentfulPressRelease')
    var posts = get(this, 'props.data.allContentfulPressRelease.edges')

    let isEn = post.node_locale === 'en';
    const siteTitle       = isEn ? get(this, 'props.data.site.siteMetadata.en.title')       : get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = isEn ? get(this, 'props.data.site.siteMetadata.en.description') : get(this, 'props.data.site.siteMetadata.description')
    const siteKeywords    = isEn ? get(this, 'props.data.site.siteMetadata.en.keywords')    : get(this, 'props.data.site.siteMetadata.keywords')
    
    if (isEn) {
      // if this member is English, only show English news on the side
      // we cannot put this filter into graphql because we don't need this filter if node_locale='ja'
      posts = posts.filter(function (p) {
        return p.node.publishEnglish === true;
      });
    }

    // cover model is optional in contentful
    let cover;
    let ogimg;
    if (post.cover && post.cover.file) {
      cover = post.cover.file.url;
      ogimg = cover;
    } else {
      ogimg = 'https://planetway.com' + OgImage;
    }

    return (
      <Layout location={this.props.location}>
        <Helmet
        	title={`${post.title} - ${siteTitle}`}
        	meta={[
        		{ name: 'description', content: post.title + ' - ' + siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: post.title + ' - ' + siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: ogimg },
        		{ property: 'og:description', content: post.title + ' - ' + siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />

        <BodyClassName className='news-article' />

        <div className="page-header media-header dia-bottom" style={{backgroundImage: "linear-gradient(rgba(0,0,255,0.5), rgba(0,0,0,0.5)), url(" + cover + "), linear-gradient(#000, #000)"}}>
          <div className="container text-on-dark text-left">
            <div className="row">
              <div className="col-lg">
                <span className={`news-category ${post.type} margin-bottom-md`} />
                <h3>{post.title}</h3>
                <span className="news-date margin-bottom-sm">{post.publishDate}</span>
                <div
                  dangerouslySetInnerHTML={{
                    __html: post.excerpt.childMarkdownRemark.html,
                  }}
                />
              </div>
              {/* <div className="col-lg-6" /> */}
            </div>
          </div>
        </div>

        <main>
          { post.node_locale === 'en' ?
            // if we're showing English now, link to Japanese. Japanese pressrelease always exists
            <LanguageLink lang='ja' to={this.props.location.pathname.replace(/^\/en/,'')} /> :
            post.publishEnglish ?
            // if we're showing Japanese now, and if English is published, link to English version
            <LanguageLink lang='en' to={this.props.location.pathname.replace(/^\//,'/en/')} /> :
            // otherwise link to /en/media because there's no English version of this press release.
            <LanguageLink lang='en' to='/en/media' />
          }

          <div className="clear" />

          <section className="article-body">
            <div className="container">
              <div className="row">
                <div className="col-sm-8">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: post.content.childMarkdownRemark.html,
                    }}
                  />
                  <div className="pr-contactinfo">
                    <h5 className="text-bold">PR Contact</h5>
                    <p>Planetway Corporation<br />
                      Public Relations Team<br />
                      <strong>TEL:</strong> +81-3-6897-9419<br />
                      <strong>MAIL:</strong> <a href="mailto:pr@planetway.com">pr@planetway.com</a>
                    </p>
                  </div>
                </div>
                <div className="col-sm-4">
                  <aside className="sidebar">
                    <h4>News</h4>
                    <div>
                    {posts.map(({ node }) => {
                      return (
                        <PressReleaseSidebar key={node.slug} post={node} />
                      )
                    })}
                    <Link to={post.node_locale === 'en' ? "/en/media" : "/media"} className="btn internal-link">See more</Link>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </section>

        </main>

      </Layout>
    )
  }
}

export default PressReleaseTemplate

// don't show this entry in the bottom press releases list
export const pageQuery = graphql`
  query PressReleaseBySlug($slug: String!, $node_locale: String!) {
    site {
      siteMetadata {
        title
        description
        keywords
        en {
          title
          description
          keywords
        }
      }
    }
    contentfulPressRelease(slug: { eq: $slug }, node_locale: { eq: $node_locale }) {
      title
      slug
      type
      excerpt {
        childMarkdownRemark {
          html
        }
      }
      content {
        childMarkdownRemark {
          html
        }
      }
      photo {
        file {
          url
        }
      }
      cover {
        file {
          url
        }
      }
      publishDate(formatString: "LL", locale: $node_locale)
      node_locale
      publishEnglish
    }
    allContentfulPressRelease(
      filter: { node_locale: { eq: $node_locale }, slug: { ne: $slug } },
      sort: { fields: [publishDate], order: DESC },
      limit: 10
    ) {
      edges {
        node {
          title
          slug
          type
          excerpt {
            childMarkdownRemark {
              html
            }
          }
          content {
            childMarkdownRemark {
              html
            }
          }
          photo {
            file {
              url
            }
          }
          publishDate(formatString: "LL", locale: $node_locale)
          node_locale
          publishEnglish
        }
      }
    }
  }
`
