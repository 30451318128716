import React from 'react'
import styles from './article-preview.module.css'
import Link from 'gatsby-link'

export default ({ member }) => {

  const to = (member.node_locale === 'en' ? '/en' : '') + `/teammembers/${member.slug}`;

  return(
    <div className="sidebar-row">
      <Link to={to} className="internal-link">
        <strong>{member.name}</strong>
        {member.position}
      </Link>
    </div>
  );
}
