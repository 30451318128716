import React from 'react'
import { Helmet } from 'react-helmet'
import Link from 'gatsby-link'
import OgImage from "img/ogimg-planetcross.jpg"
import PartnerLogoIcefire from "img/partner-logo-icefire.png"
import get from 'lodash/get'
import { graphql } from 'gatsby'
import Layout from "../components/layout"

class RootIndex extends React.Component {
  render() {
    const langPrefix = this.props.location.pathname.match(/^\/en/) ? '/en' : '';

    const siteTitle = get(this, 'props.data.site.siteMetadata.en.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.en.description')
    const siteKeywords = get(this, 'props.data.site.siteMetadata.en.keywords')

    return (
      <Layout location={this.props.location}>
        <Helmet
        	title={siteTitle}
        	meta={[
        		{ name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: 'https://planetway.com' + OgImage },
        		{ property: 'og:description', content: siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />
        <main className="no-header">

          <div className="clear" />

          <section className="padding-top-lg">
            <div className="container">
              <div className="row">
                <div className="col" />
                <div className="col-sm-10"><div className="partner-logo"><img src={PartnerLogoIcefire} /></div>
                  <h4 className="underline">Icefire OÜ</h4>
                  <h5 className="margin-bottom-sm"><strong>デジタルバンキングとフィンテックソリューションは</strong>Icefire<strong>設立当初から、我々の得意分野です。</strong></h5>
                  <p>Icefireは、銀行およびフィンテック企業向けの革新的なテクノロジーを設計および構築しています。 同社は複雑なプロセスを簡素化および自動化し、退屈な作業を人間にとって意味のある作業に変革させます。</p>
                  <p>Icefireのストーリーは1990年代に始まりました。当時、我々の中核チームのメンバーが、後にSwedbankに買収された革新的で、セキュアなインターネットバンキングであるHansabankの構築で大きく貢献しました。 それ以来会社は大きく進化しましたが、当初から持っている私たちの価値観は変わっていません。我々のビジョン（常に革新的であること、軽量化されたプロセス、迅速な意思決定、ゼロ官僚主義、従業員に対する完全な信頼）として残っています。</p>
                  <p>2002年以来、Icefireは主に金融セクターのクライアント向けに技術ソリューションを設計および構築してきました。</p>
                  <h5 className="margin-top-md margin-bottom-sm"><strong>主な得意分野</strong></h5>
                  <p>ビジネス/インダストリー4.0/金融に関するソリューション、e-Tax、e-バンキング</p>
                  <h5 className="margin-top-md margin-bottom-sm"><strong>ケーススタディ</strong></h5>
                  <p>Icefireは、エストニアの税関を世界で最も近代的かつ効率的な税務当局の1つに変えるのに貢献しています。 これは、エストニアの電子政府サービスとシステムの推進に貢献した最初のプロジェクトの1つでした。 エストニアの新しいe-Taxシステムとe-Customsシステムは、現時点ではIcefireの新しい代表的な事例となります。 毎年、エストニアでのすべての税申告の約98％は、エストニア税関税委員会によって設定された電子税申告システムであるe-Taxを介して電子的に提出されます。 エストニアのオンライン税および通関ソリューションは、世界で最も先進的なものと考えられています。</p>
                  <p>詳細はこちら: <a href="https://www.icefire.ee/" target="_blank">https://www.icefire.ee/</a></p>
                  <p className="text-center mt-5"><Link to={langPrefix + "/pdsa-partners"} className="btn internal-link">一覧に戻る</Link></p>
                </div>
                <div className="col" />
              </div>
            </div>
          </section>

        </main>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query PdsaPartnersIcefireQuery {
    site {
      siteMetadata {
        en {
          title
          description
          keywords
        }
      }
    }
  }
`
