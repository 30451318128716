import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'

import PidUg1JaHome from "img/1.png"
import PidUg2JaCreateAuthCode from "img/2.png"
import PidUg3JaCreateAuthCode from "img/3.png"
import PidUg4JaCreateAuthCode from "img/4.png"
import PidUg5JaCreateAuthCode from "img/5.png"
import PidUg7JaCreateAuthCode from "img/6.png"
import PidUg8JaCreateAuthCode from "img/7.png"
import PidUg75JaCreateAuthCode from "img/7-5.png"
import PidUg9JaCreateAuthCode from "img/8.png"

import PidUg11JaLinkButton from "img/pid-ug-11-ja-linkbutton.png"
import PidUg12JaHome from "img/10.png"
import PidUg13JaAuthRequest from "img/11.png"
import PidUg14JaAuthCode from "img/12.png"
import PidUg15JaAuthenticated from "img/13.png"

import PidUg16JaSigninButton from "img/pid-ug-16-ja-signinbutton.png"
import PidUg17JaHome from "img/15.png"
import PidUg18JaAuthRequest from "img/16.png"
import PidUg19JaAuthCode from "img/17.png"
import PidUg20JaAuthenticated from "img/18.png"

import PidUg21JaSignatureButton from "img/pid-ug-21-ja-signaturebutton.png"
import PidUg22JaSignRequest from "img/20.png"
import PidUg23JaSignCode from "img/21.png"
import PidUg24JaSigned from "img/22.png"

import PidUg25JaConsentButton from "img/pid-ug-25-ja-consentbutton.png"
import PidUg26JaConsentRequest from "img/24.png"
import PidUg27JaSignCode from "img/25.png"
import PidUg28JaSigned from "img/26.png"

import OgImage from "img/ogimg-planetid.jpg"
import get from 'lodash/get'

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.description')
    const siteKeywords = get(this, 'props.data.site.siteMetadata.keywords')

    return (
      <div>
        <Helmet
        	title={siteTitle}
        	meta={[
        		{ name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: 'https://planetway.com' + OgImage },
        		{ property: 'og:description', content: siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />
        <main>

          <section className="pt-5">
            <div className="container">
              <div className="row">
                <div className="col-md-12"><h4 className="underline">PlanetID User Guide</h4></div>
              </div>
              <div className="row">
                <div className="col-md-12"><h5 className="mb-3">PlanetID<strong> アプリの準備</strong></h5></div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-center"><img src={PidUg1JaHome} className="threequarter-width-image mb-3" /></div>
                    <p><strong className="medium">1. PlanetID </strong><strong>を発行する</strong></p>
                    <p>PlanetIDアプリを開き「PlanetIDを発行する」ボタンをタップしてください。</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-center"><img src={PidUg2JaCreateAuthCode} className="threequarter-width-image mb-3" /></div>
                    <p><strong className="medium">2. </strong><strong>認証コードを設定</strong></p>
                    <p>認証コードに４桁の数字を設定してください。</p>
                    <br/>
                    <p>認証コードは、PlanetIDで電子サービスにログインする時に利用します。忘れないようにしてください。</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-center"><img src={PidUg3JaCreateAuthCode} className="threequarter-width-image mb-3" /></div>
                    <p><strong className="medium">3. </strong><strong>認証コードを再入力</strong></p>
                    <p>確認のため、認証コードを再入力してください。</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-center"><img src={PidUg4JaCreateAuthCode} className="threequarter-width-image mb-3" /></div>
                    <p><strong className="medium">4. </strong><strong>署名コードを設定</strong></p>
                    <p>署名コードに６桁の数字を設定してください。</p>
                    <p>署名コードは、PlanetIDで電子署名、または許諾を行う時に利用します。忘れないようにしてください。</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-center"><img src={PidUg5JaCreateAuthCode} className="threequarter-width-image mb-3" /></div>
                    <p><strong className="medium">5. </strong><strong>署名コードを再入力</strong></p>
                    <p>確認のために、署名コードを再入力してください。</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-center"><img src={PidUg7JaCreateAuthCode} className="threequarter-width-image mb-3" /></div>
                    <p><strong className="medium">6. PlanetID </strong><strong>が発行されました</strong></p>
                    <p>PlanetIDの説明を見るには「次へ」をタップしてください。スキップする場合は「スキップ」をタップしてください。</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-center"><img src={PidUg8JaCreateAuthCode} className="threequarter-width-image mb-3" /></div>
                    <p><strong className="medium">7. </strong><strong>認証コードの使い方</strong></p>
                    <p>認証コードは、ウェブサイトやスマートフォンアプリにログインする時に使用します。</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-center"><img src={PidUg75JaCreateAuthCode} className="threequarter-width-image mb-3" /></div>
                    <p><strong className="medium">8. </strong><strong>署名コードの使い方</strong></p>
                    <p>署名コードは、契約書等に電子署名をする時、また、第三者に個人情報を開示することを許諾する時に使用します。</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-center"><img src={PidUg9JaCreateAuthCode} className="threequarter-width-image mb-3" /></div>
                    <p><strong className="medium">9. PlanetID </strong><strong>ホーム画面</strong></p>
                    <p>PlanetID を使用する準備ができました。</p>
                  </div>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-md-12"><h5 className="mb-5"><strong>ウェブサイト、スマートフォンアプリで</strong> PlanetID <strong>をリンク</strong></h5></div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <p><strong className="medium">1. PlanetID</strong><strong>のリンク</strong></p>
                    <p>PlanetIDのリンクとは、PlanetIDに対応しているウェブサイトやスマートフォンアプリのサービスのアカウントに、あなたのPlanetIDを紐つけることを意味します。</p>
                    <p>まず最初に対象のウェブサイトやスマートフォンアプリのサービスに、 対象サービスのユーザー名、パスワードを使用して“”ログインしてください。ログイン後、PlanetIDをリンクするボタンは、各ウェブサイトやスマートフォンアプリのサービスにより配置場所等異なることがありますので、PlanetIDのリンク方法は、各ウェブサイトやスマートフォンアプリのサービスにお問い合わせください。例として以下のような「Link PlanetID」ボタンをタップします。</p>
                    <div className="text-left"><img src={PidUg11JaLinkButton} className="half-width-image mb-3" /></div>
                    <p>パソコンで利用するウェブサイトサービスの場合は、パソコン画面にQRコードが表示されます。スマートフォンのブラウザやアプリの場合、「２．QRコードをスキャン」後の認証依頼の画面に遷移します。</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-center"><img src={PidUg12JaHome} className="threequarter-width-image mb-3" /></div>
                    <p><strong className="medium">2. QR</strong><strong>コードをスキャン</strong></p>
                    <p>PlanetIDホーム画面の「QRコードスキャン」ボタンをタップしてください。QRコードのスキャン画面で、パソコンのWebサービスで表示されたQRコードをスキャンしてください。QRコードをスキャンすると、PlanetIDアプリに次の画面のような認証の依頼が届きます。</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-center"><img src={PidUg13JaAuthRequest} className="threequarter-width-image mb-3" /></div>
                    <p><strong className="medium">3. </strong><strong>認証の依頼</strong></p>
                    <p>認証の依頼が届いたら、 画面上部に依頼元のサービス企業名 が表示されていることを確認して“”「承認する」ボタンをタップしてください。</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-center"><img src={PidUg14JaAuthCode} className="threequarter-width-image mb-3" /></div>
                    <p><strong className="medium">4. </strong><strong>認証コード入力</strong></p>
                    <p>4桁の認証コードを入力してください。</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-center"><img src={PidUg15JaAuthenticated} className="threequarter-width-image mb-3" /></div>
                    <p><strong className="medium">5. </strong><strong>認証</strong></p>
                    <p>以上で対象のウェブサイトやスマートフォンアプリのサービスのログインIDとPlanetIDがリンクされた状態になり、対象のウェブサイトやスマートフォンアプリのサービスにてPlanetIDを使用したログインができるようになります。</p>
                  </div>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-md-12">
                  <h5 className="mb-5">PlanetID <strong>でログイン</strong></h5>
                  <p>PlanetIDでログインとは、PlanetID対応している各ウェブサイトやスマートフォンアプリのサービスを利用する際、対象サービス固有のアカウントに替えてPlanetIDによる認証を行い対象サービスに対してログイン（認証）を行うことを意味します。PlanetIDログインボタンは、各ウェブサイトやスマートフォンアプリのサービスにより配置場所等異なりますので、例として以下のようなボタンをタップすることでPlanetIDによるログイン処理が始まります。</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-left"><img src={PidUg16JaSigninButton} className="half-width-image mb-3" /></div>
                    <p><strong className="medium">1. PlanetID</strong><strong>でサインイン</strong></p>
                    <p>パソコンで利用するウェブサイトサービスの場合は、パソコン画面にQRコードが表示されます。スマートフォンのブラウザやアプリの場合、「２．QRコードをスキャン」後の認証依頼の画面に遷移します。</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-center"><img src={PidUg17JaHome} className="threequarter-width-image mb-3" /></div>
                    <p><strong className="medium">2. QR</strong><strong>コードをスキャン</strong></p>
                    <p>PlanetIDホーム画面の「QRコードスキャン」ボタンをタップしてください。QRコードのスキャン画面で、パソコンのWebサービスで表示されたQRコードをスキャンしてください。QRコードをスキャンすると、PlanetIDアプリに次の画面のような認証の依頼が届きます。</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-center"><img src={PidUg18JaAuthRequest} className="threequarter-width-image mb-3" /></div>
                    <p><strong className="medium">3. </strong><strong>認証の依頼</strong></p>
                    <p>認証の依頼が届いたら、 画面上部に依頼元のサービス企業名 が表示されていることを確認して「承認する」ボタンをタップしてください。</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-center"><img src={PidUg19JaAuthCode} className="threequarter-width-image mb-3" /></div>
                    <p><strong className="medium">4. </strong><strong>認証コード入力</strong></p>
                    <p>4桁の認証コードを入力してください。</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-center"><img src={PidUg20JaAuthenticated} className="threequarter-width-image mb-3" /></div>
                    <p><strong className="medium">5. </strong><strong>認証完了</strong></p>
                    <p>以上のPlanetIDアプリでの操作後、対象のウェブサイトもしくはスマートフォンアプリでの認証が完了し、ログインした状態になります。</p>
                  </div>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-md-12">
                  <h5 className="mb-5">PlanetID <strong>で電子署名</strong></h5>
                  <p>PlanetIDで電子署名とは、PlanetID対応している各ウェブサイトやスマートフォンアプリのサービスを利用する際、対象サービス内の申請書等にPlanetIDによる電子署名を行うことを意味します。</p>
                  <br/>
                  <p>PlanetIDで電子署名ボタンは、各ウェブサイトやスマートフォンアプリのサービスにより配置場所やタイミング等異なりますので、例として対象サービス側で以下のようなボタンをタップすることでPlanetIDによる電子署名処理が始まります。</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-left"><img src={PidUg21JaSignatureButton} className="half-width-image mb-3" /></div>
                    <p><strong className="medium">1. PlanetID</strong><strong>で電子署名</strong></p>
                    <p>PlanetID対応している各ウェブサイトやスマートフォンアプリのサービス内で、電子署名の処理が始まると、次の画面のようにPlanetIDアプリに電子署名の依頼が届きます。</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-center"><img src={PidUg22JaSignRequest} className="threequarter-width-image mb-3" /></div>
                    <p><strong className="medium">2. </strong><strong>電子署名の依頼</strong></p>
                    <p>電子署名を行うファイルの情報を確認して「承認する」ボタンをタップしてください。</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-center"><img src={PidUg23JaSignCode} className="threequarter-width-image mb-3" /></div>
                    <p><strong className="medium">3. </strong><strong>署名コード入力</strong></p>
                    <p>６桁の署名コードを入力してください。この署名コードを入れることで、対象のドキュメントに電子署名することができます。</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-center"><img src={PidUg24JaSigned} className="threequarter-width-image mb-3" /></div>
                    <p><strong className="medium">4. </strong><strong>署名完了</strong></p>
                    <p>電子署名が完了すると、各対象のウェブサイトやスマートフォンアプリのサービスに戻ります。</p>
                  </div>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-md-12">
                  <h5 className="mb-5">PlanetID<strong>で許諾による個人データ共有</strong></h5>
                  <p>PlanetIDによる許諾とは、各対象のウェブサイトやスマートフォンアプリの個人情報を第三者へ開示することに対して許諾することを意味します。</p>
                  <br/>
                  <p>各ウェブサイトやスマートフォンアプリのサービスにより配置場所やタイミング等異なりますので、例として対象サービス側で以下のようなボタンをタップすることでPlanetIDによる許諾処理が始まります。</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-left"><img src={PidUg25JaConsentButton} className="half-width-image mb-3" /></div>
                    <p><strong className="medium">1. PlanetID </strong><strong>で許諾</strong></p>
                    <p>対象サービス側で許諾処理が始まると、次の画面のような許諾依頼がPlanetIDアプリに届きます。</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-center"><img src={PidUg26JaConsentRequest} className="threequarter-width-image mb-3" /></div>
                    <p><strong className="medium">2. </strong><strong>許諾の依頼</strong></p>
                    <p>個人データの送信元、送信先、開示内容等を十分に確認して、「承認する」ボタンをタップしてください。</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-center"><img src={PidUg27JaSignCode} className="threequarter-width-image mb-3" /></div>
                    <p><strong className="medium">3. </strong><strong>署名コード入力</strong></p>
                    <p>６桁の署名コードを入力してください。許諾とは、許諾依頼内容に対する電子署名を行う行為となります。</p>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="pid-faq-item mb-5">
                    <div className="text-center"><img src={PidUg28JaSigned} className="threequarter-width-image mb-3" /></div>
                    <p><strong className="medium">4. </strong><strong>許諾完了</strong></p>
                    <p>許諾が完了すると、各対象のウェブサイトやスマートフォンアプリのサービスに戻ります。</p>
                  </div>
                </div>
              </div>


            </div>
          </section>

        </main>

      </div>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query PlanetidUserGuideQuery {
    site {
      siteMetadata {
        title
        description
        keywords
      }
    }
  }
`
