import React from 'react'
import { Helmet } from 'react-helmet'
import Link from 'gatsby-link'
import OgImage from "img/ogimg-planetid.jpg"
import get from 'lodash/get'
import { graphql } from 'gatsby'

class RootIndex extends React.Component {
  render() {
    const langPrefix = this.props.location.pathname.match(/^\/en/) ? '/en' : '';

    const siteTitle = get(this, 'props.data.site.siteMetadata.en.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.en.description')
    const siteKeywords = get(this, 'props.data.site.siteMetadata.en.keywords')

    return (
      <div>
        <Helmet
        	title={siteTitle}
        	meta={[
        		{ name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: 'https://planetway.com' + OgImage },
        		{ property: 'og:description', content: siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />
        <main>

          <section className="pt-5">
            <div className="container">
              <div className="row">
                <div className="col"></div>
                <div className="col-sm-10">
                  <h4 className="underline"><strong>PlanetID 利用規約</strong></h4>

                  <div className="mb-5">
                    <span className="news-date margin-bottom-sm">本サービスのお申込みの前に、必ず本規約の内容を確認してください。</span>

                    <ol className="sublist">
                      <li className="list-item">総則
                        <ol className="sublist">
                          <li className="list-item">PlanetID利用規約(以下、「本規約」といいます。)は、PlanetwayJapan株式会社が提供するソフトウェアである PlanetID、及びそこに付帯されるサービスである PlanetID Engine、ユーザーサポートの利用に対して適用される。</li>
                          <li className="list-item">本規約は、本ソフトウェア及びサービスの利用に関する条件及び当社と利用者との間の権利義務関係を定めることを目的とする。</li>
                          <li className="list-item">利用者は、本ソフトウェア及びサービスの利用にあたり、本規約を遵守するものとする。</li>
                        </ol>
                      </li>
                      <li className="list-item">定義
                        <p>本規約において使用する以下の用語は、以下に定める意味を有する。</p>
                        <ol className="sublist">
                          <li className="list-item">「当社」とはPlanetwayJapan株式会社をいう。</li>
                          <li className="list-item">「利用者」とは当社と本ライセンス契約を締結し本ソフトウェア及びサービスを利用する者をいう。</li>
                          <li className="list-item">本ソフトウェア」とは、当社が提供する以下の製品の総称をいう。<br/>(1) ユニバーサル ID 基盤「PlanetID」</li>
                          <li className="list-item">本利用契約」とは、本規約に従い当社と利用者との間で締結される本ソフトウェアの利用に関する契約をいう。 PlanetID Engine およびユーザーサポートのサービス利用を含む。</li>
                          <li className="list-item">「認証局(C A)」とは、電子証明書の生成、発行、失効、及び管理をする組織をいう。</li>
                          <li className="list-item">「S L O」とは、Service・Level・Objective の略でどの程度の(Level)サービス提供(Service)を目標値として設定するか(Objective)というサービスの目標値についての取り決めとして本サイト上(後報)で定義するものをいう。</li>
                          <li className="list-item">知的財産権」とは、全世界における、特許権、実用新案権、意匠権、商標権、著作権(翻訳・翻案権及び二次的著作物の利用に関する原著作者の権利を含みます。)その他一切の知的財産権、及びそれらを登録、更新又は延長等の手続を行う権利をいう。</li>
                          <li className="list-item">「反社会的勢力」とは、暴力団、暴力団関係企業、総会屋、社会運動標ぼうゴロ、政治活動標ぼうゴロ、特殊知能暴力集団その他の反社会的活動を行う団体又はその構成員をいう。</li>
                          <li className="list-item">「法令等」とは、法律、政令、通達、規則、条例、裁判所の判決、決定、命令又は強制力のある行政処分、ガイドラインその他の規制の総称をいう。</li>
                          <li className="list-item">本サイト」とは当社のWebサイト(<a href="https://planetway.com/">https://planetway.com/</a>)をいう。</li>
                          <li className="list-item">「秘密情報」とは本ソフトウェア又は本規約に関連して当社が秘密と指定して利用者に開示した一切の情報をいう。但し、以下の情報は秘密情報に含まれない。</li>
                          <br/>
                          <p>(1) 開示等を受ける以前から、既に公知であった情報</p>
                          <p>(2) 開示等を受けた後、自己の責に帰すべき事由によらずに公知となった情報</p>
                          <p>(3) 開示等を受ける以前より、既に秘密情報として取り扱う義務なくして保有していた情報</p>
                          <p>(4) 正当な権限を有する第三者から秘密保持義務を負うことなく開示等された情報</p>
                        </ol>
                      </li>
                      <li className="list-item">利用契約の締結等
                        <ol className="sublist">
                          <li className="list-item">本利用契約は利用者が当社所定の申込みを当社に提出し、当社がこれに対し承諾の通知を発信したときに成立するものとする。なお、利用者は、本規約の内容を承諾の上、申込みを行 うものとし、利用者が申込みを行った時点で、当社は、利用者が本規約の内容を承諾してい るものとみなす。</li>
                          <li className="list-item">
                            当社は、利用者が次の各号のいずれかに該当すると判断した場合には、利用契約を締結しな いことがあるものとする。
                            <ul>
                              <li>利用者が虚偽の事実を申告したとき</li>
                              <li>本サービスの提供が技術上困難なとき</li>
                              <li>利用者が過去に当社との契約に違反したことがあるとき</li>
                              <li>当社の業務の遂行に支障があるとき、その他当社が不適当と判断したとき</li>
                            </ul>
                          </li>
                        </ol>
                      </li>
                      <li className="list-item">遵守事項及び禁止事項
                        <ol className="sublist">
                          <li className="list-item">利用者は、本ソフトウェアのインストールのために必要な範囲を超えて本ソフトウェアのコピーを作成してはならない。</li>
                          <li className="list-item">利用者は、本ソフトウェア若しくは本ソフトウェアに関する権利の一部又はすべてを、有償・無償を問わず、譲渡し、再販し、貸与し、担保に供し、又は第三者に使用させてはならない。</li>
                          <li className="list-item">利用者は、本ソフトウェアに関し、リバースエンジニアリング・逆アセンブル・逆コンパイル等のソースコード解析作業を行ってはならない。また、当社の書面による事前の許可なく、本ソフトウェアの改変、翻案、加工等の変更や本ソフトウェアを基にして派生物を作成してはならない。</li>
                          <li className="list-item">利用者は、本ソフトウェアの類似品を自ら開発し、若しくは第三者をして開発せしめ、又は製造し、販売してはならない。利用者がこれらの行為を行った場合、当社はこれらの行為の差止めを利用者に対して請求することができ、かつ、当社が被った損害の賠償を請求することができる。この場合、製造又は販売された当該類似品の個数に、本ソフトウェアの最高単価を乗じた金額を当社が被った損害額とみなす。</li>
                          <li className="list-item">利用者は、本ソフトウェアが提供する機能について、使用権を持たない者に使用させること及び使用可能とする仕組みを提供してはならない。また、本ソフトウェアの技術的な制約・エディションによる機能制限の回避を可能としてはならない。使用が判明した場合、利用者は、最高単価で計算された使用した人数分の本ソフトウェアの対価を支払う義務を負う。</li>
                          <li className="list-item">利用者は、使用権を持たない者が利用可能な、当社が提供するソフトウェアを使用する以外の方法で本ソフトウェアのデータを参照・編集するための仕組みを、製造・構築すること、及び使用権を持たない者が、当社が提供するソフトウェアを使用する以外の方法で、本ソフトウェアのデータを参照・編集してはらない。</li>
                          <li className="list-item">利用者は、第三者に本規約に定める禁止事項をさせてはいけません。また、利用者は、第三者が本規約に定める禁止事項を行うことを唆し又は容易ならしめてはならない。</li>
                          <li className="list-item">利用者は、事由の如何を問わず、本ライセンス契約が終了した場合及び解除された場合には、保有する全ての本ソフトウェアをコンピューター上の記憶媒体から完全に消去し、ドキュメント類の全てとその複製物を破棄する。</li>
                        </ol>
                      </li>
                      <li className="list-item">知的財産権
                        <ol className="sublist">
                          <li className="list-item">本ソフトウェアにかかる一切の知的財産権は、利用者が従前から保有する当該権利を除き、当社又は当該権利を有する第三者に帰属する。</li>
                          <li className="list-item">利用者は、前項の知的財産権その他本ソフトウェアに関する一切の権利を侵害する行為をしてはならない。</li>
                        </ol>
                      </li>
                      <li className="list-item">本ソフトウェアの変更等
                        <ol className="sublist">
                          <li className="list-item">当社は、当社の裁量により、いつでも、本ソフトウェアの内容の全部又は一部を変更、修正又は追加する権利を留保する</li>
                          <li className="list-item">当社は、以下のいずれかの事由が生じた場合には、本ソフトウェアの全部又は一部の提供を一時的に停止又は中断することができる。なお、この場合、当社は、利用者に対し、その旨を通知しなければならない。
                            <ul>
                              <li>本ソフトウェアに関わるハードウェア、通信機器その他関連する機器若しくはシステムの点検又は保守作業を定期的に又は緊急に行う場合</li>
                              <li>コンピューター若しくは通信回線等の障害、誤操作、過度なアクセスの集中、不正アクセス、ハッキング、その他予期せぬ要因により本ソフトウェアの提供が困難となった場合</li>
                              <li>本ソフトウェアに関するセキュリティ上の問題が生じた場合</li>
                              <li>天災地変、戦争、戦争のおそれ、封鎖、通商停止、革命、暴動、伝染病若しくはその他の疫病、物資若しくは施設の破壊若しくは損傷、火災、台風、地震、洪水、その他当社の支配を超える事由(以下「不可抗力」という)</li>
                              <li>法令等又はこれらに基づく措置により本ソフトウェアの提供が困難となった場合</li>
                              <li>その他前各号に準じる理由により当社が必要と判断した場合</li>
                            </ul>
                          </li>
                        </ol>
                      </li>
                      <li className="list-item">利用停止等及び解除
                        <ol className="sublist">
                          <li className="list-item">
                            利用者が以下の各号のいずれかの事由に該当すると当社が判断した場合、当社は、事前に通知することなく、当該利用者による本ソフトウェアの利用を一時的に停止若しくは制限し、又は当該利用者が本ソフトウェアに関して提供した情報の全部若しくは一部を削除するができるものとする。
                            <ul>
                              <li>利用料金その他本ライセンス契約に基づく支払いを期日に行わず、当社の催促から 7 日以内に支払遅延を解消しない場合</li>
                              <li>本ソフトウェアに関する不正な支払いが判明した場合</li>
                              <li>前各号に準ずる事態が生じたと当社が判断した場合</li>
                            </ul>
                          </li>
                          <li className="list-item">当社又は利用者が以下の各号のいずれかの事由に該当する場合、相手方に通知の上、直ちに本ライセンス契約を解除することができる。
                            <ul>
                              <li>権利義務の譲渡禁止義務に違反した場合</li>
                              <li>支払停止若しくは支払不能となった場合</li>
                              <li>破産、民事再生、会社更生、特別清算、又はこれらに類する手続の開始の申立てがあった場合</li>
                              <li>解散又は清算の手続開始が決議又は決定された場合</li>
                            </ul>
                          </li>
                        </ol>
                      </li>
                      <li className="list-item">損害賠償等
                        <ol className="sublist">
                          <li className="list-item">債務不履行、不法行為等の構成の如何を問わず、当社が本ライセンス契約に関して故意又は重過失により利用者に損害を与えた場合、当社は、利用者が直接かつ現実に被った損害に限り、賠償を行うものとする。</li>
                          <li className="list-item">前項の定めに関わらず、本ライセンス契約に関して当社が利用者に対して負う責任は、債務不履行、不法行為等の構成の如何を問わず、当該請求原因の発生時の直近12ヶ月において当社が当該利用者から実際に受領した本ソフトウェアの利用料を上限とする。</li>
                          <li className="list-item">債務不履行、不法行為等の構成の如何を問わず、当社が本ソフトウェアの利用者への提供が困難になった場合、かかる利用が不可能となった当該月の利用料の支払いは行わないものとする。なお、この場合において、利用者が当社に対して既に支払いを行っていたとき、当社は、利用者に対し、当該利用不可能月のライセンス利用料を返還する。</li>
                        </ol>
                      </li>
                      <li className="list-item">非保証
                        <ol className="sublist">
                          <li className="list-item">当社は、当社が別途明示的に表明する場合を除き、本ソフトウェアに関し、特定目的への適合性、機能性、商品性、有用性、互換性、完全性、正確性、連続性、信頼性、実用性、可用性、アクセシビリティ、適法性、並びに、セキュリティ上の欠陥、エラー、バグ、ウイルス、その他の欠陥の不存在について、保証するものではない。</li>
                          <li className="list-item">当社は、本ソフトェアと連携する利用者又は第三者のソフトウェア、サービス、情報、個人情報の管理等について一切の責任を負わない。</li>
                        </ol>
                      </li>
                      <li className="list-item">秘密保持
                        <ol className="sublist">
                          <li className="list-item">利用者は、秘密情報を厳に秘密として保持し、当社の事前の書面による承諾なく第三者に開示してはならない。</li>
                          <li className="list-item">利用者は、秘密情報を本ソフトウェアの利用の目的でのみ利用する。</li>
                        </ol>
                      </li>
                      <li className="list-item">個人情報
                        <p>当社は、利用者の個人情報を取得する場合、法令及び本サイト内のプライバシーポリシーに従い、当該個人情報を適切に取り扱う。</p>
                      </li>
                      <li className="list-item">規定の可分性
                        <p>本ライセンス契約のいずれかの条項又はその一部が、法令等により無効と判断された場合でも、本ライセンス契約の残りの条項又は部分は、継続して完全に有効なものとして存続する。</p>
                      </li>
                      <li className="list-item">準拠法及び裁判管轄
                        <ol className="sublist">
                          <li className="list-item">本ライセンス契約は日本法に準拠し、かつ、これに従って解釈される。「動産の国際的売買契約に関する国際連合条約」は本ライセンス契約には適用されない。</li>
                          <li className="list-item">本ライセンス契約又は本ソフトウェアの利用に起因又は関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とする。</li>
                        </ol>
                      </li>
                      <li className="list-item">誠実協議
                        <p>本規約又は本ライセンス契約に関して疑義が生じた場合、両当事者が誠実に協議するものとする。</p>
                      </li>
                      <li className="list-item">規約の変更
                        <ol className="sublist">
                          <li className="list-item">当社は、本規約の全部又は一部を任意に改定できるものとする。</li>
                          <li className="list-item">本サイト上又は電子メール等の手段で本規約の改定を告知した後、1ヶ月を経た時点又は利用者が本ソフトウェアを使用した時点のいずれか早い時点において、利用者は当該改定を承諾したものとみなす。なお、本規約に基づき既に発生している権利義務は当該改訂による影響を受けない。</li>
                        </ol>
                      </li>
                    </ol>
                    <br/>
                    <Link target="_blank" to="/legal/PlanetID-Terms-of-Service-20220426-1.pdf">2021年7月1日 PlanetID 規約初版 2022年4月1日改定</Link>
                  </div>

                </div>
                <div className="col"></div>
              </div>
            </div>
          </section>

        </main>

      </div>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query PlanetidTermsOfServiceQuery {
    site {
      siteMetadata {
        en {
          title
          description
          keywords
        }
      }
    }
  }
`
