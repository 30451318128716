import React from 'react'
import Link from 'gatsby-link'
import LanguageLink from 'components/language-link'

class Navigation extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const langPrefix = this.props.location.pathname.match(/^\/en/) ? '/en' : '';
    const colorClass = this.props.isWhiteText ? "dark-header" : "light-header";

    return (
        <div id="header-wrapper" className={colorClass}>
        <header>
        <nav className="main-nav">
          <div className='container-fluid'>

            <h1 className="brand"><Link to={langPrefix + "/"} className="internal-link">Planetway</Link></h1>

            <LanguageLink lang='en' to={this.props.location.pathname.replace(/^\//,'/en/')} />

            <div className="menu-wrapper">

              <ul className="main-menu">
                <li>
                  <Link to={langPrefix + "/our-vision"} className="internal-link">会社情報</Link>
                  <div className="dropdown" style={{ zIndex: "103" }}>
                    <Link to={langPrefix + "/our-vision"} className="internal-link">ビジョン</Link>
                    <Link to={langPrefix + "/corporate-profile"} className="internal-link">会社概要</Link>
                    <Link to={langPrefix + "/corporate-leadership"} className="internal-link">役員一覧</Link>
                  </div>
                </li>
                <li>
                  <Link to={langPrefix + "/planetcross"} className="internal-link">製品情報</Link>
                  <div className="dropdown" style={{ zIndex: "102" }}>
                    <Link to={langPrefix + "/planetcross"} className="internal-link">PlanetCross</Link>
                    <Link to={langPrefix + "/planetid"} className="internal-link">PlanetID</Link>
                    <Link to={langPrefix + "/security-service-for-gdpr"} className="internal-link">GDPR対策支援サービス</Link>
                  </div>
                </li>
                {/*<li>*/}
                {/*  <Link to={langPrefix + "/planetway-data-sovereignty-alliance"} className="internal-link">Alliance</Link>*/}
                {/*  <div className="dropdown" style={{ zIndex: "101" }}>*/}
                {/*    <Link to={langPrefix + "/planetway-data-sovereignty-alliance"} className="internal-link">About PDSA</Link>*/}
                {/*    <Link to={langPrefix + "/pdsa-partners"} className="internal-link">Find a Partner</Link>*/}
                {/*  </div>*/}
                {/*</li>*/}
                <li><Link to={langPrefix + "/media"} className="internal-link">ニュースリリース</Link></li>
                {/*<li><Link to={langPrefix + "/recruit"} className="internal-link">Recruit</Link></li>*/}
                <li><Link to={langPrefix + "/contact"} className="internal-link">お問い合わせ</Link></li>
              </ul>
            </div>

            <div className="hamburger" onClick={() => {document.body.classList.toggle('mobile-menu-open')}}>
              <span className="line"/>
              <span className="line"/>
              <span className="line"/>
            </div>
            <div className="clear"/>
          </div>
        </nav>
      </header>
      </div>
    );
  }
}

export default Navigation
