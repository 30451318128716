import React from 'react'
import { Helmet } from 'react-helmet'
import Link from 'gatsby-link'
import OgImage from "img/ogimg-contact.jpg"
import get from 'lodash/get'
import { graphql } from 'gatsby'
import Layout from "../../components/layout"

class RootIndex extends React.Component {
  constructor(props) {
    super(props);
    this.state = { subject: "" };
    this.onPurposeChange = this.onPurposeChange.bind(this);
  }
  onPurposeChange(ev) {
    let purpose = ev.target.value;
    let subject = `New submission from planetway.com/contact/ purpose=${purpose}`;
    this.setState({
      subject: subject
    });
  }
  render() {
    const siteTitle       = get(this, 'props.data.site.siteMetadata.en.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.en.description')
    const siteKeywords    = get(this, 'props.data.site.siteMetadata.en.keywords')

    return (
      <Layout location={this.props.location}>
        <Helmet
        	title={siteTitle}
        	meta={[
        		{ name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: 'https://planetway.com' + OgImage },
        		{ property: 'og:description', content: siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />
        <main className="no-header">

          <div className="clear" />

          <section className="padding-top-lg padding-bottom-md">
            <div className="container">
              <div className="row">
                <div className="col" />
                <div className="col-lg-8 text-center">
                  <h4 className="underline">Contact Us</h4>
                </div>
                <div className="col" />
              </div>
              <div className="row">
                <div className="col" />
                <div className="col-lg-8">
                  <form action="https://formspree.io/pr@planetway.com" method="POST">
                    <input type="hidden" name="_next" value="https://planetway.com/en/thanks" />
                    <input type="hidden" name="_language" value="en" />
                    <input type="hidden" name="_subject" value={this.state.subject} />
                    <fieldset id="purpose">
                      <label htmlFor="purpose">Reason for contacting us <span className="required">*Required Field</span></label>
                      <select name="purpose" required onChange={this.onPurposeChange}>
                        <option value="">Please select...</option>
                        <option value="service">Inquiry about Services</option>
                        <option value="business">Business Inquiries</option>
                        <option value="interview">Meetings &amp; Appointments</option>
                        <option value="recruit">Job Opportunities</option>
                        <option value="privacy">Privacy Inquiries</option>
                        <option value="security">Security Inquiries</option>
                        <option value="gdpr">GDPR support services</option>
                        <option value="other">Other</option>
                      </select>
                    </fieldset>
                    <fieldset id="name"><label htmlFor="firstname">Name <span className="required">*Required Field</span></label>
                      <div style={{float: "left", width: "49%"}}>
                        <input type="text" name="name1" placeholder="First name" required/>
                      </div>
                      <div style={{float: "right", width: "49%"}}>
                        <input type="text" name="name2" placeholder="Last name" required/>
                      </div>
                    </fieldset>
                    <fieldset id="company">
                      <label htmlFor="company">Company</label>
                      <input type="text" name="company" placeholder="" />
                    </fieldset>
                    <fieldset id="email">
                      <label htmlFor="email">Email <span className="required">*Required Field</span></label>
                      <input type="email" name="email" placeholder="" required />
                    </fieldset>
                    <fieldset id="telephone">
                      <label htmlFor="telephone">Telephone</label>
                      <input type="text" name="telephone" placeholder="" />
                    </fieldset>
                    <fieldset id="message">
                      <label htmlFor="message">Message <span className="required">*Required Field</span></label>
                      <textarea name="message" required />
                    </fieldset>
                    <fieldset id="optin">
                      <label htmlFor="optin"><input type="checkbox" name="optin" />&nbsp;&nbsp;I consent to my personal data being used for Planetway marketing purposes</label>
                      {/* <Link to="/legal/PX_Freemium_Privacy_Notice.pdf"> (please read the privacy notice)</Link> */}
                    </fieldset>
                    <button type="submit" className="btn">Submit</button>
                  </form>
                </div>
                <div className="col" />
              </div>
            </div>
          </section>

          <section className="padding-top-lg padding-bottom-lg">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <h4 className="padding-bottom-none margin-bottom-sm">US Headquarters</h4>
                  <p className="text-larger"><strong>Planetway Corporation</strong><br />2033 Gateway Pl, San Jose, CA 95110, United States</p>
                  <p className="text-larger">
                    Tel: +1-408-961-8675<br />
                    {/* Email: <a href="mailto:sanjose@planetway.com">sanjose@planetway.com</a> */}
                  </p>
                </div>
                <div className="col-md-6">
                  <h4 className="padding-bottom-none margin-bottom-sm">Asia Pacific, Tokyo</h4>
                  <p className="text-larger"><strong>Planetway Japan K.K.</strong><br />Akasaka K-Tower 4F, 1-2-7 Motoakasaka, Minato-ku, Tokyo 107-0051, Japan </p>
                  <p className="text-larger">
                    Tel: +81-(0)3-6897-9419<br />
                    {/* Email: <a href="mailto:pr@planetway.com">pr@planetway.com</a> */}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <h4 className="padding-bottom-none margin-bottom-sm">R&D Center, Estonia</h4>
                  <p className="text-larger"><strong>Planetway Europe OÜ</strong><br />Valukoja 8, Öpiku 2, Tallinn, 11415 Estonia</p>
                  <p className="text-larger">
                    {/* Tel: 03-6897-9419<br /> */}
                    {/* Email: <a href="mailto:tallinn@planetway.com">tallinn@planetway.com</a> */}
                  </p>
                </div>
              </div>
            </div>
          </section>
        </main>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query ContactQueryEn {
    site {
      siteMetadata {
        en {
          title
          description
          keywords
        }
      }
    }
  }
`
