import React from 'react'
import { Helmet } from 'react-helmet'
import OgImage from "img/ogimg-contact.jpg"
import get from 'lodash/get'
import { graphql } from 'gatsby'

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.en.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.en.description')
    const siteKeywords = get(this, 'props.data.site.siteMetadata.en.keywords')

    return (
      <div>
        <Helmet
        	title={siteTitle}
        	meta={[
        		{ name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: 'https://planetway.com' + OgImage },
        		{ property: 'og:description', content: siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />
        <main className="no-header">

          <div className="clear"/>

          <section className="padding-top-md padding-bottom-none">
            <div className="container">
              <div className="row">
                <div className="col"/>
                <div className="col-lg-8 text-center">
                  <h4 className="underline">PlanetForum Signup</h4>
                  <h5>The 1st PlanetForum - Announcement of Planetway&reg; Data Sovereignty Alliance</h5>
                  <p>Tuesday 11 Feb 2020 - 13:30 to 16:30<br />Scramble Hall, SHIBUYA QWS, Shibuya Scramble Square<br /><a href="https://goo.gl/maps/LnqhMFPbAaqteftZ6" target="_blank">View Map</a></p>
                </div>
                <div className="col"/>
              </div>
            </div>
          </section>

          <section className="padding-top-none padding-bottom-lg">
            <div className="container">
              <div className="row">
                <div className="col"/>
                <div className="col-lg-8">
                  <form action="https://formspree.io/pr@planetway.com" method="POST">
                    <input type="hidden" name="_next" value="https://planetway.com/en/thanks" />
                    <input type="hidden" name="_language" value="en" />
                    <fieldset id="company">
                      <label htmlFor="company">Company Name</label>
                      <input type="text" name="company" placeholder="" required />
                    </fieldset>
                    <fieldset id="department">
                      <label htmlFor="department">Department</label>
                      <input type="text" name="department" placeholder="" />
                    </fieldset>
                    <fieldset id="name">
                      <label htmlFor="name">Name</label>
                      <input type="text" name="name" placeholder="" required />
                    </fieldset>
                    <fieldset id="email">
                      <label htmlFor="email">Email</label>
                      <input type="email" name="email" placeholder="" required />
                    </fieldset>
                    <button type="submit" className="btn">Submit</button>
                  </form>
                </div>
                <div className="col"/>
              </div>
            </div>
          </section>

        </main>

      </div>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query PlanetForumSignupQueryEn {
    site {
      siteMetadata {
        en {
          title
          description
          keywords
        }
      }
    }
  }
`
