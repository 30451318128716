import React from 'react'
import Link from 'gatsby-link'

// lang is 'en' or 'jp'
export default (props) => {
  const lang = props.lang;
  if (lang !== 'ja' && lang !== 'en') {
    console.log('ja or en only allowed but got: ', lang);
    throw('ja or en only allowed');
  }

  const className = lang === 'ja' ? 'japanese' : 'english';
  const linkText = lang === 'ja' ? 'JA' : 'EN';

  return (
    <div className="language">
      <Link className="internal-link" {...props}>{linkText}</Link>
    </div>
  );
}
