import React from 'react'
import { Helmet } from 'react-helmet'
import BodyClassName from 'react-body-classname'
import Link from 'gatsby-link'
import OgImage from "img/ogimg-planeteco.jpg"
import get from 'lodash/get'
import { graphql } from 'gatsby'

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.description')
    const siteKeywords = get(this, 'props.data.site.siteMetadata.keywords')

    return (
      <div>
        <Helmet
        	title={`PlanetEco - ${siteTitle}`}
        	meta={[
        		{ name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: 'PlanetEco - ' + siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: 'https://planetway.com' + OgImage },
        		{ property: 'og:description', content: siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />
        <BodyClassName className='header-no-divider' />

        <div className="page-header planeteco-header dia-bottom">
          <div className="container text-left">
            <div className="row">
              <div className="col-md-6">
                <div className="planeteco-logo-artwork"></div>
              </div>
              <div className="col-md-6">
                <h2><span className="text-light">Planet</span>Eco</h2>
                <strong className="subtitle-small">Open Innovation Platform</strong>
                <p>PlanetEcoは、情報連携基盤であるPlanetCrossと個人認証基盤であるPlanetIDを共通基盤とし、特定のテーマのもと各社 ・団体と協力して、オープン・イノベーションによる新たなサービス開発を行うことを目的としたプログラムを推進するものです(複数企業/団体を対象)。</p>
              </div>
            </div>
          </div>
        </div>

        <main>

          <div className="clear"></div>

          <section className="padding-bottom-none">
            <div className="container">
              <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-lg-8 text-center mobile-text-left">
                  <h3 className="underline">What is PlanetEco?</h3>
                  <p>PlanetEcoは、情報連携基盤である PlanetCross と個人認証基盤である PlanetID を共通基盤とし、特定のテーマのもと各社 ・団体と協力して、オープン・イノベーションによる新たなサービス開発を行うことを目的としたプログラムを推進するものです(複数企業/団体を対象)。</p>
                </div>
                <div className="col-lg-2"></div>
              </div>
            </div>
          </section>

          <section className="padding-bottom-none">
            <div className="container">
              <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-lg-8 text-center mobile-text-left">
                  <h3 className="underline">Core Areas</h3>
                </div>
                <div className="col-lg-2"></div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-md text-center">
                  <div className="planeteco-theme-healthcare block-center"></div>
                  <p className="subtitle-small"><strong>ヘルスケア</strong></p>
                </div>
                <div className="col-md text-center">
                  <div className="planeteco-theme-realestate block-center"></div>
                  <p className="subtitle-small"><strong>不動産</strong></p>
                </div>
                <div className="col-md text-center">
                  <div className="planeteco-theme-finance block-center"></div>
                  <p className="subtitle-small"><strong>金融</strong></p>
                </div>
                <div className="col-md text-center">
                  <div className="planeteco-theme-automotive block-center"></div>
                  <p className="subtitle-small"><strong>自動車</strong></p>
                </div>
              </div>
            </div>
          </section>

          <section className="padding-bottom-none">
            <div className="container">
              <div className="row">
                <div className="col">
                  <h3 className="underline">Example Use Cases</h3>
                </div>
              </div>
            </div>
          </section>

          <section className="light-grey-bg">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <h4 className="numbered-title"><span className="number one"></span><span className="">Healthcare<strong className="subtitle-small">医療機関 — 保険会社間連携</strong></span></h4>
                  <p><strong>エンドユーザーからの申請による個人データ利活用</strong><br />
                  <strong>目的:</strong> ユーザービリティ向上＆オペレーションコスト削減</p>
                  <p>保険金請求時において、エンドユーザー（保険契約者）と保険会社との間の申請書等のやり取りが電子化され、 医療機関が保有する当ユーザー（患者）の診断書情報等の電子データが、ユーザーの許諾をもとに医療機関から 保険会社へ直接連携されます。これにより、保険金請求から支払までの期間が大幅に短縮化され、ユーザビリティが大きく向上します。</p>
                  <div className="planetid-x-planetcross"></div>
                </div>
                <div className="col-lg-6">
                  <div className="planeteco-healthcare-diagram block-center"></div>
                </div>
              </div>
            </div>
          </section>

          <section className="medium-grey-bg">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <h4 className="numbered-title"><span className="number two"></span><span className="">Finance<strong className="subtitle-small">金融機関 — 他サービス企業間連携</strong></span></h4>
                  <p><strong>サービス提供企業からの個人情報要求による個人データ利活用</strong><br />
                  <strong>目的:</strong> 新サービス提供機会創出</p>
                  <p>金融機関等に口座を保有するエンドユーザーが、事前に属性情報や趣味/嗜好を登録し、データ公開可否/範囲を設定しておくことで、 他のサービス提供企業がデータを参照/活用し、ユーザーに対する新たなサービス機会の提供を可能とします。個人が自らのデータ提供をコントロールし、 公開した場合はそのユーザー自身が新たな便益を享受できる新ビジネスの創出が期待できます。</p>
                  <div className="planetid-x-planetcross"></div>
                </div>
                <div className="col-lg-6">
                  <div className="planeteco-finance-diagram block-center"></div>
                </div>
              </div>
            </div>
          </section>

          <section className="light-grey-bg">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h4 className="numbered-title"><span className="number three"></span><span className="">Smart City<strong className="subtitle-small">特定地域における企業 — 行政間連携</strong></span></h4>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-8">
                  <p>行政や不動産ディベロッパー等が有する先進的な街づくり推進地区において、市民や企業のより豊かな暮らし/企業運営をサポートします。域内に居住する個人には新たにIDが付与され、そのIDを用いてあらゆるサービス提供が受けられる社会の実現を後押しします。</p>
                </div>
                <div className="col-lg-4">
                  <div className="planetid-x-planetcross block-center"></div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="planeteco-smartcity-diagram block-center"></div>
                </div>
              </div>
            </div>
          </section>

          <section className="medium-grey-bg">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h4 className="numbered-title"><span className="number four"></span><span className="">Automotive<strong className="subtitle-small"></strong></span></h4>
                  <h4 className="padding-top-lg padding-bottom-lg text-center">準備中</h4>
                </div>
              </div>
            </div>
          </section>

          <section className="light-grey-bg">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <h4 className="numbered-title"><span className="number five"></span><span className="">Secure P2P Data System<strong className="subtitle-small">企業グループ間情報連携</strong></span></h4>
                  <p>自社グループにおける本社-子会社間やグループ外の代理店やサプライヤー等の間において、高額になりがちな専用線を敷設することなく、インターネットを通じて、 分散型の情報連携を行うことが可能です。ここではグループ内のデータ利活用のために中央集権的にデータを収集する必要はありません。また、RDB (Relational Data Base) だけでなく、文書や動画・音声等のファイルの連携も対象とすることができ、これまでデータのやり取りでかかっていたコストの大幅な削減が見込めます。</p>
                  <div className="planetcross-small-grey-logo"></div>
                </div>
                <div className="col-lg-6">
                  <div className="planeteco-group-diagram block-center"></div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="container">
              <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-lg-8 text-center mobile-text-left">
                  <h3>Become a Partner</h3>
                  <p>当社のコア技術 (PlanetID & PlanetCross)を用いたデータの利活用について、様々なユースケースを一緒に検討いただけるパートナーを募集します！</p>
                  <Link to="/contact" className="btn internal-link">Contact Us</Link>
                </div>
                <div className="col-lg-2"></div>
              </div>
            </div>
          </section>

        </main>

      </div>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query PlanetEcoQuery {
    site {
      siteMetadata {
        title
        description
        keywords
      }
    }
  }
`
