import React from 'react'
import styles from './article-preview.module.css'
import Link from 'gatsby-link'

export default ({ post }) => {
  const to = (post.node_locale === 'en' ? '/en' : '') + `/pressreleases/${post.slug}`;

  return (
    <div className="sidebar-row">
      <span className={`news-category ${post.type}`}></span>
      <span className="news-date">{post.publishDate}</span>
      <Link to={to} className="internal-link">{post.title}</Link>
    </div>
  );
}

