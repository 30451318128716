import React from 'react'
import { Helmet } from 'react-helmet'
import OgImage from "img/ogimg-contact.jpg"
import get from 'lodash/get'
import { graphql } from 'gatsby'

import DocSection from "components/DocSection";
import TopScroll from "components/TopScroll";

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.description')
    const siteKeywords = get(this, 'props.data.site.siteMetadata.keywords')

    let sections = this.props.data.allMarkdownRemark.edges;

    return (
      <div>
        <Helmet
        	title={siteTitle}
        	meta={[
        		{ name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: 'https://planetway.com' + OgImage },
        		{ property: 'og:description', content: siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />
        <main className="no-header">

          <div className="clear"></div>

          <section className="padding-top-lg">
            <div className="container">
              <div className="row">
                <div className="col"></div>
                <div className="col-lg-8">
                  <h3 className="pageTitle margin-bottom-md">Documentation</h3>

                  <div className="margin-top-lg"></div>
                  {sections.map(function (section, index) {
                    let language = section.node.frontmatter.language;
                    if (!language || language=="ja") {
                      return <div id={section.node.frontmatter.linkTitle ? section.node.frontmatter.linkTitle.replace(/[^\x00-\x7F]|\s/g, "") : null}>
                               <DocSection text={section.node.html} title={section.node.frontmatter.title} info={section.node.frontmatter}/>
                             </div>
                    }
                  })}
                  <div className="clear"></div>
                </div>
                <div className="col"></div>
              </div>
            </div>
          </section>

        </main>

      </div>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query PlanetForumDownload {
    site {
      siteMetadata {
        title
        description
        keywords
      }
    }
    allMarkdownRemark(filter: { frontmatter:  { type: { eq:"planetForumDownloads"}} }, sort: { order: ASC, fields: [frontmatter___id] }) {
      edges {
        node {
          html
          frontmatter {
            date
            title
            titleEn
            linkTitle
            id
            type
            underline
            icon1
            heading
            language
          }
        }
      }
    }
  }
`
