import React from 'react'
import { Helmet } from 'react-helmet'
import Link from 'gatsby-link'
import OgImage from "img/ogimg-planetcross.jpg"
import PartnerLogoNetgroup from "img/partner-logo-netgroup.png"
import get from 'lodash/get'
import { graphql } from 'gatsby'
import Layout from "../components/layout"

class RootIndex extends React.Component {
  render() {
    const langPrefix = this.props.location.pathname.match(/^\/en/) ? '/en' : '';

    const siteTitle = get(this, 'props.data.site.siteMetadata.en.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.en.description')
    const siteKeywords = get(this, 'props.data.site.siteMetadata.en.keywords')

    return (
      <Layout location={this.props.location}>
        <Helmet
        	title={siteTitle}
        	meta={[
        		{ name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: 'https://planetway.com' + OgImage },
        		{ property: 'og:description', content: siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />
        <main className="no-header">

          <div className="clear" />

          <section className="padding-top-lg">
            <div className="container">
              <div className="row">
                <div className="col" />
                <div className="col-sm-10"><div className="partner-logo"><img src={PartnerLogoNetgroup} /></div>
                  <h4 className="underline">Net Group OÜ</h4>
                  <h5 className="margin-bottom-sm"><strong>ルワンダとタンザニアのデジタルガバナンスをアップグレードしてアフリカを変える</strong></h5>
                  <p>Net Groupは、フィールドおよびEMEA市場で20年の経験を持つ新世代のソフトウェア開発およびコンサルタント会社です。 ソフトウェアソリューションを通じてクライアントの効率を高め、コスト削減することを目指しています。</p>
                  <p>同社は自分たちを単なるプログラマーではなく、顧客のビジネスを理解し、ニーズを分析し、最も適切で収益性の高いソリューションを提供することを目的としたコンサルタントだと考えています。 新しいソリューションを開発する際に留意している重要な側面は、シンプルさ、使いやすさおよびビジネスプロセスの改善に対する直接的な影響です。</p>
                  <h5 className="margin-top-md margin-bottom-sm"><strong>主な得意分野</strong></h5>
                  <p>EC（デジタルチャネル、バックオフィスシステム）、デジタルガバナンス（デジタル裁判、e教育）、フィンテック（預託銀行、資産管理）、ユーティリティ（CRMと請求、データ統合）、デジタルビジネス（コンサルティング、ソフトウェア開発）</p>
                  <h5 className="margin-top-md margin-bottom-sm"><strong>ケーススタディ</strong></h5>
                  <p>Net Groupは、エストニア最大のエネルギー企業Eesti Energiaの設計をエンコードし、技術面でのアプリケーションを開発する上で重要な役割を果たしました。 インターネットユーザーがますますモバイルデバイスを使用するようになったため、Eesti Energiaは顧客と同じ環境で顧客と関わる必要がありました。 Net Groupは、アプリケーションの技術的な側面に加えて、アプリケーション画面を作成し、サービスレイヤーを操作し、フロントエンド機能を支援しました。 プロジェクト成功の一つの要因として、クライアントといくつかの技術チームとの間の理解を保証するNet Groupの調整力がありました。 Net Groupは、アプリケーションが視覚的に魅力的で使いやすく、変化する顧客の習慣や技術プラットフォームに合わせて簡単にアップグレードできるロジックが組み込むことで関連性を維持しやすいようにアレンジしました。</p>
                  <p>詳細はこちら: <a href="https://www.netgroup.com/" target="_blank">https://www.netgroup.com/</a></p>
                  <p className="text-center mt-5"><Link to={langPrefix + "/pdsa-partners"} className="btn internal-link">一覧に戻る</Link></p>
                </div>
                <div className="col" />
              </div>
            </div>
          </section>

        </main>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query PdsaPartnersNetgroupQuery {
    site {
      siteMetadata {
        en {
          title
          description
          keywords
        }
      }
    }
  }
`
