import React from 'react'
import styles from './article-preview.module.css'
import Link from 'gatsby-link'

export default ({ post }) => {

  // cover model is optional in contentful
  let cover;
  if (post.cover && post.cover.file) {
    cover = post.cover.file.url
  }

  return (
    <div className="page-header media-header dia-bottom" style={{backgroundImage: "linear-gradient(rgba(0,0,255,0.5), rgba(0,0,0,0.5)), url(" + cover + "), linear-gradient(#000, #000)"}}>
      <div className="container text-on-dark text-left">
        <div className="row">
          <div className="col">
            <span className={`news-category ${post.type} margin-bottom-md`}></span>
            <h3>{post.title}</h3>
            <span className="news-date margin-bottom-sm">{post.publishDate}</span>
            <div
              dangerouslySetInnerHTML={{
                __html: post.excerpt.childMarkdownRemark.html,
              }}
            />
            <Link to={`/en/pressreleases/${post.slug}`} className="btn internal-link">Read more</Link>
          </div>
        </div>
      </div>
    </div>
  );
};
