import React from 'react'
import styles from './article-preview.module.css'
import Link from 'gatsby-link'

export default ({ post }) => {

  const to = (post.node_locale === 'en' ? '/en' : '') + `/pressreleases/${post.slug}`;

  return (
    <section className="breaking-news padding-bottom-none">
      <div className="container">
        <div className="row">
          <div className="col-lg-1"></div>
          <div className="col-lg-10">
            <div className="breaking-news-box">
              <h5><strong><Link to={to}>{post.title}</Link></strong></h5>
              {/*<div*/}
              {/*  dangerouslySetInnerHTML={{*/}
              {/*    __html: post.excerpt.childMarkdownRemark.html,*/}
              {/*  }}*/}
              <div>
                { post.node_locale === 'en' ?
                    <p>Promoting digital city planning to realize "Smart City Awara, a town where people can live comfortably and experience happiness.</p> :
                    <p>暮らしやすくて幸せを実感できる街 スマートシティあわら」の実現に向け、デジタルを活用したまちづくりを推進</p>
                }
              </div>
            </div>
          </div>
          <div className="col-lg-1"></div>
        </div>
      </div>
    </section>
  );
};
