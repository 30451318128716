import React from 'react'
import { Helmet } from 'react-helmet'
import Link from 'gatsby-link'
import OgImage from "img/ogimg-planetid.jpg"
import get from 'lodash/get'
import { graphql } from 'gatsby'

class RootIndex extends React.Component {
  render() {
    const langPrefix = this.props.location.pathname.match(/^\/en/) ? '/en' : '';

    const siteTitle = get(this, 'props.data.site.siteMetadata.en.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.en.description')
    const siteKeywords = get(this, 'props.data.site.siteMetadata.en.keywords')

    return (
      <div>
        <Helmet
        	title={siteTitle}
        	meta={[
        		{ name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: 'https://planetway.com' + OgImage },
        		{ property: 'og:description', content: siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />
        <main className="no-header">

          <div className="clear"></div>

          <section className="padding-top-lg">
            <div className="container">
              <div className="row">
                <div className="col"></div>
                <div className="col-sm-12">
                  <h4 className="underline"><strong>プライバシーポリシー Privacy Policy</strong></h4>

                  <div className="mb-5">
                    <h5 className="mb-3 margin-top-lg"><strong>1. 個人情報の定義</strong></h5>
                    {/*<span class="news-date margin-bottom-sm">2020年3月27日 version 1.0</span>*/}
                    <p>Planetway Japan 株式会社（以下当社）は、個人情報とは、個人情報の保護に関する法律に規定される生存する個人に関する情報
                    氏名、生年月日、その他の特定の個人を識別することができる情報）、ならびに特定の個人と結びついて使用されるメールアドレス、
                    ユーザーID、パスワード、クレジットカードなどの情報、および個人情報と一体となった趣味、家族構成、年齢その他の個人に関する属
                    性情報であると認識しています。</p>

                    <h5 className="mb-3 margin-top-lg"><strong>2. クッキー・IP アドレス情報</strong></h5>
                    <p>当社は、クッキー及びIP アドレス情報については、それら単独では特定の個人を識別することができないため、個人情報とは考えてお
                    りません。ただしこれら情報と個人情報が一体となって使用される場合にはこれら情報も個人情報とみなします。当社の運営事務局の
                    運営するメディアにおいては、たとえ特定の個人を識別することができなくとも、クッキー及びIP アドレス情報を利用する場合には、そ
                    の目的と方法を開示してまいります。また、クッキー情報については、ユーザーの方で、ブラウザの設定で拒否することが可能です。ク
                    ッキーを拒否するとサービスが受けられない場合は、その旨も公表します。</p>

                    <h5 className="mb-3 margin-top-lg"><strong>3. 個人情報利用目的の特定</strong></h5>
                    <p>当社における個人情報の利用目的は以下の通りです。利用目的を超えて利用することはありません。</p>
                    <ul>
                      <li>ユーザーの個人認証及びユーザー向け提供</li>
                      <li>当社が行うサービスの利用に伴う連絡・メールマガジン・DM・各種お知らせ等の配信・送付</li>
                      <li>ユーザーの承諾・申込みに基づく、当社が行うサービス利用企業等への個人情報の提供</li>
                      <li>属性情報・端末情報・位置情報・行動履歴等に基づく広告・コンテンツ等の配信・表示、当社が行うサービス提供</li>
                      <li>当社が行うサービスの改善・新規サービスの開発・分析およびマーケティング</li>
                      <li>キャンペーン・アンケート・モニター・取材等の実施</li>
                      <li>空メール送信者に対するURL 情報の配信</li>
                      <li>当社が行うサービスに関するご意見、お問い合わせ、クチコミ投稿内容の確認・回答</li>
                      <li>利用規約等で禁じている、商用・転用目的での各種申込行為、各種多重申込、権利譲渡、虚偽情報登録などの調査と、それに基づく 当該申込内容の詳細確認</li>
                    </ul>


                    <h5 className="mb-3 margin-top-lg"><strong>4. 個人情報利用の制限</strong></h5>
                    <p>当社は、あらかじめユーザーの同意を得ず、利用目的の達成に必要な範囲を超えて個人情報を取扱うことはありません。ただし、次の
                    場合はこの限りではありません。</p>

                    <ul>
                      <li>法令に基づく場合</li>
                      <li>人の生命、身体または財産の保護のために必要がある場合であって、ユーザーの同意を得ることが困難であるとき</li>
                      <li>ユーザーが第三者に不利益を及ぼすと判断した場合</li>
                      <li>公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、ユーザー本人の承諾を得ることが困難で ある場合</li>
                      <li>国の機関若しくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合 で、ユーザー本人の同意を得ることによりその事務の遂行に支障を及ぼすおそれがある場合</li>
                      <li>裁判所、検察庁、警察またはこれらに準じた権限を有する機関から、個人情報についての開示を求められた場合</li>
                      <li>ユーザー本人から明示的に第三者への開示または提供を求められた場合</li>
                      <li>法令により開示または提供が許容されている場合</li>
                    </ul>

                    <h5 className="mb-3 margin-top-lg"><strong>5. 個人情報提供の任意性</strong></h5>
                    <p>当社が行うサービスにおいてそれぞれ必要となる項目を入力いただかない場合は、本サービスを受けられない場合があります。</p>

                    <h5 className="mb-3 margin-top-lg"><strong>6. 個人情報の適正な取得</strong></h5>
                    <p>当社は、適正に個人情報を取得し、偽りその他不正の手段により取得することはありません。また、15 歳未満の子供から親権者の同
                    意なく個人に関する情報をみだりに収集しないよう留意します。</p>

                    <h5 className="mb-3 margin-top-lg"><strong>7. 個人情報利用目的の変更</strong></h5>
                    <p>当社は、個人情報の利用目的を変更する場合には、変更前の利用目的と相当の関連性を有すると合理的に認められる範囲を超えて
                    は行わず、変更された利用目的について、ユーザーに通知し、または公表します。</p>

                    <h5 className="mb-3 margin-top-lg"><strong>8. 個人情報の安全管理・従業員の監督</strong></h5>
                    <p>当社は、個人情報の漏洩、滅失またはき損の防止その他の個人情報の安全管理が図られるよう、個人情報保護規程を定め、当社に
                    対する必要かつ適切な監督を行います。</p>

                    <h5 className="mb-3 margin-top-lg"><strong>9. 委託先の監督</strong></h5>
                    <p>当社は、個人情報の取扱いの全部又は一部を委託する場合は、委託先と機密保持を含む契約の締結、または、当社が定める約款に
                    合意を求め、委託先において個人情報の安全管理が図られるよう、必要かつ適切な監督を行います。</p>

                    <h5 className="mb-3 margin-top-lg"><strong>10. 第三者提供の制限</strong></h5>
                    <p>当社は、次に掲げる場合を除くほか、あらかじめユーザーの同意を得ないで、個人情報を第三者に提供しません。</p>
                    <ul>
                      <li>法令に基づく場合</li>
                      <li>人の生命、身体または財産の保護のために必要がある場合であって、ユーザーの同意を得ることが困難であるとき</li>
                      <li>公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、ユーザーの同意を  得ることが困難である とき</li>
                      <li>国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合で あって、ユーザーの同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</li>
                      <li>予め次の事項を告知あるいは公表をしている場合
                        <ul>
                          <li>利用目的に第三者への提供を含むこと</li>
                          <li>第三者に提供されるデータの項目</li>
                          <li>第三者への提供の手段または方法</li>
                          <li>ユーザーの求めに応じて個人情報の第三者への提供を停止すること。 ※ただし当社が利用目的の達成に必要な範囲内において個人情報の
                            取扱いの全部または一部を委託する場合は第三者には該当しません。</li>
                        </ul>
                      </li>
                    </ul>
                    <ul>
                      <li>個人情報を特定の者との間で共同して利用する場合であって、その旨並びに共同して利用される個人情報の項目、共同して利用す る者の範囲、利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について、あらかじめユ ーザーに通知し、またはユーザーが容易に知り得る状態に置いているとき </li>
                    </ul>

                    <h5 className="mb-3 margin-top-lg"><strong>11. 個人情報に関する事項の公表等</strong></h5>
                    <p>当社は、個人情報に関する次に掲げる事項について、ユーザーの知り得る状態に置き、ユーザーの求めに応じて遅滞なく回答します。</p>
                    <ul>
                      <li>個人情報の利用目的（ただし、個人情報の保護に関する法律において、その義務がないと規定されるものは除きます。ご回答しない 決定をした場合は、ユーザーに対して遅滞なくその旨を通知します。）</li>
                      <li>個人情報に関するお問い合わせ窓口</li>
                    </ul>

                    <h5 className="mb-3 margin-top-lg"><strong>12. 個人情報の開示</strong></h5>
                    <p>当社は、ユーザーから、個人情報の開示を求められたときは、ユーザーに対し、遅滞なく開示します。ただし、開示することにより次の
                    いずれかに該当する場合は、その全部または一部を開示しないこともあり、開示しない決定をした場合には、その旨を遅滞なく通知しま す。</p>

                    <ul>
                      <li>ユーザーまたは第三者の生命、身体、財産その他の権利利益を害するおそれがある場合</li>
                      <li>当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合</li>
                      <li>他の法令に違反することとなる場合</li>
                    </ul>

                    <p>なお、アクセスログなどの個人情報以外の情報については、原則として開示いたしません。</p>

                    <h5 className="mb-3 margin-top-lg"><strong>13. 個人情報の訂正等</strong></h5>
                    <p>当社は、ユーザーから、個人情報が真実でないという理由によって、内容の訂正、追加または削除（以下「訂正等」といいます）を求めら
                    れた場合には、他の法令の規定により特別の手続きが定められている場合を除き、利用目的の達成に必要な範囲内において、遅滞な
                    必要な調査を行い、その結果に基づき、個人情報の内容の訂正等を行い、その旨ユーザーに通知します。</p>

                    <h5 className="mb-3 margin-top-lg"><strong>14. 統計処理されたデータの利用</strong></h5>
                    <p>当社は、提供を受けた個人情報をもとに、個人を特定できないよう加工した統計データを作成することがあります。個人を特定できない
                    統計データについては、当社は何ら制限なく利用することができるものとします。</p>

                    <h5 className="mb-3 margin-top-lg"><strong>15. 個人情報の利用停止等</strong></h5>
                    <p>当社は、ユーザーから、ユーザーの個人情報が、あらかじめ公表された利用目的の範囲を超えて取り扱われているという理由、または
                    偽りその他不正の手段により取得されたものであるという理由により、その利用の停止または消去（以下「利用停止等」といいます）を
                    求められた場合には、遅滞なく必要な調査を行い、その結果に基づき、個人情報の利用停止等を行い、その旨ユーザーに通知しま
                    す。ただし、個人情報の利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって、ユーザーの権利
                    利益を保護するために必要なこれに代わるべき措置をとれる場合は、この代替策を講じます。</p>

                    <h5 className="mb-3 margin-top-lg"><strong>16. 理由の説明</strong></h5>
                    <p>当社は、ユーザーからの要求にもかかわらず、</p>
                    <ul>
                      <li>利用目的を通知しない</li>
                      <li>個人情報の全部または一部を開示しない</li>
                      <li>個人情報の利用停止等を行わない</li>
                      <li>個人情報の第三者提供を停止しない</li>
                    </ul>

                    <p>のいずれかを決定する場合、その旨ユーザーに通知する際に理由を説明するよう努めます。</p>

                    <h5 className="mb-3 margin-top-lg"><strong>17. お問い合わせ</strong></h5>
                    <p>当社のプライバシーポリシーに関するお問い合わせは、下記担当までお願い致します。</p>

                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <p>個人情報保護管理責任者</p>
                    <p>平尾  憲映</p>
                  </div>

                </div>
                <div className="col"></div>
              </div>
            </div>
          </section>

        </main>

      </div>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query PrivacyPolicyQuery {
    site {
      siteMetadata {
        en {
          title
          description
          keywords
        }
      }
    }
  }
`
