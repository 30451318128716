import React from 'react'
import { Helmet } from 'react-helmet'
import Link from 'gatsby-link'
import OgImage from "img/ogimg-planetcross.jpg"
import get from 'lodash/get'
import { graphql } from 'gatsby'
import Layout from "../../components/layout"

class RootIndex extends React.Component {
  render() {
    const langPrefix = this.props.location.pathname.match(/^\/en/) ? '/en' : '';

    const siteTitle = get(this, 'props.data.site.siteMetadata.en.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.en.description')
    const siteKeywords = get(this, 'props.data.site.siteMetadata.en.keywords')

    return (
      <Layout location={this.props.location}>
        <Helmet
        	title={siteTitle}
        	meta={[
        		{ name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: 'https://planetway.com' + OgImage },
        		{ property: 'og:description', content: siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />

        <div className="page-header gdpr-header">
          <div className="container text-on-dark">
            <div className="row">
              <div className="col-md-6"><div className="gdpr-artwork" /></div>
              <div className="col-md-6 mt-5">
                <h3>Security Service for GDPR</h3>
                <p>We provide support services to help you comply with GDPR, the law that enhances and integrates data protection for all individuals in the EU.</p>
                <Link to={langPrefix + "/contact"} className="btn internal-link">Contact Us</Link>
              </div>
            </div>
          </div>
        </div>

        <main>

          <div className="clear" />

          <section>
            <div className="container">
              <div className="row">
                <div className="col" />
                <div className="col-lg-10 text-center mobile-text-left">
                  <h4 className="underline">GDPR Countermeasure Support Service</h4>
                  <p className="padding-bottom-sm">GDPR, which came into force on May 25, 2018, stands for General Data Protection Regulation, and is widely recognized for its impact on companies outside of the EU, including Japan, and for its recent imposition of high fines on non-compliant companies. We provide countermeasure services for business development without violating this law.</p>
                </div>
                <div className="col" />
              </div>
            </div>
          </section>

          <section className="pwdsa-infini-blur-bg text-on-dark">
            <div className="container">
              <div className="row padding-top-md">
                <div className="col-sm-6 padding-bottom-lg">
                  <div className="gdpr-icon-text d-flex align-items-center">
                    <div className="icon-user-check" />
                    <div className="flex-fill">
                      <h4>Assessment &amp; Training</h4>
                      <p>Evaluate, analyze, and train legal compliance.</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 padding-bottom-lg">
                  <div className="gdpr-icon-text d-flex align-items-center">
                    <div className="icon-target" />
                    <div className="flex-fill">
                      <h4>Definition of elements</h4>
                      <p>Organize regulatory compliance requirements.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 padding-bottom-lg">
                  <div className="gdpr-icon-text d-flex align-items-center">
                    <div className="icon-tools" />
                    <div className="flex-fill">
                      <h4>Implementation Support</h4>
                      <p>Assist in the development of regulations, policies and processes.</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 padding-bottom-lg">
                  <div className="gdpr-icon-text d-flex align-items-center">
                    <div className="icon-clipboard" />
                    <div className="flex-fill">
                      <h4>Periodic Audit</h4>
                      <p>Ongoing compliance checks and audits.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 text-center padding-bottom-md">
                  <Link to={langPrefix + "/contact"} className="btn internal-link">Contact Us</Link>
                </div>
              </div>
            </div>
          </section>
        </main>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query GdprQueryEn {
    site {
      siteMetadata {
        en {
          title
          description
          keywords
        }
      }
    }
  }
`
