import React from 'react'
import { Helmet } from 'react-helmet'
import Link from 'gatsby-link'
import OgImage from "img/ogimg-planetid.jpg"
import get from 'lodash/get'
import { graphql } from 'gatsby'

class RootIndex extends React.Component {
  render() {
    const langPrefix = this.props.location.pathname.match(/^\/en/) ? '/en' : '';

    const siteTitle = get(this, 'props.data.site.siteMetadata.en.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.en.description')
    const siteKeywords = get(this, 'props.data.site.siteMetadata.en.keywords')

    return (
      <div>
        <Helmet
        	title={siteTitle}
        	meta={[
        		{ name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: 'https://planetway.com' + OgImage },
        		{ property: 'og:description', content: siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />
        <main>

          <section className="pt-5">
            <div className="container">
              <div className="row">
                <div className="col"></div>
                <div className="col-sm-10">
                  <h4 className="underline"><strong>PlanetID Service Level Objective (SLO)</strong></h4>

                  <div className="mb-5">
                    <span class="news-date margin-bottom-sm">本ドキュメントはPlanetIDのサービス目標（SLO）について記したものです。</span>

                    <ol className="sublist">
                      <li className="list-item">SLOの概要
                        <ol className="sublist">
                          <li className="list-item">SLO対象サービス
                            <p>本SLO の対象サービスは、Planetway Japan 株式会社（以下、「当社」と呼称します。）が実施するPlanetID データ連携基盤における以下サービス（以下、本SLO の対象サービスと呼称します）について適用されます。</p>
                            <table className="basic-table">
                              <tr>
                                <td>項番</td>
                                <td>サービス概要</td>
                                <td>サービス内容</td>
                              </tr>
                              <tr>
                                <td>１</td>
                                <td>PlanetID Engine 運用/保守</td>
                                <td>
                                  <ul>
                                    <li>PlanetID Engine の運用</li>
                                    <li>PlanetID Engine の保守</li>
                                    <li>PlanetID Engine の障害対応</li>
                                  </ul>
                                </td>
                              </tr>
                              <tr>
                                <td>2</td>
                                <td>証明書の発行・失効業務運用</td>
                                <td>
                                  <ul>
                                    <li>PlanetID ご利用にあたっての各種登録業務</li>
                                    <li>上記登録後の変更/失効業務</li>
                                  </ul>
                                </td>
                              </tr>
                              <tr>
                                <td>3</td>
                                <td>ヘルプデスク</td>
                                <td>
                                  <ul>
                                    <li>製品の仕様の技術的な内容に関するご相談</li>
                                    <li>製品の改善および機能の追加の要望、不具合報告</li>
                                    <li>インシデント対応</li>
                                  </ul>
                                </td>
                              </tr>
                            </table>
                            <p>※「当社」には、Planetway Japan 株式会社が指定する会社も含まれます。</p>
                          </li>
                          <li className="list-item">サービス提供対象
                            <p>本SLO の対象サービスは、当社と本SLO に同意が成立する法人（以下、「契約者」と呼称します。）「（１）利用期間」で定める期間で利用可能とします。なお、利用可否を確認する手段について、「（２）確認手段」にて記載いたします。</p>
                          </li>
                          <p>（１）利用期間</p>
                          <p>PlanetID ライセンス契約の契約期間に従います。</p>
                          <p>（２）確認手段</p>
                          <p>法人単位にユニークな番号（X-Road メンバーコード）を発行します。</p>
                        </ol>
                      </li>
                      <li className="list-item">サービス別詳細
                        <ol className="sublist">
                          <li className="list-item">PlanetID Engineの運用/保守
                            <p>（１）サービス稼働時間基準値</p>
                            <table className="basic-table">
                              <tr>
                                <td>項番</td>
                                <td>項目</td>
                                <td>基準値</td>
                              </tr>
                              <tr>
                                <td>１</td>
                                <td>PlanetID Engine</td>
                                <td>24 時間 365 日のベストエフォート（（３）サービスの一時停止の記載事項を除く）</td>
                              </tr>
                              <tr>
                                <td>2</td>
                                <td>【参考】CA（OCSP レスポンダ）</td>
                                <td>24 時間365 日でベストエフォート（別途メンテナンスなどのサービス停止の規定あり）</td>
                              </tr>
                              <tr>
                                <td>3</td>
                                <td>【参考】TSA（タイムスタンプサービス）</td>
                                <td>24 時間365 日のベストエフォート（別途メンテナンスなどのサービス停止の規定あり）</td>
                              </tr>
                            </table>
                            <p>TSA に関しましては第三者機関のサービスレベルに準じます。そのため上記から変更の可能性がありますのでご注意ください。</p>
                            <p>（２）PlanetID の耐障害性</p>
                            <p>PlanetID のアーキテクチャは、PlanetID Engine、プライベートCA 及び外部組織提供機能（TSA）により構成されています。</p>
                            <p>前記構成の各要素は冗長化されており一部障害の影響を受けないように設計されています。</p>
                            <br/>
                            <p>（３）サービスの一時停止</p>
                            <p>PlanetID Engine は計画停止などでサービス停止を伴う作業が必要となった場合に、作業実施日の２週間前までに契約者に通知を行います。ただし、当社が緊急と判断した場合は、事前にお客様に通知することなく、サービスの全部または 一部を停止することができるものとします。</p>
                            <ul>
                              <li>火災、停電、不正アクセス等の事故により本サービスの中断がやむを得ない場合</li>
                              <li>計画停止を除く保守、運用上の点検整備又はセキュリティ管理上中断がやむを得ない場合</li>
                              <li>システム構成の重大な故障やその他システムに関する重大な障害が発生し、業務を継続することにより被害が拡大するおそれがある場合</li>
                            </ul>
                            <br/>
                            <p>（４）障害対応</p>
                            <p>（４−１）障害の定義</p>
                            <ul>
                              <li>
                                本サービスで障害とは、弊社のPlanetID Engine 環境に起因してお客様環境での機能が利用できない場合とします。<br/>
                                ただし、お客様環境に搭載される弊社製品に起因される技術内容の調査ついてはヘルプデスクにて対応いたします。</li>
                              <li>障害発生の起点時間は、お客様からの申告により当社が障害と認知した時点、または、当社からお客様に障害の通知を行った時点とします。</li>
                              <li>復旧完了の時間は、当社が問題の回復を認知、または代替手段の提供を行った時点とします。</li>
                            </ul>
                            <br/>
                            <p>（４−２）障害対応のサービス基準値</p>
                            <table className="basic-table">
                              <tr>
                                <td>項番</td>
                                <td>サービス項目名</td>
                                <td>定義</td>
                                <td>基準値</td>
                              </tr>
                              <tr>
                                <td>1</td>
                                <td>障害対応時間帯</td>
                                <td>障害対応を行う曜日、時間帯</td>
                                <td>当社営業日 （平日9:00〜17:00）</td>
                              </tr>
                              <tr>
                                <td>2</td>
                                <td>障害通知時間</td>
                                <td>当社が障害を認知し、障害状況の一報を通知するまでの時間</td>
                                <td>当社１営業日 （当社営業時間内に障害認知の場合）</td>
                              </tr>
                              <tr>
                                <td>3</td>
                                <td>障害復旧時間</td>
                                <td>障害が発生してから復旧までに要する時間</td>
                                <td>当社３営業日</td>
                              </tr>
                            </table>
                            <p>（5）セキュリティ</p>
                            <ul>
                              <li>SOC1 Type2 を受領したデータセンターを採用しております。また作業者については、当社内のルールに則った適切な権限管理を行っており、特定の担当者以外アクセス不可となっております。</li>
                              <li>Engine 側で保持している暗号キーはFIPS 140-2 のレベル 3 認証済みのHSM（ハードウエアセキュリティモジュール）で安全に保管されております。</li>
                            </ul>
                          </li>
                          <li className="list-item">証明書の発行・失効業務運用
                            <p>本サービスのSLO を以下の通りに設定します。尚、完了基準値の日付に付きましては全て当社営業日が基準となります。当社非営業日にメールなどでご依頼頂いた場合、翌当社営業日に受付となります。</p>
                            <table className="basic-table">
                              <tr>
                                <td>項番</td>
                                <td>サービス項目名</td>
                                <td>サービス項目の説明</td>
                                <td>完了基準値</td>
                              </tr>
                              <tr>
                                <td>1</td>
                                <td>電子証明書の発行手続</td>
                                <td>PlanetID アプリでのPlanetID 発行処理時自動発行</td>
                                <td>24 時間365 日随時</td>
                              </tr>
                              <tr>
                                <td>2</td>
                                <td>電子証明書の失効手続</td>
                                <td>PlanetID アプリでのPlanetID 削除処理時</td>
                                <td>24 時間365 日随時但し対象証明書の失効処理は別途実施（PlanetID 削除処理により対象証明書の利用はシステムとして利用できなくなるため）</td>
                              </tr>
                            </table>
                          </li>
                          <li className="list-item">ヘルプデスク
                            当社ヘルプデスクで提供する内容は以下の通りとなります。尚、各項目はヘルプデスクポータル上の選択事項に合わせておりますのでご留意ください。
                            <br/>
                            <p>（1）ヘルプデスク業務</p>
                            <p>（1−1）対象業務</p>
                            <table className="basic-table">
                              <tr>
                                <td>項目</td>
                                <td>定義</td>
                              </tr>
                              <tr>
                                <td>インシデント</td>
                                <td>当社製品に起因したトラブルの調査・解析のご依頼</td>
                              </tr>
                              <tr>
                                <td>技術的なお問い合わせ</td>
                                <td>当社製品仕様等の技術的な内容についてのご相談</td>
                              </tr>
                              <tr>
                                <td>改善要望</td>
                                <td>当社製品およびドキュメントの改善・機能追加要望のリクエスト</td>
                              </tr>
                            </table>
                            <p>（1−2）対象外業務</p>
                            <ul>
                              <li>パフォーマンス・パラメータのチューニング</li>
                              <li>マニュアルに記載のない構成、または使用方法</li>
                              <li>システム設計・個別設定に関する質問</li>
                              <li>当社製品以外の質問</li>
                            </ul>
                            <br/>
                            <p>（２）ヘルプデスク提供手段（当面サポート用メールにて対応：以下ご参考）</p>
                            <ul>
                              <li>当社提供のヘルプデスクシステムにてお問い合わせを受付、対応いたします。同アカウントはご契約後に提供させて頂きます。</li>
                            </ul>
                            <br/>
                            <p>（３）サービス提供条件</p>
                            <ul>
                              <li>当社営業時間（9:00-17:00）にてお問い合わせ受付、当社からのご報告・回答をさせていただきます（＃お客様からヘルプデスクシステムへのアクセス・お問い合わせ、更新は24 時間365 日可能となります。）</li>
                            </ul>
                            <br/>
                            <p>（４）インシデンスレスポンス対応方針</p>
                            <p>（4−1）重要度の分類</p>
                            <p>重要度はヘルプデスクにてお問い合わせの際、お客様にて入力頂く項目となります。以下を参考に入力頂きますようお願い致します。</p>
                            <table className="basic-table">
                              <tr>
                                <td>インシデント重要度</td>
                                <td>分類基準</td>
                                <td>定義</td>
                              </tr>
                              <tr>
                                <td>Critical</td>
                                <td>本番業務が中断する</td>
                                <td>
                                  弊社製品において重要な機能が使用不可能となり、本番業務が停止した状態となっている。<br/> 例）<br/>
                                  <ul>
                                    <li>本番環境にて製品が起動不可</li>
                                    <li>本番環境にて各製品との通信不可</li>
                                  </ul>
                                </td>
                              </tr>
                              <tr>
                                <td>High</td>
                                <td>業務に大きな影響がある</td>
                                <td>
                                  弊社製品において機能が大幅に限定されている、または大幅な性能劣化が発生。これにより本番業務に大きな影響が発生している。 弊社製品において重要な機能が使用不可能となり、検証環境の作業が停止した状態となっている。
                                  <br/>
                                  例）
                                  <br/>
                                  <ul>
                                    <li>検証環境にて製品が起動不可</li>
                                    <li>プロセスが断続的に再起動を繰り返す</li>
                                  </ul>
                                </td>
                              </tr>
                              <tr>
                                <td>Medium</td>
                                <td>業務へ多少の影響が発生している</td>
                                <td>
                                  弊社製品において重要度の低い機能に関する不具合が発生している。 業務への影響はない、または多少の影響が発生している。
                                  <br/>
                                  例）
                                  <br/>
                                  <ul>
                                    <li>WebUI の表示不正（文字化けなど）</li>
                                  </ul>
                                </td>
                              </tr>
                              <tr>
                                <td>Low</td>
                                <td>業務とは関係ない問題が発生している</td>
                                <td>
                                  業務とは関係のない機能についての不具合、または業務影響のない不具合。 マニュアルに記載されている内容の不備などのソフトウェア自体の不具合に直接関係しない内容。
                                  <br/>
                                  例）
                                  <br/>
                                  <ul>
                                    <li>マニュアルの誤記</li>
                                  </ul>
                                </td>
                              </tr>
                            </table>
                            <p>（4−2）ファーストレスポンス目標</p>
                            <table className="basic-table">
                              <tr>
                                <td>インシデント重要度</td>
                                <td>基準値（設定値）</td>
                              </tr>
                              <tr>
                                <td>Critical</td>
                                <td>２時間（営業時間）</td>
                              </tr>
                              <tr>
                                <td>High</td>
                                <td>４時間（営業時間）</td>
                              </tr>
                              <tr>
                                <td>Medium</td>
                                <td>１日（営業時間）</td>
                              </tr>
                              <tr>
                                <td>Low</td>
                                <td>１日（営業時間）</td>
                              </tr>
                            </table>
                            <ul>
                              <li>ファーストレスポンスとはお客様の問い合わせに対しての受付のことであり、問い合わせについての回答を指すものではありません。</li>
                              <li>ファーストレスポンスはお客様にてヘルプデスクシステムにインシデント登録がされた時点を起点とし、当社担当者がインシデントを認識した後返信を行うまでの時間となります。</li>
                              <li>ファーストレスポンスは目標時間となります。時間をお約束するものではございません。重要度「Critical」にて16 時30 分にお問い合わせいただいた場合、当社翌営業日10 時30 分となります。</li>
                            </ul>
                            <br/>
                            <p>（4−3）状況報告の目安</p>
                            <table className="basic-table">
                              <tr>
                                <td>インシデント重要度</td>
                                <td>基準値（設定値）</td>
                              </tr>
                              <tr>
                                <td>Critical</td>
                                <td>必要に応じて適宜実施連絡が取れない場合、１営業日以内</td>
                              </tr>
                              <tr>
                                <td>High</td>
                                <td>２営業日以内</td>
                              </tr>
                              <tr>
                                <td>Medium</td>
                                <td>５営業日以内</td>
                              </tr>
                              <tr>
                                <td>Low</td>
                                <td>10 営業日以内</td>
                              </tr>
                            </table>
                            <ul>
                              <li>状況報告の目安は、お客様から当社にご要望をいただいた後、当社にて対応が発生した時点からの進捗報告・確認を実施する目安となります。そのための内容の解決をお約束するものではありません。</li>
                              <li>上記状況報告の対象となるのは弊社製品の問題、「2.1 PlanetID Engine の運用/保守の（4-1）障害の定義」に記載の事項に切り分けられたものとなります。</li>
                            </ul>
                            <br/>
                            <p>（4−3−1）インシデントに対する提供内容</p>
                            <ul>
                              <li>問題の切り分け調査、解析結果のご報告</li>
                              <li>顧客環境での当社製品における問題に対して既に対策が存在する場合、その対策方法のご案内</li>
                              <li>顧客環境での当社製品における問題に対して対策が未知の場合、原因を分析し、対策を検討させていただきます。可能な場合代替案を案内する場合がございますが約束するものではございません。</li>
                              <li>PlanetID Engine 環境の障害に起因した障害については担当部門にエスカレーションし、「2.1 PlanetID Engine の運用/保守の（４ ２）障害対応のサービス基準値」に基づき復旧いたします。</li>
                            </ul>
                            <br/>
                            <p>（4−3−２）時間外業務について</p>
                            <ul>
                              <li>営業時間外での問い合わせについての受付は対象外となります。但し重要度[Critical]の対応について営業時間外での対応をご希望される際は貴社の営業担当者にご相談ください。</li>
                            </ul>
                            <br/>
                            <p>（4−3−３）その他</p>
                            <ul>
                              <li>調査を円滑に進めさせていただくために、調査に必要となるログの提供をお願いさせていただく場合がございます。</li>
                              <li>問い合わせ頂いた内容については、設定した重要度の変更についてご相談させていただく場合がございます。</li>
                              <li>重要度[Critical]の場合、お客様にも適宜ご対応頂ける体制を確立頂くことが前提となります。</li>
                              <li>製品仕様について、弊社ウエブページに公開しているドキュメント、およびFAQ にすでに対策が記載されている場合がありますため、一度ご確認いただき、情報のご提供お願いいたします。</li>
                            </ul>
                            <br/>
                            <p>（５）技術的なお問い合わせに対する対応方針</p>
                            <p>（5−1）ご依頼内容</p>
                            <table className="basic-table">
                              <tr>
                                <td>ご依頼内容</td>
                                <td>定義</td>
                              </tr>
                              <tr>
                                <td>設定・仕様について</td>
                                <td>設定内容、設定変更方法についてのお問合せ。</td>
                              </tr>
                              <tr>
                                <td>その他のご相談</td>
                                <td>その他お問合せ。</td>
                              </tr>
                            </table>
                            <br/>
                            <p>（５−2）ファーストレスポンス目標</p>
                            <table className="basic-table">
                              <tr>
                                <td>優先度</td>
                                <td>基準値（設定値）</td>
                              </tr>
                              <tr>
                                <td>Critical</td>
                                <td>1 営業日（営業時間）</td>
                              </tr>
                              <tr>
                                <td>High</td>
                                <td>1 営業日（営業時間）</td>
                              </tr>
                              <tr>
                                <td>Middle</td>
                                <td>1 営業日（営業時間）</td>
                              </tr>
                              <tr>
                                <td>Low</td>
                                <td>1 営業日（営業時間）</td>
                              </tr>
                            </table>
                            <ul>
                              <li>ファーストレスポンスはお客様にてヘルプデスクシステムに登録がされた時点を起点とし、当社担当者が登録を認識した後返信を行うまでの時間となります。</li>
                              <li>ファーストレスポンスは目標時間となります。時間をお約束するものではございません。</li>
                            </ul>
                            <br/>
                            <p>（5−３）状況報告の目安</p>
                            <table className="basic-table">
                              <tr>
                                <td>優先度</td>
                                <td>状況報告の目安</td>
                              </tr>
                              <tr>
                                <td>Critical</td>
                                <td>５営業日（営業時間）</td>
                              </tr>
                              <tr>
                                <td>High</td>
                                <td>５営業日（営業時間）</td>
                              </tr>
                              <tr>
                                <td>Middle</td>
                                <td>10 営業日（営業時間）</td>
                              </tr>
                              <tr>
                                <td>Low</td>
                                <td>10 営業日（営業時間）</td>
                              </tr>
                            </table>
                            <p>状況報告の目安は、お客様から当社にご要望をいただいた後、当社にて対応が発生した時点からの進捗報告・確認を実施する目安となります。そのための内容の解決をお約束するものではありません。</p>
                            <br/>
                            <p>（６）機能要望</p>
                            <ul>
                              <li>「機能要望はこちら」に関しましては受付専用となります。</li>
                              <li>弊社にて受付が完了した時点でクローズとさせていただきます。</li>
                              <li>頂きましたご要望に関しましては、今後の製品に対する機能追加・改善の参考とさせていただきます。</li>
                            </ul>
                            <p>＃機能改善をお約束するものではございません。</p>
                          </li>
                        </ol>
                      </li>
                      <li className="list-item">SLO の適用外
                        <p>全てのサービスに付きまして以下は本SLO の適用外となります。</p>
                        <ul>
                          <li>お客様環境に起因するサービス停止、ヘルプデスク対応</li>
                          <li>サーバ稼働する当社の製造に係らないソフト、OS に起因するサービス停止、ヘルプデスク対応</li>
                          <li>利用者の故意または過失に起因するサービス停止</li>
                          <li>天災地変その他の不可抗力によるサービス停止</li>
                          <li>その他、契約書に記載の当社責任外となる事象、現象</li>
                        </ul>
                      </li>
                      <li className="list-item">SLO の変更
                        <p>当社は、本SLO の内容を変更する場合があります。変更の場合は、１ヶ月前に通知を行います。</p>
                      </li>
                    </ol>
                    <br/>
                  </div>

                </div>
                <div className="col"></div>
              </div>
            </div>
          </section>

        </main>

      </div>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query PlanetidSloQuery {
    site {
      siteMetadata {
        en {
          title
          description
          keywords
        }
      }
    }
  }
`
