import React from 'react'
import { Helmet } from 'react-helmet'
import OgImage from "img/news-placeholder.jpg"
import get from 'lodash/get'
import { graphql } from 'gatsby'

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.description')
    const siteKeywords = get(this, 'props.data.site.siteMetadata.keywords')

    return (
      <div>
        <Helmet
        	title={siteTitle}
        	meta={[
        		{ name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords },
        		{ property: 'og:title', content: siteTitle },
        		{ property: 'og:type', content: 'website' },
        		{ property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
        		{ property: 'og:image', content: 'https://planetway.com' + OgImage },
        		{ property: 'og:description', content: siteDescription },
        		{ name: 'twitter:card', content: 'summary' },
        		{ name: 'twitter:site', content: '@Planetway_Tw' },
        	]}
        />
        <main className="no-header">

          <div className="clear"></div>

          <section>
            <div className="container">
              <div className="row">
                <div className="col"></div>
                <div className="col-sm-8 text-center">
                  <h3 className="underline text-bold">ありがとうございます</h3>
                  <p className="subtitle-small">お問い合わせありがとうございます。</p>
                  <p>近日中にご回答いたします。今しばらくお待ちください。
                    {/*<br />
                    また、ご入力いただいたメールアドレス宛てに自動返信メールをお送りしました。<br />
                  メールが届かない場合、大変お手数ですが、以下の問い合わせ先までご連絡をお願い致します。*/}
                  </p>

                  <div className="pr-contactinfo">
                    <h5 className="text-bold">本件に関するお問い合わせ</h5>
                    <p>Planetway Japan 株式会社<br />
                    広報担当<br />
                    <strong>TEL:</strong> 03-6897-9419<br />
                    <strong>MAIL:</strong> <a href="mailto:pr@planetway.com">pr@planetway.com</a></p>
                  </div>
                </div>
                <div className="col"></div>
              </div>
            </div>
          </section>

        </main>

      </div>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query ThanksQuery {
    site {
      siteMetadata {
        title
        description
        keywords
      }
    }
  }
`
