import React from 'react'
import { Helmet } from 'react-helmet'
import Link from 'gatsby-link'
import OgImage from "img/news-placeholder.jpg"
import PlanetIdHpArtwork from "img/planetid-hp-artwork.png"
import get from 'lodash/get'
import PressReleaseLatest from 'components/pressrelease-latest'
import PressReleaseSmall from 'components/pressrelease-small'
import { graphql } from 'gatsby'
import Layout from "../components/layout"

class RootIndex extends React.Component {
  render() {
    const langPrefix = this.props.location.pathname.match(/^\/en/) ? '/en' : '';

    const siteTitle       = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(this, 'props.data.site.siteMetadata.description')
    const siteKeywords    = get(this, 'props.data.site.siteMetadata.keywords')
    const posts           = get(this, 'props.data.allContentfulPressRelease.edges')
    const members         = get(this, 'props.data.allContentfulTeamMember.edges')

    return (
      <Layout location={this.props.location}>
        <Helmet
          title={siteTitle}
          meta={[
          { name: 'description', content: siteDescription },
          { name: 'keywords', content: siteKeywords },
          { property: 'og:title', content: siteTitle },
          { property: 'og:type', content: 'website' },
          { property: 'og:url', content: 'https://planetway.com' + this.props.location.pathname },
          { property: 'og:image', content: 'https://planetway.com' + OgImage },
          { property: 'og:description', content: siteDescription },
          { name: 'twitter:card', content: 'summary' },
          { name: 'twitter:site', content: '@Planetway_Tw' },
          ]}
          />
        <div className="slider">
          <div className="vimeo-wrapper">
            <iframe src="https://player.vimeo.com/video/385935996?background=1&autoplay=1&loop=1&byline=0&title=0&dnt=1"
            frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen />
          </div>
          <div className="video-caption">
            <strong className="subtitle-small">Planetway at</strong>
            <h2>DAVOS 2020</h2>
            <p>プラネットウェイCEO兼創設者の平尾憲映は、2020年の世界経済フォーラムで人間を中心とした「個人データ主権」に関するビジョンを発表しました</p>
            <Link to="" className="btn btn-outline margin-bottom-md" data-toggle="modal" data-target="#pwDavosVidPop">Watch Full Interview</Link>
            <p className="header-offsite-links"><a href="https://ascii.jp/elem/000/004/000/4000038/" target="_blank">Davos 2020 on ASCII</a></p>
          </div>
        </div>

        <div id="pwDavosVidPop" className="modal fade" role="dialog">
          <div className="modal-dialog">

            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Human-Centric Data Sovereignty</h4>
                <button type="button" className="close" data-dismiss="modal">&times;</button>
              </div>
              <div className="modal-body">
                <div style={{padding: '56.25% 0 0 0',position: 'relative'}}><iframe src="https://player.vimeo.com/video/385942278?color=FF40FF&byline=0&portrait=0&dnt=1" id="pwDavosVidFull" className="pw-davos-full" style={{position: 'absolute',top: '0',left: '0',width: '100%',height: '100%'}} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen /></div>
              </div>
            </div>

          </div>
        </div>

        <main>

          <div className="clear" />

          {posts.slice(0,1).map(({ node }) => {
            return (
                <PressReleaseLatest key={node.slug} post={node} />
            )
          })}

          <section className="padding-bottom-none">
            <div className="container">
              <div className="row">
                <div className="col" />
                <div className="col-lg-10 text-center mobile-text-left">
                  <h3 className="underline">Our Vision</h3>
                  <h5 className="margin-bottom-sm"><strong>インディビジュアル・データ・ドリブン・ソーシャルイノベーション</strong></h5>
                  <p>Planetwayは、データは組織ではなく個人に帰属すべきであると考えています。個人の許諾ベースのもと、<br className="full-width-only" />
                     自身の意思でデータを安全かつ自由に第三者に公開可能とすることで、自身と社会にとって好ましい形で<br className="full-width-only" />
                     データが利活用される世界の創出を目指しています。当社はこの世界を「データ個人主権の新時代」と<br className="full-width-only" />
                     名づけ、近い将来訪れる資本主義を超えた社会の実現を目指します。</p>
                  <Link to="/our-vision" className="btn internal-link">詳しくはこちら</Link>
                </div>
                <div className="col" />
              </div>
            </div>
          </section>

          <section className="padding-bottom-none">
            <div className="container">
              <div className="row">
                <div className="col">
                  <h3 className="underline">Solutions</h3>
                </div>
              </div>
            </div>
          </section>

          <section className="padding-top-none">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 text-center"><img src={PlanetIdHpArtwork} className="hp-planetid-artwork full-width-image" /></div>
                <div className="col-lg-6">
                  <h4>PlanetID</h4>
                  <strong className="subtitle-small">Personal Information Centralisation</strong>
                  <p><strong>企業が持つ個人情報の権利を「個人に帰属させる」ためのID</strong></p>
                  <ul>
                    <li>なりすまし超困難（エストニアの最先端かつ実績のある技術を使用）</li>
                    <li>サービスドリブンアプローチ（使いやすさ、導入のしやすさに注力）<br />※ 初期 利用頻度の高いサービスで実装</li>
                    <li>アプリベース（展開スピード）</li>
                    <li>国内居住者だけでなく非居住者でも利用可能（ユニバーサルID）</li>
                    <li>自身で個人情報のコントロールが可能（許諾/失効とアクセス履歴）</li>
                  </ul>
                  <Link to="/planetid" className="btn internal-link">More about PlanetID</Link>
                </div>
              </div>
            </div>
          </section>

          <section className="dark-blue-gradient text-on-dark">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 order-lg-0 order-12">
                  <h4>PlanetCross</h4>
                  <strong className="subtitle-small">Cross-Industry Secure Data Access Platform</strong>
                  <p>「PlanetCross」は、エストニアの世界最先端の電子政府を支える技術を、ブロックチェーン技術などを組み合わせた情報連携基盤です。
                   各種企業間の データベースを分散型で繋げることで、個人・法人を問わないデータの完全性・セキュリティを担保したデータのアクセスを可能にします。
                   また、Beyond APIという概念を実現させていきます。</p>
                  <Link to="/planetcross" className="btn internal-link">More about PlanetCross</Link>
                </div>
                <div className="col-lg-1" />
                <div className="col-lg-5 text-center"><div className="planetcross-logo-dark block-center" /></div>
              </div>
            </div>
          </section>

          <section className="padding-bottom-none">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 text-center"><div className="gdpr-artwork" /></div>
                <div className="col-lg-6 padding-top-lg">
                  <h4>Security Service for GDPR</h4>
                  <strong className="subtitle-small">GDPR<strong>対策支援サービス</strong></strong>
                  <p>EU内の全ての個人のためにデータ保護を強化し統合する法律GDPRに準拠するための対策支援サービスを提供します。</p>
                  <Link to={langPrefix + "/security-service-for-gdpr"} className="btn internal-link">More about GDPR</Link>
                </div>
              </div>
            </div>
          </section>

          <section className="padding-bottom-none">
            <div className="container">
              <div className="row">
                <div className="col">
                  <h3 className="underline">Platforms</h3>
                </div>
              </div>
            </div>
          </section>

          {/*<section className="purple-gradient text-on-dark">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 text-center"><img src={PlanetGuardiansLogo} className="hp-planetguardians-logo padding-bottom-md" /></div>
                <div className="col-lg-6">
                  <h4>PlanetGuardians</h4>
                  <strong className="subtitle-small">Global Digital Security Initiative</strong>
                  <p>NATOサイバーテロ防衛センター、国際諜報機関、国際警察機関に対する世界的なサイバーセキュリティエ ンジニア/トレイナー/アドバイザーを
                  象徴として展開するグローバルスタンダードなホワイトハッカーおよ びセキュリティ人材の育成プログラムを提供します。本プログラムには、オンライン講座、
                  CyberPlanet(Global CyberRange)、認定証の提供を含む想定です。詳細は近日中に公開予定です。</p>
                  <Link to="/planetguardians" className="btn internal-link">Learn more about PlanetGuardians</Link>
                </div>
              </div>
            </div>
          </section>*/}

          {/*<section className="padding-top-none">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 order-lg-0 order-12">
                  <h4>PlanetEco</h4>
                  <strong className="subtitle-small">Open Innovation Platform</strong>
                  <p>PlanetEcoは、情報連携基盤である PlanetCross と個人認証基盤である PlanetID を共通基盤とし、
                    特定のテーマのもと各社 ・団体と協力して、オープン・イノベーションによる新たなサービス開発を行うことを目的としたプログラムを推進するものです(複数企業/団体を対象)。
                    対象となるコアテーマは下記の通りです。1. ヘルスケア・メディカル領域（サービス）、2. 不動産・スマートシティ領域（サービス）、3. 金融・フィンテック領域（サービス）、4. 自動車・シェアリング領域（サービス）の4つを予定しています。</p>
                  <Link to="/planeteco" className="btn internal-link">Learn more about PlanetEco</Link>
                </div>
                <div className="col-lg-1"></div>
                <div className="col-lg-5 text-center"><div className="planeteco-logo block-center"></div></div>
              </div>
            </div>
          </section>*/}

          <section className="dark-blue-gradient text-on-dark margin-bottom-xl">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 order-lg-0 order-12">
                  <h4>PDSA</h4>
                  <strong className="subtitle-small">Planetway® Data Sovereignty Alliance</strong>
                  <p>弊社がビジョンに掲げる「個人データ主権の実現」を推進するための企業向けグローバルビジネスアライアンスです。</p>
                  <p>PDSAはDX、GDPR、情報セキュリティなどの企業課題を対象としたソリューションやユースケースのコンテンツを提供することで、企業の課題解決を支援します。
                  また、ビジネス・技術をサポートするコンテンツを提供することで、パートーナー企業が弊社提供製品およびサービスを構築、マーケティング、販売することを支援します。</p>
                  <Link to={langPrefix + "/planetway-data-sovereignty-alliance"} className="btn internal-link">More about PDSA</Link>
                </div>
                <div className="col-lg-1" />
                <div className="col-lg-5 text-center"><div className="pwdsa-pid-px-home-artwork" /></div>
              </div>
            </div>
          </section>

          <section className="video padding-top-none padding-bottom-none">
            <div className="container">
              <div className="row">
                <div className="col">
                  <h3 className="underline margin-bottom-lg">Planetway at MyData 2019</h3>
                  <div className="video-wrapper">
                    <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/q6COzzOsmUo" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
                  </div>
                </div>
              </div>
            </div>
          </section>


          <section className="news padding-bottom-xs">
            <div className="container">
              <div className="row">
                <div className="col">
                  <h3 className="underline margin-bottom-lg">News</h3>
                </div>
              </div>
            </div>

            <div className="container">
              {posts.map(({ node }) => {
                return (
                  <PressReleaseSmall key={node.slug} post={node} />
                )
              })}
            </div>

            <div className="container margin-top-sm margin-bottom-sm">
              <div className="row">
                <div className="col">
                  <Link to="/media" className="btn internal-link">See more news</Link>
                </div>
              </div>
            </div>
          </section>

          <section className="padding-top-xl">
            <div className="container">
              <div className="row">
                <div className="col">
                <h3 className="underline"><strong>受賞歴</strong></h3>
                </div>
              </div>

              <div className="row">
                <div className="col-sm">
                  <div className="planetcross-award-ibmbluehub" />
                  <div className="text-center"><p>2016年12月17日</p></div>
                  <ul className="list-green-check">
                    <li><h5>Automotive</h5><p>最優秀賞/審査員特別賞(日産自動車様)</p></li>
                    <li><h5>Healthcare</h5><p>審査員特別賞(電通デジタル様)</p></li>
                  </ul>
                </div>
                <div className="col-sm">
                  <div className="planetcross-award-efma" />
                  <div className="text-center"><p>2017年5月2日</p></div>
                  <ul className="list-green-check">
                    <li><h5>Efma主催Innovation In Insurance Award</h5><p>東京海上日動火災社一位受賞に貢献</p></li>
                  </ul>
                </div>
                <div className="col-sm">
                  <div className="planetcross-award-apec" />
                  <div className="text-center"><p>2017年10月30日</p></div>
                  <ul className="list-green-check">
                    <li><h5>ガートナー主催 APEC 2017 Award
                    (Most Innovative Digital Business Modelの部)
                    </h5><p>東京海上日動火災社 準優勝に貢献</p></li>
                  </ul>
                </div>
                <div className="col-sm">
                  <div className="planetcross-award-cert" />
                  <div className="text-center"><p>2022年4月6日</p></div>
                  <ul className="list-green-check">
                    <li><h5>Top 10 JAPANESE STARTUPS 2022</h5>
                    <p>米StartUp City誌により、日本を代表するスタートアップ10社に選出</p></li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </main>

      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
        description
        keywords
      }
    }
    allContentfulPressRelease(
      filter: {node_locale: {eq: "ja"}},
      sort: { fields: [publishDate], order: DESC },
      limit: 5
    ) {
      edges {
        node {
          title
          slug
          type
          excerpt {
            childMarkdownRemark {
              html
            }
          }
          content {
            childMarkdownRemark {
              html
            }
          }
          photo {
            file {
              url
            }
          }
          publishDate(formatString: "LL", locale: "ja")
          node_locale
          publishEnglish
        }
      }
    }
    allContentfulTeamMember(
      filter: {node_locale: {eq: "ja"}},
      sort: { fields: [publishDate], order: ASC },
      limit: 50
    ) {
      edges {
        node {
          name
          slug
          position
          excerpt {
            childMarkdownRemark {
              html
            }
          }
          profile {
            childMarkdownRemark {
              html
            }
          }
          profilePicture {
            file {
              url
            }
          }
          america
          japan
          estonia
          type
          publishDate(formatString: "LL", locale: "ja")
          node_locale
          publishEnglish
        }
      }
    }
  }
`
